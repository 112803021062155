import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        commentDiv: {
            display: "table-cell",
        },
    });
};
var CommentLineBase = function (props) {
    var classes = props.classes, name = props.name, value = props.value;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.commentDiv },
            name,
            ":\u00A0"),
        React.createElement("div", { className: classes.commentDiv }, value)));
};
export var CommentLine = withStyles(styles)(CommentLineBase);
