var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { createMuiTheme } from "@material-ui/core/styles";
import { blue, green, indigo, teal, amber, red, lime, deepOrange, } from "@material-ui/core/colors";
export var TrafficLightState;
(function (TrafficLightState) {
    TrafficLightState[TrafficLightState["Unknown"] = 0] = "Unknown";
    TrafficLightState[TrafficLightState["Good"] = 1] = "Good";
    TrafficLightState[TrafficLightState["Warning"] = 2] = "Warning";
    TrafficLightState[TrafficLightState["Bad"] = 3] = "Bad";
})(TrafficLightState || (TrafficLightState = {}));
var CATEGORY_COLORS = {
    unknown: ["#000000", "#7f7f7f", "#c7c7c7"],
    good: ["#2ca02c", "#1f77b4", "#bcbd22", "#98df8a", "#aec7e8", "#dbdb8d"],
    warning: ["#ff7f0e", "#8c564b", "#ffbb78", "#c49c94"],
    bad: ["#d62728", "#9467bd", "#e377c2", "#ff9896", "#c5b0d5", "#f7b6d2"],
};
var getPlotColors = function (plotPalettes, primary) {
    var palettes = __spreadArrays([
        primary
    ], plotPalettes.filter(function (p) { return p !== primary; }));
    return palettes.map(function (p) { return p[500]; });
};
var typography = {
    fontFamily: ["Lato", "Helvetica"].join(","),
};
var defaultTheme = createMuiTheme({ typography: typography });
var lightPlotPalettes = [
    green,
    blue,
    deepOrange,
    lime,
    red,
    teal,
    amber,
    indigo,
];
var lightSecondary = blue;
export var createLightTheme = function (lightPrimary) {
    var lightPalette = {
        type: "light",
        primary: lightPrimary,
        secondary: lightSecondary,
        plot: getPlotColors(lightPlotPalettes, lightPrimary),
        categoryColors: CATEGORY_COLORS,
    };
    var lightTheme = createMuiTheme({
        palette: lightPalette,
        typography: typography,
    });
    return lightTheme;
};
export var createDarkTheme = function (darkPrimary) {
    var darkSecondary = lightSecondary;
    var darkPlotPalettes = lightPlotPalettes;
    var darkPalette = {
        type: "dark",
        primary: darkPrimary,
        secondary: darkSecondary,
        plot: getPlotColors(darkPlotPalettes, darkPrimary),
        categoryColors: CATEGORY_COLORS,
    };
    var darkTheme = createMuiTheme({
        palette: darkPalette,
        typography: typography,
    });
    return darkTheme;
};
