import React from "react";
import { greyColor } from "../../utils";
var rectProps = function (fill) {
    return {
        display: "inline",
        opacity: 1,
        fill: fill,
        fillOpacity: 1,
        strokeWidth: 0,
    };
};
export var LegendColorIcon = function (props) {
    var height = props.height, width = props.width, selected = props.selected;
    var viewBoxBounds = "0 0 " + width + " " + height;
    var fillColor = props.fillColor;
    if (selected === false) {
        fillColor = greyColor(fillColor);
    }
    return (React.createElement("svg", { width: props.width || "16px", height: props.height || "16px", overflow: "visible", viewBox: viewBoxBounds },
        React.createElement("rect", { width: "100%", height: "100%", fill: fillColor, style: rectProps(fillColor || "#590000") })));
};
