import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getRegion } from "../../api/regions";

export interface Region {
  regionId: string;
  startDate: string;
  endDate: string | null;
  hasIssues?: boolean;
  dataQualityScore: number;
  anomalyScore: number;
  metaData: {
    Well: string;
    Vendor: string;
    "Pump Type": string;
    "Corrosion Resistant": string;
    STG: string;
    "Motor Type": string;
    "Power H.P. (60Hz)": number;
    "Cable Type": string;
    "Sensor Type": string;
    "Qty Ch": string;
    "RIH Date": string;
    "Final Commissioning Date": string;
    "Failure Date": string;
    "Data Quality": string;
  } | null;
}

interface RegionsState {
  regions: { [regionId: string]: Region };
  isLoading: boolean;
  error: string | null;
}

const initialState: RegionsState = {
  regions: {},
  isLoading: true,
  error: null,
};

function startLoading(state: RegionsState) {
  state.isLoading = true;
}

function loadingFailed(state: RegionsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    getRegionStart: startLoading,
    getRegionSuccess(state, action: PayloadAction<Region>) {
      state.regions[action.payload.regionId] = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getRegionFailure: loadingFailed,
  },
});

export const {
  getRegionStart,
  getRegionSuccess,
  getRegionFailure,
} = regionsSlice.actions;

export const fetchRegionData = (
  assetId: string,
  regionId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(getRegionStart());
    const assetList = await getRegion(assetId, regionId);
    dispatch(getRegionSuccess(assetList));
  } catch (err) {
    dispatch(getRegionFailure(err.toString()));
  }
};

export default regionsSlice.reducer;
