import React, { useState } from "react";
import { LoadingCard } from "../../components/LoadingCard";
import { dateFormat } from "../../globals";
import moment from "moment";
import pidGraph from "./../../images/pid-graph.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  makeStyles,
  Typography,
  Tabs,
  Tab,
  Button,
  Dialog,
  IconButton,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MetadataTable } from "@toumetis/cascadence-react-ui";

import { RootState } from "../../app/rootReducer";
import { useSelector } from "react-redux";

interface AssetMetaDataProps {
  assetId: string;
  regionId: string;
}

const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      paddingBottom: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(2),
    },
    metaDataError: {
      color: theme.palette.categoryColors.bad[0],
    },
    pidButton: {
      marginTop: theme.spacing(2),
    },
    pidModalImage: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: 1920, // high definition window, use drag to move
      },
    },
    closeButton: {
      position: "fixed",
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: "white",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  };
});

const AssetMetaData = (props: AssetMetaDataProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const modalBreakpointMD = useMediaQuery(theme.breakpoints.down("md"));

  const { assetId, regionId } = props;
  const { assetData: assetDataObj, isLoading: assetDataLoading } = useSelector(
    (state: RootState) => state.assetData
  );
  const { regions, isLoading: regionDataLoading } = useSelector(
    (state: RootState) => state.regions
  );

  // Determines whether to open or close the P&ID Modal
  const [open, setOpen] = React.useState(false);

  // Determines whether to show asset or region metadata
  const [tabIndex, setTabIndex] = useState(0);

  // If we're still retrieving asset or region data, show loading spinner
  if (assetDataLoading || regionDataLoading) return <LoadingCard />;

  // Callback for tab change
  const handleTabChange = (event: React.ChangeEvent<{}>, index: number) =>
    setTabIndex(index);
  const assetData =
    assetDataObj && assetDataObj[assetId] && assetDataObj[assetId].metaData;
  const regionData = regions && regions[regionId] && regions[regionId].metaData;

  // Used to check if we have valid metadata to display based on chosen tab
  const metadata = tabIndex === 0 ? assetData : regionData;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.padding}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        variant="fullWidth"
      >
        <Tab label="Well Properties" />
        <Tab label="Pump Properties" />
      </Tabs>

      {metadata ? (
        <MetadataTable
          metadata={Object.entries(metadata).map((entry) => {
            const [_key, _value] = entry;

            // Change format of dates to the consistent format
            if (
              ["RIH Date", "Final Commissioning Date", "Failure Date"].includes(
                _key
              )
            )
              return {
                key: _key,
                value: _value && moment(_value).format(dateFormat),
              };

            return {
              key: _key,
              value:
                ["string", "undefined"].includes(typeof _value) ||
                _value === null
                  ? _value
                  : _value.toString(),
            };
          })}
          excludeMissingData
          shouldFormatText={false}
        />
      ) : (
        <Typography
          variant="body1"
          align="center"
          className={classes.metaDataError}
        >
          No data found
        </Typography>
      )}

      <Button
        className={classes.pidButton}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Show P&ID
      </Button>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        fullScreen={modalBreakpointMD}
      >
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon fontSize="large" />
        </IconButton>
        <img
          src={pidGraph}
          className={classes.pidModalImage}
          alt={"Piping and Instrumentation Diagram"}
        />
      </Dialog>
    </div>
  );
};

export default AssetMetaData;
