import React, { useState, useEffect } from "react";
import { Tooltip, Zoom, Typography, makeStyles } from "@material-ui/core";
var useStyles = makeStyles(function () { return ({
    barContainer: {
        display: "flex",
        width: "100%",
        height: 30,
    },
    barSection: {
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7,
        },
    },
}); });
var PartitionBar = function (_a) {
    var sectionsArray = _a.sectionsArray, title = _a.title, unitOfMeasure = _a.unitOfMeasure;
    var classes = useStyles();
    var _b = useState(1), valuesTotal = _b[0], setValuesTotal = _b[1];
    var _c = useState([]), sortedSections = _c[0], setSortedSections = _c[1];
    /**
     * Listens for changes in the sectionsArray prop
     *
     */
    useEffect(function () {
        // Sorts the sections by order of increasing value (no need to sort them for now)
        setSortedSections(sectionsArray);
        // Calculates the total values of all the sections
        setValuesTotal(sectionsArray.reduce(function (total, section) { return total + section.value; }, 0));
    }, [sectionsArray]);
    return (React.createElement("div", null,
        title && React.createElement(Typography, { variant: "h6" }, title),
        React.createElement("div", { className: classes.barContainer }, sortedSections.map(function (section) { return (React.createElement(Tooltip, { key: section.value + section.colour, title: React.createElement(Typography, { variant: "body1" }, section.sectionTitle +
                " - " +
                section.value +
                (unitOfMeasure || "")), placement: "top", TransitionComponent: Zoom, arrow: true },
            React.createElement("div", { className: classes.barSection, style: {
                    width: "calc(100% * " + section.value / valuesTotal + ")",
                    backgroundColor: section.colour,
                } }))); }))));
};
export default PartitionBar;
