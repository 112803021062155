import axios from "axios";
import { TagData } from "../features/tagData/tagDataSlice";

interface TagDataResponse {
  id: string;
  times: string[];
  values: number[];
}

interface TagDataRequestParams {
  resampling_method: string;
  resampling_points: number;
  data_id: string;
  region_id: string;
  start_time?: string;
  end_time?: string;
}

export async function getTagData(
  assetId: string,
  regionId: string,
  tagId: string,
  startTime: string | undefined,
  endTime: string | undefined
): Promise<TagData> {
  const url = `${process.env.REACT_APP_API_ROOT}/tag/by_id/`;

  try {
    const params: TagDataRequestParams = {
      resampling_method: "lttb",
      resampling_points: 400,
      data_id: `${assetId}_${tagId}`,
      region_id: regionId,
    };

    if (startTime) params.start_time = startTime;
    if (endTime) params.end_time = endTime;

    const response = await axios.get<TagDataResponse[]>(url, {
      params,
      timeout: 60000, // To go along with server timeout of 60 seconds
    });
    const { id, times, values } = response.data[0];

    return {
      id,
      times,
      values,
    };
  } catch (err) {
    throw err;
  }
}
