import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./app/store";

import "./index.css";
import App from "./app/App";

import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/styles";

import blue from "@material-ui/core/colors/blue";

import CssBaseline from "@material-ui/core/CssBaseline";

import { createLightTheme } from "@toumetis/cascadence-react-ui";

const theme = createLightTheme(blue);

const AppBootstrap = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<AppBootstrap />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
