// The idea behind this component is to watch the size of a component, and provide a state
// wrapper around a child component so that the wrapper's state becomes the size of the
// inner component. It essentially solved the problem of plotly plots not actually being
// responsive as they should be by making it into a reusable piece of logic. Though at this
// point I wonder it's usefulness as you can probably solve this problem in a simpler way
// using JUST sizeme, though there is something to be said about having this importable
// piece of code that solves the plotly problem for you if a developer doesn't necessarily
// know what sizeme is. So it could be a force multiplier in that sense. I still need to
// think about this component a bit more though, maybe it can be useful in a different way
// or be simplified, as this component made Plotly pie charts (famously impossible to make
// behave responsively) actually be responsive thanks to the constant state setting. Inefficient
// and hacky af tho.
// Maybe one idea could be to have a kind of debounce, so that if something has been in a
// position for at least half a second then it will run the script so it's more heavily
// optimised say. Unsure right now.

import React, { useState, useEffect } from "react";
import { SizeMe, SizeMeOptions, SizeMeProps } from "react-sizeme";

const defaultConfiguration = {
  monitorHeight: true,
  monitorWidth: true,
};

export interface ForceResponsive {
  configuration?: SizeMeOptions;
  children: (width: number, height: number) => JSX.Element;
}
const ForceResponsive: React.FC<ForceResponsive> = (props) => {
  return (
    <React.Fragment>
      <SizeMe
        {...(props.configuration ? props.configuration : defaultConfiguration)}
      >
        {({ size }: SizeMeProps) => {
          const w = size.width!!;
          const h = size.height!!;
          return (
            <React.Fragment>
              <Renderable width={w} height={h}>
                {props.children}
              </Renderable>
            </React.Fragment>
          );
        }}
      </SizeMe>
    </React.Fragment>
  );
};

interface RenderableProps {
  width: number;
  height: number;
  children: (width: number, height: number) => JSX.Element;
}

const Renderable: React.FC<RenderableProps> = (props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setWidth(props.width);
    setHeight(props.height);
  }, [props.width, props.height]);
  return <div>{props.children(width, height)}</div>;
};

export default ForceResponsive;
