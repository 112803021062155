import React from "react";
import { createStyles, withStyles, Typography, Card, } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        container: {
            padding: theme.spacing(1) + "px " + theme.spacing(2) + "px",
            position: "absolute",
            right: "10px",
            top: "10px",
        },
    });
};
export var LegendBase = function (props) {
    return (React.createElement(Card, { className: props.classes.container },
        React.createElement(Typography, { variant: "caption" }, props.children)));
};
export var Legend = withStyles(styles, { withTheme: true })(LegendBase);
