export const selectedListItem = "rgba(33, 150, 243, 0.1)";
export const inactiveListItem = "rgba(0, 0, 0, 0.4)";
export const selectedOpenListItemBorder = "rgba(33, 150, 243, 0.5)";
export const selectedCollapseListItemBorder = "rgba(33, 150, 243, 0.03)";
export const muiGrey = "#e0e0e0";
export const background = "#fafafa";
export const mapMarker = "#363636";
export const mapMarkerSelected = "#7bc0f5";
export const anomalyScoreOK = "#6aa84f";
export const anomalyScoreWatch = "#f7b36b";
export const anomalyScoreAnomalous = "#cf2a27";
export const anomalyScoreDisregard = "purple";
export const partitionBarInactive = "#d3d3d3";
export const runningBarOn = "rgba(33, 150, 243, 0.5)";
export const runningBarOff = "rgba(33, 150, 243, 0.1)";
export const bodyFont = "rgba(0, 0, 0, 0.87)";