import axios from "axios";
import { ModelData } from "../features/model/modelDataSlice";

interface ModelDataResponse {
  id: string;
  times: string[]; // Timestamp
  values: number[];
}

interface ModelDataRequestParams {
  resampling_method: string;
  resampling_points: number;
  asset_id: string;
  region_id: string;
  start_time?: string;
  end_time?: string;
}

export async function getModelData(
  assetId: string,
  regionId: string,
  startTime: string | undefined,
  endTime: string | undefined
): Promise<ModelData> {
  const url = `${process.env.REACT_APP_API_ROOT}/model/by_asset`;

  try {
    const params: ModelDataRequestParams = {
      asset_id: assetId,
      region_id: regionId,
      resampling_method: "lttb",
      resampling_points: 400,
    };

    if (startTime) params.start_time = startTime;
    if (endTime) params.end_time = endTime;

    const response = await axios.get<ModelDataResponse[]>(url, {
      params,
      timeout: 60000, // To go along with server timeout of 60 seconds
    });

    // API will return [] if no valid data points found given params
    if (response.data.length === 0) throw new Error("No data points");

    const { id, times, values } = response.data[0];
    return {
      id,
      times,
      values,
    };
  } catch (err) {
    throw err;
  }
}
