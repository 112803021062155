import React, { useState } from "react";

// React Router
import { useParams } from "react-router";
import { RouteParams } from "../app/App";

// Material UI
import {
  makeStyles,
  Grid,
  ButtonGroup,
  Button,
  useTheme,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

// Sensorhealth components
import { BreakpointComponent } from "@toumetis/cascadence-react-ui";

// Constants
import { background } from "../constants/colours";
import {
  headerHeight,
  searchHeight,
  hierarchyToggleHeight,
} from "../constants/sizes";

// Local components
import Header from "../features/navigation/Header";
import AssetList from "../features/assetList/AssetList";
import NotificationList from "../features/issues/NotificationList";
import AnalyticsView from "./RootViews/AnalyticsViewRoot";
import Search from "./Search";

const useStyles = makeStyles((theme) => {
  const arrowSize = 48;

  return {
    padded: {
      padding: theme.spacing(2),
    },
    analyticsColumn: {
      overflowY: "auto",
    },
    selectedButton: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    hierarchyToggleGroup: {
      height: `calc(${hierarchyToggleHeight}px + ${theme.spacing(2)}px)`,
      padding: theme.spacing(2, 2, 0),
    },
    hider: {
      width: "100%",
      position: "sticky",
      top: 0,
      height: theme.spacing(2),
      zIndex: 2,
      background: background,
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    // No selected region
    "@keyframes arrowAnimation": {
      "0%": { left: `calc(-${arrowSize}px - 5%)` },
      "50%": { left: `calc(-${arrowSize}px - 2%)` },
      "100%": { left: `calc(-${arrowSize}px - 5%)` },
    },
    arrowContainer: {
      position: "relative",
    },
    arrow: {
      position: "absolute",
      fontSize: arrowSize,
      animation: "$arrowAnimation 0.8s ease-in-out infinite alternate",
    },
    ctaHeading: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
  };
});

const AnalyticsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { assetId, regionId } = useParams<RouteParams>();
  const regionSelected =
    typeof assetId === "string" &&
    typeof regionId === "string" &&
    assetId.length !== 0 &&
    regionId.length !== 0;

  const [listShowing, setListShowing] = useState("hierarchy");
  const [searchValue, setSearchValue] = useState("");

  /**
   * Method for handling any change in the input of the Search component
   * @param {React.FormEvent<HTMLInputElement>} event
   */
  const handleSearchChange = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <BreakpointComponent
      components={{
        xs: () => (
          <>
            <Header showBreadcrumb showNoBreadcrumbLink={regionSelected} />
            <Grid
              container
              direction="column"
              item
              xs={12}
              style={{ height: `calc(100vh - ${headerHeight}px)` }}
            >
              {regionSelected ? (
                <AnalyticsView />
              ) : (
                <>
                  <ButtonGroup
                    className={classes.hierarchyToggleGroup}
                    fullWidth
                  >
                    <Button
                      onClick={() => setListShowing("hierarchy")}
                      className={
                        listShowing === "hierarchy"
                          ? classes.selectedButton
                          : ""
                      }
                    >
                      Hierarchy
                    </Button>
                    <Button
                      onClick={() => setListShowing("issues")}
                      className={
                        listShowing === "issues" ? classes.selectedButton : ""
                      }
                    >
                      Issues
                    </Button>
                  </ButtonGroup>
                  <div
                    style={{
                      height: `calc(100% - ${searchHeight}px)`,
                      padding: theme.spacing(2),
                    }}
                  >
                    <div className={classes.row}>
                      <Search
                        value={searchValue}
                        onChangeValue={handleSearchChange}
                      />
                    </div>
                    {listShowing === "hierarchy" ? (
                      <AssetList
                        searchValue={searchValue}
                        mobile
                        height={`calc(100% - ${searchHeight}px - ${theme.spacing(
                          2
                        )}px)`}
                      />
                    ) : (
                      <NotificationList
                        showFilters
                        mobile
                        searchValue={searchValue}
                        height={`calc(100% - ${searchHeight}px - ${theme.spacing(
                          2
                        )}px)`}
                      />
                    )}
                  </div>
                </>
              )}
            </Grid>
          </>
        ),
        md: () => (
          <>
            <Header showBreadcrumb />
            <Grid container item xs={12}>
              <Grid
                item
                md={6}
                lg={5}
                xl={3}
                className={classes.padded}
                style={{
                  height: `calc(100vh - ${headerHeight}px)`,
                }}
              >
                <div className={classes.row}>
                  <Search
                    value={searchValue}
                    onChangeValue={handleSearchChange}
                  />
                </div>
                <div
                  className={classes.row}
                  style={{
                    height: `calc(50% - ${searchHeight / 2}px - ${theme.spacing(
                      2
                    )}px)`,
                  }}
                >
                  <AssetList searchValue={searchValue} />
                </div>
                <NotificationList
                  title="Fleet Issues"
                  showFilters
                  searchValue={searchValue}
                  height={`calc(50% - ${searchHeight / 2}px - ${theme.spacing(
                    2
                  )}px)`}
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={7}
                xl={9}
                className={classes.analyticsColumn}
                style={{
                  height: `calc(100vh - ${headerHeight}px)`,
                  paddingRight: theme.spacing(2), // Space for toolbar/scrollbar
                }}
              >
                {regionSelected ? (
                  <Grid>
                    <div className={classes.hider} />
                    <AnalyticsView />
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        md={8}
                        lg={7}
                        xl={4}
                      >
                        <Grid container item className={classes.arrowContainer}>
                          <ArrowBack className={classes.arrow} />
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          alignItems="center"
                        >
                          <Typography
                            variant="h3"
                            className={classes.ctaHeading}
                          >
                            Start by choosing an Asset / Issue
                          </Typography>
                          <Typography variant="body1">
                            To choose, use the Asset Hierarchy or Fleet Issues
                            list on the left hand side.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        ),
      }}
    />
  );
};

export default AnalyticsPage;
