import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { ToumetisIcon } from "../ToumetisIcon/ToumetisIcon";
var styles = function (theme) {
    return createStyles({
        loading: {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
    });
};
var LoadingOverlayBase = function (props) {
    var loading = props.loading, classes = props.classes, size = props.size;
    return loading ? (React.createElement("div", { className: classes.loading },
        React.createElement(ToumetisIcon, { spin: true, pointer: true, size: size || 40 }))) : null;
};
export var LoadingOverlay = withStyles(styles)(LoadingOverlayBase);
