import * as React from "react";
import { Table, TableBody, TableRow, TableCell, Typography, } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        header: {
            textAlign: "left",
            marginBottom: theme.spacing(1),
        },
        tableRow: {
            height: theme.spacing(3),
        },
        tableCell: {
            border: "none",
            cursor: "pointer",
        },
        tableHeading: {
            fontWeight: 500,
        },
    });
};
var InfoBoxBase = function (props) {
    var classes = props.classes;
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { size: "small" },
            React.createElement(TableBody, null, props.lines.map(function (row) { return (React.createElement(TableRow, { className: classes.tableRow, key: row.id },
                React.createElement(TableCell, { className: classes.tableCell },
                    React.createElement(Typography, { component: "div", variant: "body2" },
                        row.name && row.name + ": ",
                        row.value)))); })))));
};
export var InfoBox = withStyles(styles)(InfoBoxBase);
