import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
  scrollTo: string | null;
  scrolling: "ready" | "scrolling";
}

const initialState: NavigationState = {
  scrollTo: null,
  scrolling: "ready",
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavigation(state, action: PayloadAction<NavigationState>) {
      state.scrollTo = action.payload.scrollTo;

      // Set to true from breadcrumb click
      // Set back to null in AssetList when scroll is done
      state.scrolling = action.payload.scrolling;
    },
  },
});

export const { setNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
