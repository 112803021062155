import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAssetList } from "../../api/asset";
import { AppThunk } from "../../app/store";
import { Region } from "../regions/regionsSlice";

export interface StationListItem {
  [key: string]: AssetListItem;
}

export interface AssetListItem {
  assetId: string;
  regions: Region[]; // TODO is this correct?
}

export interface AssetData {
  assetId: string;
  station: string;
  location: {
    lat: number | null;
    long: number | null;
  };
  activeRegion: {
    id: number;
    anomalyScore: number;
  };
  running: boolean;
  metaData: {
    Station: string;
    Downtime: string;
    "ESP Status": string;
    "Last Shutdown Reason": string;
    "Last Shutdown Reason Value": string | null;
  };
}

export interface AssetDictionary {
  [key: string]: StationListItem;
}

interface AssetListState {
  assetList: AssetDictionary;
  isLoading: boolean;
  error: string | null;
}

const initialState: AssetListState = {
  assetList: {},
  isLoading: true,
  error: null,
};

function startLoading(state: AssetListState) {
  state.isLoading = true;
  state.error = null;
}

function loadingFailed(state: AssetListState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const assetListSlice = createSlice({
  name: "assetList",
  initialState,
  reducers: {
    getAssetListStart: startLoading,
    getAssetListSuccess(state, action: PayloadAction<AssetDictionary>) {
      state.assetList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getAssetListFailure: loadingFailed,
  },
});

export const {
  getAssetListStart,
  getAssetListSuccess,
  getAssetListFailure,
} = assetListSlice.actions;

export const fetchAssetList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getAssetListStart());
    const assetList = await getAssetList();
    dispatch(getAssetListSuccess(assetList));
  } catch (err) {
    dispatch(getAssetListFailure(err.toString()));
  }
};

export default assetListSlice.reducer;
