import React, { useState, useEffect, CSSProperties } from "react";
import Header from "../features/navigation/Header";
import {
  PartitionBar,
  BreakpointComponent,
} from "@toumetis/cascadence-react-ui";
import { roundPercentages } from "../utils";

// Material UI
import {
  Typography,
  makeStyles,
  Grid,
  Card,
  ButtonGroup,
  Button,
  Divider,
  useTheme,
} from "@material-ui/core";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../app/rootReducer";

import NotificationList from "../features/issues/NotificationList";

// Constants
import {
  anomalyScoreOK,
  anomalyScoreWatch,
  anomalyScoreAnomalous,
  anomalyScoreDisregard,
  partitionBarInactive,
  runningBarOn,
  runningBarOff,
} from "../constants/colours";
import { headerHeight, hierarchyToggleHeight } from "../constants/sizes";

import { AssetMapLegend } from "../features/assetData/AssetMapLegend";
import { AssetDataDictionary } from "../features/assetData/assetDataSlice";
import AssetMap from "features/assetData/AssetMap";
import { LoadingCard } from "./LoadingCard";
import { AssetData } from "features/assetList/assetListSlice";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: `calc(100vh - ${headerHeight}px)`,
  },
  fleetHealthCard: {
    marginBottom: theme.spacing(2),
  },
  cardTitle: { paddingBottom: theme.spacing(1) },
  partitionBarContainer: {
    padding: theme.spacing(2),
  },
  mapContainer: {
    position: "relative",
    minHeight: 250,
    [theme.breakpoints.up("md")]: {
      minHeight: 300,
    },
  },
  paneToggle: {
    height: hierarchyToggleHeight,
    marginBottom: theme.spacing(2),
  },
  selectedToggle: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  partitionTitle: {
    fontSize: "1.1rem",
    fontWeight: 700,
    paddingTop: theme.spacing(1),
  },
  metadataValue: {
    fontWeight: 900,
  },
}));

interface PartitionBarCounts {
  [category: string]: number;
}

interface FleetHealthData {
  runningAssetsCount: number;
  activeAssetsCount: number;
  anomalyCategories: PartitionBarCounts;
  runningCategories: PartitionBarCounts;
  assetData: AssetDataDictionary;
}

interface FleetHealthDataState {
  [section: string]: FleetHealthData;
}

const createEmptyAnomalyCategories = () => ({
  green: 0,
  amber: 0,
  red: 0,
  purple: 0,
});

const createEmptyRunningCategories = () => ({
  producing: 0,
  shut: 0,
});

// Generates sections for health PartitionBar
const generateHealthSectionsArray = (
  anomalyCategories: PartitionBarCounts,
  activeAssetsCount: number
) => {
  // Calculate percentages from pumps assigned to each category
  const healthPercentages: PartitionBarCounts = roundPercentages([
    {
      name: "green",
      value: (anomalyCategories["green"] / activeAssetsCount) * 100,
    },
    {
      name: "amber",
      value: (anomalyCategories["amber"] / activeAssetsCount) * 100,
    },
    {
      name: "red",
      value: (anomalyCategories["red"] / activeAssetsCount) * 100,
    },
    {
      name: "purple",
      value: (anomalyCategories["purple"] / activeAssetsCount) * 100,
    },
  ]);

  return activeAssetsCount !== 0
    ? [
      {
        sectionTitle: "Snoozed",
        value: healthPercentages["purple"],
        colour: anomalyScoreDisregard,
      },
        {
          sectionTitle: "OK",
          value: healthPercentages["green"],
          colour: anomalyScoreOK,
        },
        {
          sectionTitle: "Watch",
          value: healthPercentages["amber"],
          colour: anomalyScoreWatch,
        },
        {
          sectionTitle: "Anomalous",
          value: healthPercentages["red"],
          colour: anomalyScoreAnomalous,
        },
      ]
    : [
        {
          sectionTitle: "No active pumps",
          value: 100,
          colour: partitionBarInactive,
        },
      ];
};

// Generates sections for running PartitionBar
const generateRunningSectionsArray = (
  runningCategories: PartitionBarCounts,
  activeAssetsCount: number
) => {
  // Calculate percentages from pumps assigned to each category
  const runningPercentages: PartitionBarCounts = roundPercentages([
    {
      name: "producing",
      value: (runningCategories["producing"] / activeAssetsCount) * 100,
    },
    {
      name: "shut",
      value: (runningCategories["shut"] / activeAssetsCount) * 100,
    },
  ]);

  return activeAssetsCount !== 0
    ? [
        {
          sectionTitle: "Producing",
          value: runningPercentages["producing"],
          colour: runningBarOn,
        },
        {
          sectionTitle: "Shut In",
          value: runningPercentages["shut"],
          colour: runningBarOff,
        },
      ]
    : [
        {
          sectionTitle: "No active pumps",
          value: 100,
          colour: partitionBarInactive,
        },
      ];
};

const northStations =
  (process.env.REACT_APP_NORTH_STATIONS &&
    process.env.REACT_APP_NORTH_STATIONS.split("|")) ||
  [];
const southStations =
  (process.env.REACT_APP_SOUTH_STATIONS &&
    process.env.REACT_APP_SOUTH_STATIONS.split("|")) ||
  [];

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { assetData, isLoading: assetDataLoading } = useSelector(
    (state: RootState) => state.assetData
  );

  const history = useHistory();

  // Mobile toggle
  const [paneShowing, setPaneShowing] = useState("fleetHealth");

  // Holds all the data required for the fleet health pane
  const [fleetHealthData, setFleetHealthData] = useState<FleetHealthDataState>(
    {}
  );

  useEffect(() => {
    // North
    let northRunningAssetsCount = 0;
    let northActiveAssetsCount = 0;
    const northAnomalyCategories: PartitionBarCounts = createEmptyAnomalyCategories();
    const northRunningCategories: PartitionBarCounts = createEmptyRunningCategories();
    const northAssetData: AssetDataDictionary = {};

    // South
    let southRunningAssetsCount = 0;
    let southActiveAssetsCount = 0;
    const southAnomalyCategories: PartitionBarCounts = createEmptyAnomalyCategories();
    const southRunningCategories: PartitionBarCounts = createEmptyRunningCategories();
    const southAssetData: AssetDataDictionary = {};

    Object.values(assetData).forEach((asset) => {
      const { assetId, station, activeRegion, running } = asset;
      const { id, anomalyScore } = activeRegion;

      let anomalyCategory;
      if (anomalyScore) {
        // Categorize the assets based upon their active region's anomaly score
        if (anomalyScore < 500) anomalyCategory = "green";
        else if (anomalyScore < 1000) anomalyCategory = "amber";
        else if (anomalyScore < 1500) anomalyCategory = "red";
        else anomalyCategory = "purple";
      }

      // ACTIVE - currently commissioned pump
      // RUNNING - producing oil (based on FREQ.CV tag value)
      if (northStations.includes(station)) {
        // Check if there is active region
        if (id) {
          northActiveAssetsCount++;
          if (anomalyCategory) northAnomalyCategories[anomalyCategory]++;

          // Assumption here that running pump would be active
          if (running) {
            northRunningAssetsCount++;
            northRunningCategories["producing"]++;
          } else {
            northRunningCategories["shut"]++;
          }
        }

        northAssetData[assetId] = asset;
      } else if (southStations.includes(station)) {
        // Check if there is active region
        if (id) {
          southActiveAssetsCount++;
          if (anomalyCategory) southAnomalyCategories[anomalyCategory]++;

          // Assumption here that running pump would be active
          if (running) {
            southRunningAssetsCount++;
            southRunningCategories["producing"]++;
          } else {
            southRunningCategories["shut"]++;
          }
        }

        southAssetData[assetId] = asset;
      }
    });

    setFleetHealthData({
      north: {
        runningAssetsCount: northRunningAssetsCount,
        activeAssetsCount: northActiveAssetsCount,
        anomalyCategories: northAnomalyCategories,
        runningCategories: northRunningCategories,
        assetData: northAssetData,
      },
      south: {
        runningAssetsCount: southRunningAssetsCount,
        activeAssetsCount: southActiveAssetsCount,
        anomalyCategories: southAnomalyCategories,
        runningCategories: southRunningCategories,
        assetData: southAssetData,
      },
    });
  }, [assetData]);

  const FleetHealthSection = (props: { section: string; mobile: boolean }) => {
    const { section, mobile } = props;
    if (!fleetHealthData[section]) return null;

    const isNorth = section === "north";
    const {
      runningAssetsCount,
      activeAssetsCount,
      anomalyCategories,
      runningCategories,
      assetData,
    } = fleetHealthData[section];

    return (
      <Card
        className={classes.fleetHealthCard}
        style={{
          height: mobile ? `calc(50% - ${theme.spacing(1)}px)` : "auto",
        }}
      >
        <Grid container direction="row">
          <Grid item className={classes.partitionBarContainer} xs={12} lg={6}>
            <Typography variant="h5" className={classes.cardTitle}>
              {`${isNorth ? "North" : "South"} Rumaila`}
            </Typography>
            <Divider className={classes.divider} />
            {activeAssetsCount !== 0 ? (
              <Typography variant="h6" className={classes.partitionTitle}>
                Health
              </Typography>
            ) : (
              <Typography variant="h6">Health: No active pumps</Typography>
            )}
            <PartitionBar
              unitOfMeasure="%"
              sectionsArray={generateHealthSectionsArray(
                anomalyCategories,
                activeAssetsCount
              )}
            />
            {activeAssetsCount !== 0 ? (
              <Typography variant="h6" className={classes.partitionTitle}>
                Running
              </Typography>
            ) : (
              <Typography variant="h6">Running: No active pumps</Typography>
            )}
            <PartitionBar
              unitOfMeasure="%"
              sectionsArray={generateRunningSectionsArray(
                runningCategories,
                activeAssetsCount
              )}
            />
            <Divider flexItem className={classes.divider} />
            <Typography variant="body1">
              Total Running ESPs:{" "}
              <span className={classes.metadataValue}>
                {runningAssetsCount}
              </span>
            </Typography>
            <Typography variant="body1">
              Total ESPs:{" "}
              <span className={classes.metadataValue}>{activeAssetsCount}</span>
            </Typography>
            <Typography variant="body1">
              Flow Rate:
              <span className={classes.metadataValue}> ? BOED</span>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.mapContainer}>
            <AssetMap
              assets={assetData}
              colorMode="anomaly_score"
              fillMode="show_producing"
              onMarkerClick={(asset: AssetData) => {
                history.push(
                  `/wells/${asset.assetId}/pumps/${asset.activeRegion.id}`
                );
              }}
            />
            {!isEmpty(assetData) && <AssetMapLegend />}
          </Grid>
        </Grid>
      </Card>
    );
  };

  const FleetHealth = (props: { mobile?: Boolean }) => {
    const { mobile } = props;

    return (
      <Grid item xs={12} md={6} lg={7} style={{ height: "100%" }}>
        {assetDataLoading || isEmpty(assetData) ? (
          <Card>
            <LoadingCard />
          </Card>
        ) : (
          <>
            <FleetHealthSection section="north" mobile={Boolean(mobile)} />
            <FleetHealthSection section="south" mobile={Boolean(mobile)} />
          </>
        )}
      </Grid>
    );
  };

  const WatchList = (props: { mobile?: Boolean; style: CSSProperties }) => {
    const { mobile, style } = props;

    return (
      <Grid item xs={12} md={6} lg={5} style={style}>
        <NotificationList
          title="Watch List"
          mobile={Boolean(mobile)}
          watchList
        />
      </Grid>
    );
  };

  return (
    <BreakpointComponent
      components={{
        xs: () => (
          <>
            <Header />
            <Grid container className={classes.container}>
              <ButtonGroup className={classes.paneToggle} fullWidth>
                <Button
                  onClick={() => setPaneShowing("fleetHealth")}
                  className={
                    paneShowing === "fleetHealth" ? classes.selectedToggle : ""
                  }
                >
                  Fleet Health
                </Button>
                <Button
                  onClick={() => setPaneShowing("watchList")}
                  className={
                    paneShowing === "watchList" ? classes.selectedToggle : ""
                  }
                >
                  Watch List
                </Button>
              </ButtonGroup>
              {paneShowing === "fleetHealth" ? (
                <FleetHealth mobile />
              ) : (
                <WatchList
                  mobile
                  style={{
                    height: `calc(100% - ${hierarchyToggleHeight}px - ${theme.spacing(
                      2
                    )}px)`,
                  }}
                />
              )}
            </Grid>
          </>
        ),
        md: () => (
          <>
            <Header />
            <Grid container direction="row" className={classes.container}>
              <FleetHealth />
              <WatchList
                style={{ height: "100%", paddingLeft: theme.spacing(2) }}
              />
            </Grid>
          </>
        ),
      }}
    />
  );
};

export default HomePage;
