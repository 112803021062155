import { LoginFormData } from "@toumetis/cascadence-react-ui";
import axios from "axios";
import { LoginPayload } from "../features/auth/authSlice";

interface AuthTokenResponse {
  access_token: string;
  token_type: string;
}

export async function getToken(
  loginData: LoginFormData
): Promise<LoginPayload> {
  const url = `${process.env.REACT_APP_API_ROOT}/login/token`;

  try {
    const data = new FormData();
    data.append("username", loginData.username);
    data.append("password", loginData.password);
    const authResponse = await axios.post<AuthTokenResponse>(url, data);
    return {
      token: authResponse.data.access_token,
    };
  } catch (err) {
    throw err;
  }
}
