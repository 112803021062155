import axios from "axios";
import { Region } from "../features/regions/regionsSlice";

interface RegionsResponse {
  region_id: string;
  start_datetime: string;
  end_datetime: string;
  dq_score: number;
  anomaly_score: number;
  meta_data: {
    well: string;
    Vendor: string;
    "PUMP TYPE": string;
    "Corrosion Resistant": string;
    STG: string;
    "MOTOR TYPE": string;
    "Power H.P. (60Hz)": number;
    "Cable Type": string;
    "Sensor Type": string;
    "Qty Ch": string;
    "rih date": string;
    "final commissioning date": string;
    failure_date: string;
  };
}

export async function getRegion(
  assetId: string,
  regionId: string
): Promise<Region> {
  const url = `${process.env.REACT_APP_API_ROOT}/region/asset`;

  try {
    const response = await axios.get<RegionsResponse[]>(url, {
      params: {
        asset_id: assetId,
      },
      timeout: 5000,
    });

    // We get back an array with all the regions that match an assetId
    const dataArray = response.data;

    // We need to match the element from the array that matches both the assetId and regionId passed.
    // Why is regionId a string? Using == instead of === to bypass that for now.
    const data = dataArray.find(
      (element: RegionsResponse) => element.region_id == regionId
    );
    if (data) {
      return {
        regionId: data.region_id,
        startDate: data.start_datetime,
        endDate: data.end_datetime,
        dataQualityScore: data.dq_score,
        anomalyScore: data.anomaly_score,
        metaData: {
          Well: data.meta_data["well"],
          Vendor: data.meta_data["Vendor"],
          "Pump Type": data.meta_data["PUMP TYPE"],
          "Corrosion Resistant": data.meta_data["Corrosion Resistant"],
          STG: data.meta_data["STG"],
          "Motor Type": data.meta_data["MOTOR TYPE"],
          "Power H.P. (60Hz)": data.meta_data["Power H.P. (60Hz)"],
          "Cable Type": data.meta_data["Cable Type"],
          "Sensor Type": data.meta_data["Sensor Type"],
          "Qty Ch": data.meta_data["Qty Ch"],
          "RIH Date": data.meta_data["rih date"],
          "Final Commissioning Date":
            data.meta_data["final commissioning date"],
          "Failure Date": data.meta_data["failure_date"],
          "Data Quality": data.dq_score > 0.7 ? "Passed" : "Failed",
        },
      };
    } else
      return {
        regionId: "",
        startDate: "",
        endDate: "",
        dataQualityScore: NaN,
        anomalyScore: NaN,
        metaData: {
          Well: "",
          Vendor: "",
          "Pump Type": "",
          "Corrosion Resistant": "",
          STG: "",
          "Motor Type": "",
          "Power H.P. (60Hz)": NaN,
          "Cable Type": "",
          "Sensor Type": "",
          "Qty Ch": "",
          "RIH Date": "",
          "Final Commissioning Date": "",
          "Failure Date": "",
          "Data Quality": "",
        },
      };
  } catch (err) {
    throw err;
  }
}
