var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { withTheme } from "@material-ui/core";
import { DefaultPlot, withSizeHoc } from "../DefaultPlot";
import { TimeseriesAnalyticsLayoutManager } from "./TimeseriesAnalyticsLayoutManager";
var TimeseriesAnalyticsBase = /** @class */ (function (_super) {
    __extends(TimeseriesAnalyticsBase, _super);
    function TimeseriesAnalyticsBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TimeseriesAnalyticsBase.prototype.render = function () {
        var _a = this.props, timeseriesList = _a.timeseriesList, showXAxes = _a.showXAxes, showYAxes = _a.showYAxes, columns = _a.columns, singleChart = _a.singleChart, size = _a.size, theme = _a.theme, height = _a.height, showEmpty = _a.showEmpty, tickFormat = _a.tickFormat, hoverFormat = _a.hoverFormat, debuggingShapes = _a.debuggingShapes, onRelayout = _a.onRelayout, revision = _a.revision;
        var width = size.width || 700;
        var numSeries = timeseriesList.length;
        if (!(numSeries || showEmpty)) {
            return null;
        }
        var plotDescriptions = TimeseriesAnalyticsLayoutManager.PlotDescriptionFromTs(timeseriesList, !!singleChart);
        var layoutManager = new TimeseriesAnalyticsLayoutManager();
        layoutManager.tickFormat = tickFormat || layoutManager.tickFormat;
        layoutManager.hoverFormat = tickFormat || layoutManager.hoverFormat;
        if (singleChart) {
            layoutManager.addPlotGroup(plotDescriptions, 1, height || 440, showXAxes !== undefined ? showXAxes : true, showYAxes !== undefined ? showYAxes : true, 0);
        }
        else {
            layoutManager.addPlotGroup(plotDescriptions, columns || 1, height || 180, showXAxes !== undefined ? showXAxes : true, showYAxes !== undefined ? showYAxes : true, 0);
        }
        var layout = layoutManager.createLayout(width, this.props, theme.palette.secondary.light);
        var data = layoutManager.data;
        if (debuggingShapes) {
            layoutManager.addDebuggingShapes(layout);
            console.log(data);
            console.log(layout);
        }
        return (React.createElement("div", { style: { position: "relative" } },
            React.createElement(DefaultPlot, { data: data, layout: layout, onRelayout: onRelayout, revision: revision }),
            this.props.children));
    };
    return TimeseriesAnalyticsBase;
}(React.Component));
export var TimeseriesAnalytics = withSizeHoc(withTheme(TimeseriesAnalyticsBase));
