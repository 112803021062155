// See the following doc for the current tag ids and their corresponding names.
// docs.google.com/spreadsheets/d/10PQksYbzY6kQQTO7VcehPgFKBVOcLcZA29va3mstbDU/edit#gid=0

export const mapTagIdToUnitOfMeasure = (id: string) =>
  tagIDUnitOfMeasureMappings[id.replace(/^([^\_\s]+)_/, "")];

export const tagIDUnitOfMeasureMappings: Record<string, string> = {
  "AMBTEMP.CV": " °C",
  "DC_BUS.CV": " V",
  "DISPRES.CV": " PSIA",
  "DISPRESS_HH.CV": " PSIA",
  "ILEAK.CV": " A",
  "I_VSD.CV": " A",
  "I_VSD_A.CV": " A",
  "I_VSD_B.CV": " A",
  "I_VSD_C.CV": " A",
  "INTPRES.CV": " PSIA",
  "INTPRESS_LL.CV": " PSIA",
  "INTTEMP.CV": " °C",
  "MOTTEMP.CV": " °C",
  "MOTTEMP.HH.CV": " °C",
  "PRESS_DS.CV": " PSIG",
  "PRESS_DS_BATTV.CV": " V",
  "PRESS_DS_TAMPER.CV": "", // currently unknown
  "PRESS_US.CV": " PSIG",
  "PRESS_US_BATTV.CV": " V",
  "PRESS_US_TAMPER.CV": "", // currently unknown
  "TEMP_ANN.CV": " °C",
  "TEMP_ANN_BATTV.CV": "V",
  "TEMP_FL.CV": " °C",
  "TEMP_FL_BATTV.CV": "V",
  "TEMP_TUB.CV": " °C",
  "TEMP_TUB_BATTV.CV": "V",
  "TEMP_US.CV": " °C",
  "TEMP_US_BATTV.CV": " °C",
  "VAB.CV": " V",
  "VIBX.CV": "", // currently unknown
  "VIBY.CV": "",
  "VIBZ.CV": "",
  "FREQ.CV": " Hz",
  well: "",
  "Start date": "",
  "failure date": "",
  "POOH date": "",
  Timestamp: "",
};
