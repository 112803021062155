import * as React from "react";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Particles from "react-particles-js";
var useStyles = makeStyles(function (theme) { return ({
    root: {
        height: "100%",
        width: "100%",
        position: "relative",
    },
    formRoot: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}); });
var getDefaultParticleSettings = function (theme) { return ({
    particles: {
        color: {
            value: theme.palette.grey["500"],
        },
        lineLinked: {
            color: theme.palette.grey["500"],
        },
        number: {
            value: 50,
        },
        size: {
            value: 2,
        },
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
            },
        },
    },
}); };
export var LoginView = function (props) {
    var classes = useStyles();
    var theme = useTheme();
    return (React.createElement("div", { className: classes.root },
        React.createElement(Particles, { width: "100%", height: "100%", params: props.particleParams
                ? props.particleParams
                : getDefaultParticleSettings(theme) }),
        React.createElement("div", { className: classes.formRoot }, props.children)));
};
