import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import isEmpty from "lodash.isempty";

// React Router
import { useParams, useHistory } from "react-router";
import { RouteParams } from "../../app/App";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { fetchTagData } from "../../features/tagData/tagDataSlice";
import { fetchRegionData } from "../../features/regions/regionsSlice";
import { fetchModelData } from "../../features/model/modelDataSlice";
import { Issue } from "../../features/issues/issuesSlice";

// Material UI
import {
  makeStyles,
  Grid,
  Card,
  Tooltip,
  IconButton,
  CardHeader,
  CardContent,
  Hidden,
  Button,
  Typography,
} from "@material-ui/core";
import { Spellcheck as NameToggleIcon, ChevronLeft } from "@material-ui/icons";

// Sensorhealth components
import {
  HighlightRange,
  XAxisToggle,
  YAxisToggle,
  FullScreenToggle,
  SingleChartToggle,
  ColumnMenu,
  Toolbar,
  ToolbarButtonProps,
  TimeseriesAnalytics,
} from "@toumetis/cascadence-react-ui";

// Constants
import { mapTagIdToName } from "./../../constants/TagIDNameMappings";
import { mapTagIdToUnitOfMeasure } from "./../../constants/TagIDUnitOfMeasureMappings";
import { dateFormatPlotly } from "../../globals";
import { background } from "./../../constants/colours";

// Utility functions
import { extractIssuesForRegion } from "../../utils";

// Local components
import AnomalyScore from "../../features/model/AnomalyScore";
import AssetMap from "../../features/assetData/AssetMap";
import { AssetMapLegend } from "features/assetData/AssetMapLegend";
import AssetMetaData from "../../features/assetData/AssetMetaData";
import ForceResponsive from "../../components/ResponsiveComponent";
import { LoadingCard } from "components/LoadingCard";

const useStyles = makeStyles((theme) => {
  const toolbarSize = 48;
  return {
    analyticsContainer: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    container: {
      paddingRight: theme.spacing(2), // make gutter for the toolbar
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2, 2, 2),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
      },
    },
    mobileToolbarContainer: {
      position: "sticky",
      top: 0,
      padding: theme.spacing(2, 2, 0),
      backgroundColor: background,
      zIndex: 2,
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    column: {
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0, // row margin covers last column on mobile
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: `calc((100% - ${theme.spacing(2)}px)/2)`,
        marginBottom: 0,
      },
    },
    metadataColumn: {
      [theme.breakpoints.up("lg")]: {
        order: 2,
      },
    },
    mapColumn: {
      position: "relative",
      [theme.breakpoints.up("lg")]: {
        order: 1,
        marginRight: theme.spacing(2),
      },
    },
    anomalyScoreContainer: {
      // disable sticky on mobile unless better solution for comparing graphs with efficient space usage arises
      [theme.breakpoints.up("md")]: {
        position: "sticky",
        top: theme.spacing(2),
        zIndex: 1, // 1300 is used for Material UI modals
        overflow: "visible", // Required for datepicker to overlap
      },
    },
    toolbar: {
      position: "sticky",
      top: theme.spacing(2),
      width: toolbarSize,
      height: `calc(${toolbarSize}px * 6)`, // toolbar height === how many buttons there are in the toolbar * square width
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",

        // Toolbar set to horizontal instead
        height: toolbarSize,
        width: `calc(${toolbarSize}px * 6)`,
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: theme.spacing(2),
        marginBottom: 0,
      },
    },
    fullScreenToolbar: {
      position: "sticky",
      zIndex: 1,
      height: toolbarSize,
      width: `calc(${toolbarSize}px * 6)`,
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    cardHeader: {
      paddingBottom: 0,
    },
    tagGraphs: {
      marginTop: 0,
    },
    anomalyGraph: {
      paddingTop: theme.spacing(2),
    },
    graphCard: {
      paddingTop: 0,
    },
    tagGraphsOnFullscreen: {
      width: "100vw",
      height: "100vh",
      overflow: "scroll",
      backgroundColor: "white",
    },
    navButton: {
      height: toolbarSize - 1, // because of contrasting colour, this looks bigger than toolbar, so minute reduction in size
      textTransform: "none",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2), // *very* small screens. Flex wrap around, create gap between button and toolbar
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0),
        width: "100%",
      },
    },
    xsButtonContainer: {
      width: "100%",
      backgroundColor: theme.palette.grey.A100,
    },
    xsButtonAligner: {
      width: "100%",
      textAlign: "left",
      alignItems: "center",
      display: "flex",
    },
  };
});

// Name toggle button. This button does not come with SH Common, but uses the same
// patterns as SH Common's buttons. This name toggling only applies to BP thus I
// implemented it here.
const NameToggle: React.FunctionComponent<ToolbarButtonProps> = (props) => {
  return (
    <Tooltip
      title={
        props.selected ? "Label Y Axis by Tag IDs" : "Label Y Axis by Tag Names"
      }
    >
      <IconButton onClick={props.onClick}>
        <NameToggleIcon />
      </IconButton>
    </Tooltip>
  );
};

const AnalyticsView = () => {
  const classes = useStyles();
  const { assetId, regionId } = useParams<RouteParams>();
  const history = useHistory();
  const showNavList = !assetId && !regionId;

  const dispatch = useDispatch();

  // For controlling graph custom date inputs
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Model graph
  const { modelData } = useSelector((state: RootState) => state.modelData);

  // Asset map
  const { assetData } = useSelector((state: RootState) => state.assetData);

  // Issue highlighting
  const { issues } = useSelector((state: RootState) => state.issues);
  const [issueHighlightArray, setIssueHighlightArray] = useState<
    HighlightRange[]
  >([]);
  const [issuesForRegion, setIssuesForRegion] = useState<Issue[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null);

  // Tag graphs
  const { tagData, isLoading: tagDataLoading } = useSelector(
    (state: RootState) => state.tagData
  );
  const fullScreenHandle = useFullScreenHandle();
  const [timeseriesConfig, setTimeseriesConfig] = useState({
    showXAxes: true,
    showYAxes: true,
    columns: 1,
    singleChart: false,
    fullScreenHandle: fullScreenHandle,
    toggleName: false,
  });

  const toggleXAxis = () => {
    setTimeseriesConfig({
      ...timeseriesConfig,
      showXAxes: !timeseriesConfig.showXAxes,
    });
  };
  const toggleYAxis = () => {
    setTimeseriesConfig({
      ...timeseriesConfig,
      showYAxes: !timeseriesConfig.showYAxes,
    });
  };
  const toggleSingleChart = () => {
    setTimeseriesConfig({
      ...timeseriesConfig,
      singleChart: !timeseriesConfig.singleChart,
    });
  };
  const onChooseColumns = (index: number, val: number) => {
    setTimeseriesConfig({ ...timeseriesConfig, columns: val });
  };
  const toggleFullScreen = () => {
    const { fullScreenHandle } = timeseriesConfig;
    fullScreenHandle.active
      ? fullScreenHandle.exit()
      : fullScreenHandle.enter();
    fullScreenHandle.active = !fullScreenHandle.active;
  };
  const toggleName = () => {
    setTimeseriesConfig({
      ...timeseriesConfig,
      toggleName: !timeseriesConfig.toggleName,
    });
  };

  /**
   * Fetches the data for the selected asset (well) and region (pump)
   */
  useEffect(() => {
    if (assetId && regionId) {
      dispatch(fetchTagData(assetId, regionId));
      dispatch(fetchModelData(assetId, regionId));
      dispatch(fetchRegionData(assetId, regionId));
    }
  }, [dispatch, assetId, regionId]);

  /**
   * Sets the issuesForRegion for every change in the issues and the selected region
   */
  useEffect(() => {
    if (assetId && regionId) {
      setIssuesForRegion(extractIssuesForRegion(assetId, regionId, issues));
    }
  }, [issues, assetId, regionId]);

  /**
   * Decides on the selectedIssue in the scenario the issuesForRegion changes
   */
  useEffect(() => {
    if (issuesForRegion.length) {
      if (selectedIssue) {
        // Check if the selectedIssue still exists, select another if it doesn't
        const current = issuesForRegion.find(
          (issue) => issue.id === selectedIssue.id
        );
        !current && setSelectedIssue(issuesForRegion[0]);
      } else {
        // There is no selected issue, so set one
        setSelectedIssue(issuesForRegion[0]);
      }
    } else {
      // There are no issues to select
      setSelectedIssue(null);
    }
  }, [issuesForRegion, selectedIssue, setSelectedIssue]);

  /**
   * Listens for change in selectedIssue and the modelData, and uses that to set the highlight region on the graphs
   */
  useEffect(() => {
    if (
      selectedIssue &&
      assetId &&
      regionId &&
      modelData[assetId] &&
      modelData[assetId][regionId]
    ) {
      const displayedData = modelData[assetId][regionId];

      // Since the graph auto scales to fit the data, we can assume these are the boundaries
      const xMinMoment = moment(displayedData.times[0]);
      const xMaxMoment = moment(
        displayedData.times[displayedData.times.length - 1]
      );

      const issueStartMoment = moment(selectedIssue.startTime);
      const issueEndMoment = moment(selectedIssue.endTime);

      // If the issue is not within the bounds of the graph, don't set any highlight region
      if (
        issueEndMoment.isBefore(xMinMoment) ||
        issueStartMoment.isAfter(xMaxMoment)
      ) {
        setIssueHighlightArray([]);
        return;
      }

      // Set the highlight region depending on the current x axis limits
      setIssueHighlightArray([
        {
          from: xMinMoment.isAfter(issueStartMoment)
            ? xMinMoment.toDate()
            : issueStartMoment.toDate(),
          to: issueEndMoment.isAfter(xMaxMoment)
            ? xMaxMoment.toDate()
            : issueEndMoment.toDate(),
          color: "red",
        },
      ]);
    } else {
      setIssueHighlightArray([]);
    }
  }, [modelData, assetId, regionId, selectedIssue]);

  const onRelayout = (event: Readonly<Plotly.PlotRelayoutEvent>) => {
    // Relayout event can have various properties depending on user action
    // xaxis.autorange - "Autoscale", "Reset axes" buttons or double clicking graph
    // xaxis.range[0], xaxis.range[1] - Either drag zoom or "Zoom in/out" buttons
    if (event["xaxis.autorange"]) {
      dispatch(fetchModelData(assetId, regionId));
      dispatch(fetchTagData(assetId, regionId));
      setStartDate("");
      setEndDate("");
    } else if (event["xaxis.range[0]"] && event["xaxis.range[1]"]) {
      // Ensure the times are in a proper format
      // Plotly can provide just YYYY-MM-DD, which was causing requests to fail
      const xRangeStart = moment(event["xaxis.range[0]"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const xRangeEnd = moment(event["xaxis.range[1]"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      // Make sure we have 2 x coordinates to get dates from
      if (xRangeStart && xRangeEnd) {
        dispatch(fetchModelData(assetId, regionId, xRangeStart, xRangeEnd));
        dispatch(fetchTagData(assetId, regionId, xRangeStart, xRangeEnd));
        setStartDate(xRangeStart);
        setEndDate(xRangeEnd);
      }
    }
  };

  /**
   * Callback for date picker changes
   * @param {boolean} isStart - If true, this is a start date change
   * @param {Date | null} date
   */
  const onDateChange = (isStart: boolean, date: Date | null) => {
    // Basic year validation to prevent parse issues with moment
    const year = date && date.getFullYear();
    if (year && (year < 1900 || year > 9999)) return;

    const newDate = date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
    if (isStart) {
      dispatch(fetchModelData(assetId, regionId, newDate, endDate));
      dispatch(fetchTagData(assetId, regionId, newDate, endDate));
      setStartDate(newDate);
    } else {
      dispatch(fetchModelData(assetId, regionId, startDate, newDate));
      dispatch(fetchTagData(assetId, regionId, startDate, newDate));
      setEndDate(newDate);
    }
  };

  // Ensures we don't show analytics without a selected pump
  if (!assetId || !regionId) return null;

  // Ensures we have some tags to display
  const showTagData =
    tagData && tagData[assetId] && !isEmpty(tagData[assetId][regionId]);

  const AnalyticsToolbar = () => (
    <Toolbar>
      <XAxisToggle onClick={toggleXAxis} />
      <YAxisToggle onClick={toggleYAxis} />
      <FullScreenToggle
        onClick={toggleFullScreen}
        selected={timeseriesConfig.fullScreenHandle.active}
      />
      <ColumnMenu onItemChosen={onChooseColumns} maxColumns={3} />
      {showTagData && (
        <SingleChartToggle
          onClick={toggleSingleChart}
          selected={timeseriesConfig.singleChart}
        />
      )}
      <NameToggle onClick={toggleName} selected={timeseriesConfig.toggleName} />
    </Toolbar>
  );

  return (
    <div className={classes.analyticsContainer}>
      {/* Toolbar for mobile + back button */}
      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.mobileToolbarContainer}
        >
          {!showNavList && (
            <>
              <Hidden xsDown>
                <Button
                  variant="contained"
                  className={classes.navButton}
                  startIcon={<ChevronLeft />}
                  onClick={() => {
                    history.push("/wells");
                  }}
                >
                  Back
                </Button>
              </Hidden>

              <Hidden smUp>
                <Card className={classes.xsButtonContainer}>
                  <Button
                    variant="text"
                    className={classes.navButton}
                    onClick={() => {
                      history.push("/wells");
                    }}
                  >
                    <span className={classes.xsButtonAligner}>
                      <ChevronLeft />
                      Back to Assets
                    </span>
                  </Button>
                </Card>
              </Hidden>
            </>
          )}

          {showTagData && (
            <Card className={classes.toolbar}>
              <AnalyticsToolbar />
            </Card>
          )}
        </Grid>
      </Hidden>

      <Grid container className={classes.container}>
        <Grid container item xs={12} className={classes.row}>
          <Grid
            item
            xs={12}
            lg={6}
            component={Card}
            className={`${classes.column} ${classes.metadataColumn}`}
          >
            <AssetMetaData assetId={assetId} regionId={regionId} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            component={Card}
            className={`${classes.column} ${classes.mapColumn}`}
          >
            <AssetMap
              assets={assetData}
              colorMode="anomaly_score"
              fillMode="show_producing"
              selectedAssetId={assetId}
            />
            {!isEmpty(assetData) && <AssetMapLegend />}
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          xs={12}
          className={`${classes.row} ${classes.anomalyScoreContainer}`}
        >
          <Grid item component={Card}>
            <AnomalyScore
              assetId={assetId}
              regionId={regionId}
              onRelayout={onRelayout}
              highlightsArray={issueHighlightArray}
              onDateChange={onDateChange}
              startDate={startDate ? moment(startDate).toDate() : null}
              endDate={endDate ? moment(endDate).toDate() : null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} component={Card}>
          <CardHeader title="Tags" className={classes.cardHeader} />
          <CardContent className={classes.graphCard}>
            {tagDataLoading ? (
              <LoadingCard />
            ) : (
              <>
                {showTagData ? (
                  <ForceResponsive>
                    {(width, height) => (
                      <FullScreen handle={timeseriesConfig.fullScreenHandle}>
                        <div
                          className={
                            timeseriesConfig.fullScreenHandle.active
                              ? classes.tagGraphsOnFullscreen
                              : classes.tagGraphs
                          }
                        >
                          {timeseriesConfig.fullScreenHandle.active && (
                            <Card className={classes.fullScreenToolbar}>
                              <AnalyticsToolbar />
                            </Card>
                          )}

                          <TimeseriesAnalytics
                            timeseriesList={tagData[assetId][regionId].map(
                              (tag) => ({
                                name: timeseriesConfig.toggleName
                                  ? mapTagIdToName(tag.id)
                                  : tag.id,
                                index: tag.times.map((timestamp: string) =>
                                  moment(timestamp).valueOf()
                                ), // workaround. Need a standard datetime handling method in sh common
                                data: tag.values,
                                unitOfMeasure: mapTagIdToUnitOfMeasure(tag.id),
                              })
                            )}
                            showXAxes={timeseriesConfig.showXAxes}
                            showYAxes={timeseriesConfig.showYAxes}
                            columns={timeseriesConfig.columns}
                            singleChart={timeseriesConfig.singleChart}
                            highlights={issueHighlightArray || []}
                            onRelayout={onRelayout}
                            tickFormat={dateFormatPlotly}
                          />
                        </div>
                      </FullScreen>
                    )}
                  </ForceResponsive>
                ) : (
                  <Typography variant="body1" align="center">
                    No tag data available
                  </Typography>
                )}
              </>
            )}
          </CardContent>
        </Grid>
      </Grid>
      <Hidden smDown>
        <>
          {showTagData && (
            <Card className={classes.toolbar}>
              <AnalyticsToolbar />
            </Card>
          )}
        </>
      </Hidden>
    </div>
  );
};

export default AnalyticsView;
