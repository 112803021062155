import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Drawer,
  ListItemText,
  ListItemIcon,
  Divider,
  Hidden,
  Grid,
} from "@material-ui/core";
import { Home, Menu as MenuIcon, Close } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { ExitToApp as LogoutIcon } from "@material-ui/icons";
import { LogoutDialog } from "./../auth/LogoutDialog";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    padding: 0,
  },
  desktopMenuContainer: {
    display: "flex",
    flexDirection: "row",
  },
  menuIconWrapper: {
    padding: 0,
    fontSize: 32,
    color: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  closeIconWrapper: {
    fontSize: 32,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Menu = () => {
  const classes = useStyles();
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  // Determines whether to open or close the Mobile Menu Drawer
  const toggleDrawer = () => setMenuOpen(!menuOpen);

  // Determines whether to open or close the Logout Modal
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  return (
    <>
      {/* Mobile menu */}
      <Hidden mdUp>
        <>
          <IconButton
            className={classes.menuIconWrapper}
            onClick={toggleDrawer}
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>
          <Drawer
            open={menuOpen}
            anchor="top"
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
          >
            <>
              <IconButton
                onClick={toggleDrawer}
                className={classes.closeIconWrapper}
              >
                <Close />
              </IconButton>
              <MenuList className={classes.menuContainer}>
                <MenuItem
                  onClick={() => {
                    toggleDrawer();
                    history.push("/");
                  }}
                >
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    toggleDrawer();
                    history.push("/wells");
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faChartArea}
                      className="MuiSvgIcon-root"
                      style={{ width: "1em" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Analytics" />
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setLogoutModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </MenuList>
            </>
          </Drawer>
        </>
      </Hidden>
      {/* Desktop menu */}
      <Hidden smDown>
        <Grid container alignItems="center">
          <MenuList
            className={`${classes.menuContainer} ${classes.desktopMenuContainer}`}
          >
            <MenuItem onClick={() => history.push("/")}>
              <Tooltip title="Home">
                <IconButton className={classes.menuIconWrapper}>
                  <Home fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </MenuItem>
            <MenuItem onClick={() => history.push("/wells")}>
              <Tooltip title="Analytics">
                <IconButton className={classes.menuIconWrapper}>
                  <FontAwesomeIcon
                    icon={faChartArea}
                    className="MuiSvgIcon-root"
                    style={{ width: "1em" }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
            />
            <MenuItem onClick={() => setLogoutModalOpen(true)}>
              <Tooltip title="Logout">
                <IconButton className={classes.menuIconWrapper}>
                  <LogoutIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </MenuItem>
          </MenuList>
        </Grid>
      </Hidden>
      <LogoutDialog
        open={logoutModalOpen}
        closeHandler={() => setLogoutModalOpen(false)}
      />
    </>
  );
};

export default Menu;
