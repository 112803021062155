import { createStyles } from "@material-ui/core";
export var styles = function (theme) {
    return createStyles({
        tableHeadRow: {
            height: theme.spacing(4),
            fontSize: theme.typography.fontSize,
        },
        tableHeadCell: {
            paddingRight: theme.spacing(3),
            fontSize: theme.typography.fontSize,
        },
        tableRow: {
            height: theme.spacing(3),
            cursor: "pointer",
            fontSize: theme.typography.fontSize,
            borderBottom: "1px solid #eee",
        },
        tableCell: {
            border: "none",
            whiteSpace: "nowrap",
            paddingRight: theme.spacing(3),
            fontSize: theme.typography.fontSize,
        },
    });
};
