import React from "react";

import {
  Card,
  FormGroup,
  InputBase,
  InputAdornment,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1, 2),
    },
    inputAdornment: {
      marginRight: theme.spacing(3),
    },
    input: {
      height: 40,
    },
  })
);

interface SearchProps {
  value: string;
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search = (props: SearchProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <FormGroup>
        <InputBase
          placeholder="Search"
          onChange={props.onChangeValue}
          value={props.value}
          inputProps={{ "aria-label": "search" }}
          fullWidth
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <SearchIcon />
            </InputAdornment>
          }
          className={classes.input}
        />
      </FormGroup>
    </Card>
  );
};

export default Search;
