import * as React from "react";
import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        gridContainer: {
            flexGrow: 1,
            minHeight: 0,
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    });
};
var ColLayout = function (props) {
    var classes = props.classes, children = props.children;
    return (React.createElement(Grid, { container: true, className: classes.gridContainer, direction: "column", spacing: 0 }, children));
};
export var ScrollingLayoutContainer = withStyles(styles)(ColLayout);
