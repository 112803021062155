import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getTagData } from "../../api/tagData";
import { tagNames } from "../../constants/tagNames";

export interface TagData {
  id: string;
  times: string[]; // Timestamp
  values: number[];
}
interface TagDataState {
  tagData: {
    [assetId: string]: { [regionId: string]: TagData[] };
  };
  isLoading: boolean;
  error: string | null;
}

interface TagDataSuccessPayload {
  assetId: string;
  regionId: string;
  tagData: TagData[];
}

const initialState: TagDataState = {
  tagData: {},
  isLoading: true,
  error: null,
};

function startLoading(state: TagDataState) {
  state.isLoading = true;
}

function loadingFailed(state: TagDataState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const tagDataSlice = createSlice({
  name: "tagData",
  initialState,
  reducers: {
    getTagDataStart: startLoading,
    getTagDataSuccess(state, action: PayloadAction<TagDataSuccessPayload>) {
      const { assetId, regionId, tagData } = action.payload;

      // Don't want to overwrite tagData for asset, only asset/region combo
      state.tagData[assetId] = state.tagData[assetId] || {};

      // Assign tag data to asset/region combo
      state.tagData[assetId][regionId] = tagData;
      state.isLoading = false;
      state.error = null;
    },
    getTagDataFailure: loadingFailed,
  },
});

export const {
  getTagDataStart,
  getTagDataSuccess,
  getTagDataFailure,
} = tagDataSlice.actions;

export const fetchTagData = (
  assetId: string,
  regionId: string,
  startTime?: string,
  endTime?: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(getTagDataStart());
    const tagPromises = tagNames.map((tagId) =>
      getTagData(assetId, regionId, tagId, startTime, endTime)
    );
    const tagData = await Promise.all(tagPromises);

    // Check if we have valid data points for >=1 tags
    const validTagData = tagData.filter((tag) => tag.times.length !== 0);
    if (validTagData.length === 0) {
      dispatch(getTagDataFailure("No data points for tags"));
      return;
    }

    const payload: TagDataSuccessPayload = {
      assetId,
      regionId,
      tagData: validTagData,
    };
    dispatch(getTagDataSuccess(payload));
  } catch (err) {
    dispatch(getTagDataFailure(err.toString()));
  }
};

export default tagDataSlice.reducer;
