var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
var useStyles = makeStyles(function (theme) { return ({
    title: {
        textTransform: "capitalize",
    },
    titleColor: {
        color: theme.palette.primary.light,
    },
    loginBox: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingLeft: 0,
    },
    loginLink: {
        textDecoration: "none",
    },
    loginButton: {
        display: "block",
        padding: theme.spacing(1.5, 4),
        color: theme.palette.text.primary,
        textDecoration: "none",
        borderRadius: 0,
        alignSelf: "flex-start",
        marginTop: theme.spacing(5),
        fontSize: theme.typography.fontSize,
    },
    loginField: {
        marginTop: theme.spacing(3),
    },
}); });
// TODO: Turn this component into a controlled component so that when a user clicks the
// form submission, the form data (on component state), is passed as an argument. This will allow
// the form data to be passed to any store architecture as data to be used with authentication.
export var DefaultLoginForm = function (props) {
    var classes = useStyles();
    var _a = useState({
        username: "",
        password: "",
    }), formData = _a[0], setFormData = _a[1];
    var triggerCallback = function () {
        props.submissionCallback(formData);
    };
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: "h3", className: classes.title }, props.title),
        React.createElement("div", { className: "" + classes.loginBox },
            React.createElement(TextField, { className: classes.loginField, id: "username", margin: "none", label: "Username" // remember to change unit test's corresponding test
                , 
                // if you change this - tests/DefaultLoginForm.tsx
                fullWidth: true, value: formData.username, onChange: function (e) {
                    return setFormData(__assign(__assign({}, formData), { username: e.target.value }));
                } }),
            React.createElement(TextField, { className: "" + classes.loginField, id: "password", type: "password", label: "Password" // remember to change unit test's corresponding test
                , 
                // if you change this - tests/DefaultLoginForm.tsx
                margin: "none", fullWidth: true, value: formData.password, onChange: function (e) {
                    return setFormData(__assign(__assign({}, formData), { password: e.target.value }));
                } }),
            React.createElement(Button, { variant: "outlined", color: "default", className: classes.loginButton, onClick: triggerCallback }, "Login"))));
};
