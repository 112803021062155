import * as BABYLON from "babylonjs";
export var SceneElementVisibility;
(function (SceneElementVisibility) {
    SceneElementVisibility[SceneElementVisibility["Solid"] = 0] = "Solid";
    SceneElementVisibility[SceneElementVisibility["Transparent"] = 1] = "Transparent";
    SceneElementVisibility[SceneElementVisibility["Hidden"] = 2] = "Hidden";
})(SceneElementVisibility || (SceneElementVisibility = {}));
// Placeholder to mean 'calculate this from bounding boxes'
var NULL_VECTOR = null;
function makeMap(list, nameFn) {
    var ret = {};
    list.forEach(function (item) {
        ret[nameFn(item)] = item;
    });
    return ret;
}
var makeLeaf = function (mesh, name, explodeVector) {
    var bbox = mesh.getBoundingInfo().boundingBox;
    return {
        id: 0,
        name: name || mesh.name,
        originalPosition: mesh.position.clone(),
        bbMin: bbox.minimum.clone(),
        bbMax: bbox.maximum.clone(),
        explodeVector: explodeVector || NULL_VECTOR,
        isLeaf: true,
        mesh: mesh,
    };
};
function makeGroup(name, children, explodeVector) {
    var xMin = Math.min.apply(Math, children.map(function (b) { return b.bbMin.x; }));
    var xMax = Math.max.apply(Math, children.map(function (b) { return b.bbMax.x; }));
    var yMin = Math.min.apply(Math, children.map(function (b) { return b.bbMin.y; }));
    var yMax = Math.max.apply(Math, children.map(function (b) { return b.bbMax.y; }));
    var zMin = Math.min.apply(Math, children.map(function (b) { return b.bbMin.z; }));
    var zMax = Math.max.apply(Math, children.map(function (b) { return b.bbMax.z; }));
    var bbMin = new BABYLON.Vector3(xMin, yMin, zMin);
    var bbMax = new BABYLON.Vector3(xMax, yMax, zMax);
    // calculate child explode vectors if not set
    children.forEach(function (el) {
        if (el.explodeVector === NULL_VECTOR) {
            el.explodeVector = el.bbMin
                .add(el.bbMax)
                .subtractInPlace(bbMin)
                .subtractInPlace(bbMax)
                .scale(0.5);
        }
    });
    return {
        id: 0,
        name: name,
        isLeaf: false,
        items: children,
        bbMin: bbMin,
        bbMax: bbMax,
        explodeVector: explodeVector || NULL_VECTOR,
    };
}
function setIds(model) {
    var lookup = [];
    var setElementId = function (el) {
        el.id = lookup.length;
        lookup.push(el);
        if (!el.isLeaf) {
            el.items.forEach(setElementId);
        }
    };
    setElementId(model);
    return lookup;
}
function makeModel(meshes, info) {
    if (!info) {
        info = meshes.map(function (mesh) { return mesh.id; });
    }
    // filter out groups with no children
    info = info.filter(function (x) { return typeof x === "string" || x.items.length > 0; });
    var meshMap = makeMap(meshes, function (m) { return m.id; });
    var mapDescription = function (desc) {
        if (typeof desc === "string") {
            return makeLeaf(meshMap[desc]);
        }
        var exV = desc.explodeVector;
        var explodeVector = exV
            ? new BABYLON.Vector3(exV[0], exV[1], exV[2])
            : undefined;
        if (desc.items.length === 1 && typeof desc.items[0] === "string") {
            return makeLeaf(meshMap[desc.items[0]], desc.name, explodeVector);
        }
        return makeGroup(desc.name, desc.items.map(mapDescription), explodeVector);
    };
    return makeGroup("model", info.map(mapDescription), BABYLON.Vector3.Zero());
}
var SceneModelController = /** @class */ (function () {
    function SceneModelController(canvas, engine, scene) {
        this.canvas = canvas;
        this.engine = engine;
        this.scene = scene;
        this.meshes = [];
        this.meshExtent = BABYLON.Vector3.Zero();
        this.meshCenter = BABYLON.Vector3.Zero();
        this.modelLookup = [];
        this.changed = false;
        this.previousCameraPosition = new BABYLON.Vector3();
        this.lastTick = Date.now() - 1000;
        this.rendering = false;
        this.lastHover = -1;
        this.camera = this.createCamera();
        this.pipeline = new BABYLON.DefaultRenderingPipeline("default", true, scene, [this.camera]);
        this.pipeline.fxaaEnabled = true;
        this.pipeline.samples = 4;
        this.pipeline.sharpenEnabled = true;
    }
    SceneModelController.prototype.setMeshes = function (meshes, description) {
        this.centerMeshes(meshes);
        this.changed = true;
        this.meshes = meshes;
        this.model = makeModel(meshes, description);
        this.modelLookup = setIds(this.model);
        this.camera.setTarget(this.meshCenter);
        this.camera.radius =
            Math.max(this.meshExtent.x, this.meshExtent.y, this.meshExtent.z) * 8;
        this.previousCameraPosition = this.camera.position.clone();
        return this.modelLookup.map(function (_) { return 0; });
    };
    SceneModelController.prototype.resize = function () {
        this.engine.resize();
    };
    SceneModelController.prototype.sortedMeshes = function () {
        return this.meshes
            .map(function (m, i) { return ({ mesh: m, index: i }); })
            .sort(function (m1, m2) {
            return m1.mesh.name
                .toLocaleLowerCase()
                .localeCompare(m2.mesh.name.toLocaleLowerCase());
        });
    };
    SceneModelController.prototype.findElementIdFromMesh = function (mesh) {
        if (!mesh) {
            return -1;
        }
        return this.modelLookup.findIndex(function (model) { return model.isLeaf && model.mesh === mesh; });
    };
    SceneModelController.prototype.itemName = function (index) {
        if (index < 0 || index >= this.modelLookup.length) {
            return "";
        }
        return this.modelLookup[index].name;
    };
    SceneModelController.prototype.explode = function (factor, expandedList) {
        this.changed = true;
        var explodeElement = function (element, vec, expanded, expandChildren) {
            var newVec = expanded
                ? element.explodeVector.scale(factor).addInPlace(vec)
                : vec;
            if (element.isLeaf) {
                element.mesh.position = element.originalPosition.add(newVec);
            }
            else {
                element.items.forEach(function (item) {
                    return explodeElement(item, newVec, expandChildren, expandChildren && expandedList[item.id]);
                });
            }
        };
        if (this.model) {
            explodeElement(this.model, BABYLON.Vector3.Zero(), true, true);
        }
    };
    SceneModelController.prototype.zoom = function (factor) {
        this.changed = true;
        if (this.camera) {
            var fov = 2.1 / Math.pow(2, factor);
            this.camera.fov = fov;
        }
    };
    SceneModelController.prototype.setVisibility = function (meshVisibility, highlightId, transparentHighlight) {
        this.changed = true;
        var setElement = function (element, vis, force) {
            if (element.isLeaf) {
                var mesh = element.mesh;
                mesh.showBoundingBox =
                    element.id === highlightId && !transparentHighlight;
                if (vis === 2) {
                    mesh.isVisible = false;
                }
                else {
                    mesh.isVisible = true;
                    mesh.visibility = vis === 0 ? 1 : 0.08;
                }
            }
            else {
                if (force || vis !== 0) {
                    element.items.forEach(function (item) { return setElement(item, vis); });
                }
                else {
                    element.items.forEach(function (item) {
                        return setElement(item, meshVisibility[item.id]);
                    });
                }
            }
        };
        if (this.model) {
            if (transparentHighlight && highlightId >= 0) {
                setElement(this.model, 1);
                setElement(this.modelLookup[highlightId], 0, true);
            }
            else {
                setElement(this.model, 0);
            }
        }
    };
    SceneModelController.prototype.createCamera = function () {
        var camera = new BABYLON.ArcRotateCamera("Camera", 0, 0.8, 2, BABYLON.Vector3.Zero(), this.scene);
        // reduce the sensitivity of the wheel control
        camera.wheelPrecision = 1000;
        camera.setTarget(BABYLON.Vector3.Zero());
        camera.attachControl(this.canvas, true);
        return camera;
    };
    SceneModelController.prototype.centerMeshes = function (meshes) {
        var bounds = meshes.map(function (mesh) { return mesh.getBoundingInfo().boundingBox; });
        var xMin = Math.min.apply(Math, bounds.map(function (b) { return b.minimum.x; }));
        var xMax = Math.max.apply(Math, bounds.map(function (b) { return b.maximum.x; }));
        var yMin = Math.min.apply(Math, bounds.map(function (b) { return b.minimum.y; }));
        var yMax = Math.max.apply(Math, bounds.map(function (b) { return b.maximum.y; }));
        var zMin = Math.min.apply(Math, bounds.map(function (b) { return b.minimum.z; }));
        var zMax = Math.max.apply(Math, bounds.map(function (b) { return b.maximum.z; }));
        this.meshCenter = new BABYLON.Vector3((xMin + xMax) / 2, (yMin + yMax) / 2, (zMin + zMax) / 2);
        this.meshExtent = new BABYLON.Vector3((xMax - xMin) / 2, (yMax - yMin) / 2, (zMax - zMin) / 2);
    };
    SceneModelController.prototype.startRendering = function () {
        var _this = this;
        if (!this.rendering) {
            this.rendering = true;
            this.engine.runRenderLoop(function () {
                // TODO: Find a way of detecting camera movement animations.
                // Since the movement animation doesn't move the camera on the first frame
                // no camera movement is detected, scene.render isn't called & the camera
                // animation never progresses - deadlock.
                // We get round this by rendering after 200ms even if no change is detected.
                var moved = !_this.previousCameraPosition.equals(_this.camera.position);
                var now = Date.now();
                if (_this.changed || moved || now - _this.lastTick > 200) {
                    _this.changed = false;
                    _this.lastTick = now;
                    _this.previousCameraPosition = _this.camera.position.clone();
                    _this.scene.render();
                }
            });
        }
    };
    SceneModelController.prototype.stopRendering = function () {
        if (this.rendering) {
            this.engine.stopRenderLoop();
            this.rendering = false;
        }
    };
    return SceneModelController;
}());
export { SceneModelController };
export default SceneModelController;
