var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import classNames from "classnames";
import { createStyles, withStyles } from "@material-ui/core";
import { ListItem, Link as LinkText } from "@material-ui/core";
import { ExpandLess, ExpandMore, DragIndicator } from "@material-ui/icons";
// -------------------------- //
// -- Styles and Interface -- //
// -------------------------- //
var styles = function (theme) {
    return createStyles({
        listItem: {
            justifyContent: "space-between",
            color: theme.palette.text.primary,
            padding: 0,
        },
        draggable: {
            cursor: "move",
        },
        nodeContainer: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(1, 2),
        },
        link: {
            cursor: "pointer",
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    });
};
// --------------------------------- //
// -- Drag and Drop Specification -- //
// --------------------------------- //
var canDragNode = function (props) {
    if (typeof props.canDragNode === "boolean") {
        return props.canDragNode;
    }
    return props.canDragNode(props.node);
};
export var DragItemSpec = {
    beginDrag: function (props, monitor) {
        return {
            node: props.node,
        };
    },
    canDrag: function (props, monitor) {
        return canDragNode(props);
    },
};
export var DragItemCollect = function (connect, monitor) { return ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    canDrag: monitor.canDrag(),
}); };
// --------------- //
// -- Component -- //
// --------------- //
var ListItemBase = /** @class */ (function (_super) {
    __extends(ListItemBase, _super);
    function ListItemBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onItemClick = function (event) {
            _this.props.onItemClick(_this.props.node);
        };
        _this.onTextClick = function (event) {
            _this.props.onTextClick(_this.props.node);
            event.stopPropagation();
        };
        _this.onItemDoubleClick = function (event) {
            var onItemDoubleClick = _this.props.onItemDoubleClick;
            if (onItemDoubleClick) {
                onItemDoubleClick(_this.props.node);
            }
        };
        return _this;
    }
    ListItemBase.prototype.render = function () {
        var _a;
        var _b = this.props, expanded = _b.expanded, expandable = _b.expandable, classes = _b.classes, children = _b.children, className = _b.className;
        var _c = this.props, connectDragPreview = _c.connectDragPreview, connectDragSource = _c.connectDragSource;
        var isDraggable = canDragNode(this.props);
        var listItemClasses = classNames((_a = {},
            _a[classes.listItem] = true,
            _a[classes.draggable] = isDraggable,
            _a));
        return connectDragSource(React.createElement("div", { className: className },
            React.createElement(ListItem, { className: listItemClasses, button: true, disableRipple: true, onClick: this.onItemClick, onDoubleClick: this.onItemDoubleClick },
                connectDragPreview(React.createElement("div", { className: classes.nodeContainer },
                    isDraggable && (React.createElement(DragIndicator, { className: classes.icon, fontSize: "small" })),
                    React.createElement(LinkText, { className: classes.link, variant: "body2", color: "inherit", onClick: this.onTextClick }, children))),
                expandable &&
                    (expanded ? (React.createElement(ExpandLess, { className: classes.icon, fontSize: "small" })) : (React.createElement(ExpandMore, { className: classes.icon, fontSize: "small" }))))));
    };
    return ListItemBase;
}(React.Component));
export var DragListItem = withStyles(styles)(ListItemBase);
// NB: Do not be tempted to put the call to DragSource() in here, it has to be in the main app.
// If DragSource is called here the resulting component will not be able to find the drag provider
// in the component tree.
