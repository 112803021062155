import axios from "axios";
import {
  AssetData,
  AssetDictionary,
} from "../features/assetList/assetListSlice";

interface AssetDataResponse {
  meta_data: {
    Station: string;
    Downtime: string;
    "Last Shutdown Reason": string;
    "ESP Status": string;
    Name: string;
    Last_Shutdown_Reason_Value: string | null;
    location_latitude: string;
    location_longitude: string;
  };
  active_region_id: number;
  active_region_anomaly_score: number;
  freq_value: number;
}

export interface RegionResponse {
  end_date: string | null;
  region_id: string;
  start_date: string;
  has_issues: boolean;
  dq_score: number;
  anomaly_score: number;
}

export interface AssetResponse {
  [assetId: string]: {
    asset_id: string;
    regions: RegionResponse[];
  };
}

export interface AssetListItemResponse {
  [stationId: string]: AssetResponse;
}

export async function getAssetList(): Promise<AssetDictionary> {
  const url = `${process.env.REACT_APP_API_ROOT}/asset/list_all`;

  try {
    const response = await axios.get<AssetListItemResponse>(url, {
      params: {},
      timeout: 5000,
    });
    const data = response.data;
    const assetList: AssetDictionary = {};
    Object.keys(data).forEach((stationId) => {
      assetList[stationId] = {};
      Object.keys(data[stationId]).forEach((assetId) => {
        const { asset_id, regions } = data[stationId][assetId];
        assetList[stationId][assetId] = {
          assetId: asset_id,
          regions: regions.map((value) => ({
            regionId: value.region_id,
            startDate: value.start_date,
            endDate: value.end_date,
            hasIssues: value.has_issues,
            metaData: null,
            dataQualityScore: value.dq_score,
            anomalyScore: value.anomaly_score,
          })),
        };
      });
    });

    return assetList;
  } catch (err) {
    throw err;
  }
}

export async function getAssetData(): Promise<AssetData[]> {
  const url = `${process.env.REACT_APP_API_ROOT}/asset/select_assets`;

  try {
    const assetListResponse = await axios.get<AssetDataResponse[]>(url, {
      timeout: 5000,
    });

    // Format response
    return assetListResponse.data.map((asset) => ({
      assetId: asset.meta_data["Name"].replace("-", ""),
      station: asset.meta_data["Station"],
      location: {
        lat: parseFloat(asset.meta_data.location_latitude),
        long: parseFloat(asset.meta_data.location_longitude),
      },
      activeRegion: {
        id: asset.active_region_id,
        anomalyScore: asset.active_region_anomaly_score,
      },
      running: Boolean(asset.freq_value), // Using FREQ.CV value as indicator for whether pump is running
      metaData: {
        Station: asset.meta_data["Station"],
        Downtime: asset.meta_data["Downtime"],
        "ESP Status": asset.meta_data["ESP Status"],
        "Last Shutdown Reason": asset.meta_data["Last Shutdown Reason"],
        "Last Shutdown Reason Value":
          asset.meta_data["Last_Shutdown_Reason_Value"] || "0",
      },
    }));
  } catch (err) {
    throw err;
  }
}
