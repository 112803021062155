import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAssetData } from "../../api/asset";
import { AppThunk } from "../../app/store";
import { AssetData } from "../assetList/assetListSlice";

export interface AssetDataDictionary {
  [assetId: string]: AssetData;
}

interface AssetDataState {
  assetData: AssetDataDictionary;
  isLoading: boolean;
  error: string | null;
}

const initialState: AssetDataState = {
  assetData: {},
  isLoading: true,
  error: null,
};

function startLoading(state: AssetDataState) {
  state.isLoading = true;
}

function loadingFailed(state: AssetDataState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const assetDataSlice = createSlice({
  name: "assetData",
  initialState,
  reducers: {
    getAssetDataStart: startLoading,
    getAssetDataSuccess(state, action: PayloadAction<AssetData[]>) {
      state.assetData = action.payload.reduce(
        (nextAssetData: { [assetId: string]: AssetData }, asset) => {
          nextAssetData[asset.assetId] = asset;
          return nextAssetData;
        },
        {}
      );
      state.isLoading = false;
      state.error = null;
    },
    getAssetDataFailure: loadingFailed,
  },
});

export const {
  getAssetDataStart,
  getAssetDataSuccess,
  getAssetDataFailure,
} = assetDataSlice.actions;

export const fetchAssetData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getAssetDataStart());
    const assetData = await getAssetData();
    dispatch(getAssetDataSuccess(assetData));
  } catch (err) {
    dispatch(getAssetDataFailure(err.toString()));
  }
};

export default assetDataSlice.reducer;
