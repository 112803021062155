import * as React from "react";
import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        column: {
            flexGrow: 1,
            overflow: "auto",
            minHeight: "100%",
        },
    });
};
var LayoutColumn = function (props) {
    var classes = props.classes, children = props.children, xs = props.xs, sm = props.sm, md = props.md, lg = props.lg, xl = props.xl;
    return (React.createElement(Grid, { item: true, container: true, className: classes.column, alignContent: "flex-start", xs: xs, sm: sm, md: md, lg: lg, xl: xl }, children));
};
export var ScrollingLayoutColumn = withStyles(styles)(LayoutColumn);
