import React, { useEffect, useState } from "react";

import GoogleMap, { MapOptions, Maps } from "google-map-react";

import { Typography, makeStyles } from "@material-ui/core";
import { Marker } from "@toumetis/cascadence-react-ui";

import isEmpty from "lodash.isempty";

// Constants
import { mapMarker, mapMarkerSelected } from "../../constants/colours";
import { AssetDataDictionary } from "./assetDataSlice";

import {
  anomalyScoreOK,
  anomalyScoreWatch,
  anomalyScoreAnomalous,
  anomalyScoreDisregard,
} from "../../constants/colours";
import { AssetData } from "features/assetList/assetListSlice";

interface AssetMapProps {
  assets: AssetDataDictionary;
  selectedAssetId?: string;
  colorMode: "anomaly_score" | "none";
  fillMode: "show_producing" | "none";
  forceMarkerColorAs?: string; // sometimes we might want to enforce a different color because the current colour doesnt stand out from the map background
  onMarkerClick?: (asset: AssetData) => void;
}

const useStyles = makeStyles((theme) => {
  return {
    mapContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      minHeight: 250, // Ensures map shows on mobile
    },
    mapError: {
      color: theme.palette.categoryColors.bad[0],
    },
  };
});

const createMapOptions = (maps: Maps): MapOptions => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    scaleControl: false,
    mapTypeControl: false,
    zoomControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
  };
};

// check asset data is

const AssetMap = (props: AssetMapProps) => {
  const classes = useStyles();
  const {
    selectedAssetId,
    assets,
    colorMode,
    fillMode,
    forceMarkerColorAs,
    onMarkerClick,
  } = props;
  const [startLocation, setStartLocation] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    // This works for a first pass because the default starting location will
    // be set when the assets becomes non-empty as it will initially be when the
    // component loads. After that, changing the default starting location
    // doesn't do anything to the viewport.
    if (!isEmpty(assets)) {
      if (
        selectedAssetId &&
        assets[selectedAssetId] &&
        assets[selectedAssetId].location &&
        assets[selectedAssetId].location.lat &&
        assets[selectedAssetId].location.long
      ) {
        setStartLocation({
          lat: assets[selectedAssetId].location.lat!,
          lng: assets[selectedAssetId].location.long!,
        });
      } else {
        // If there is no selected asset we make a location that is in the centre of
        // all locations.
        let count = 0;
        let location = { lat: 0, lng: 0 };
        Object.keys(assets).forEach((assetId) => {
          if (
            assets[assetId] &&
            assets[assetId].location &&
            assets[assetId].location.lat &&
            assets[assetId].location.long &&
            !isNaN(assets[assetId].location.lat!) &&
            !isNaN(assets[assetId].location.long!) &&
            assets[assetId].location.lat! <= 85 &&
            assets[assetId].location.lat! >= -85 &&
            assets[assetId].location.long! <= 180 &&
            assets[assetId].location.long! >= -180
          ) {
            location.lat += assets[assetId].location.lat!;
            location.lng += assets[assetId].location.long!;
            count++;
          }
        });
        if (count !== 0) {
          location.lat /= count;
          location.lng /= count;
          setStartLocation(location);
        } else {
          setStartLocation({ lat: 30.71021, lng: 47.3175453 });
        }
      }
    } else {
      setStartLocation({ lat: 30.71021, lng: 47.3175453 });
    }
  }, [assets, selectedAssetId]);

  if (isEmpty(assets)) {
    return (
      <div className={classes.mapContainer}>
        <Typography variant="body1" align="center" className={classes.mapError}>
          No location data found
        </Typography>
      </div>
    );
  } else {
    const generateMapMarker = (assetId: string) => {
      const asset = assets!![assetId];
      if (!asset) return null;

      const { location, activeRegion, running } = asset;
      const { lat, long: lng } = location;
      const { anomalyScore } = activeRegion;
      const selected = assetId === selectedAssetId;

      if (
        !lat ||
        !lng ||
        isNaN(lat) ||
        isNaN(lng) ||
        lat! < -85 ||
        lat! > 85 ||
        lng! < -180 ||
        lng! > 180
      )
        return null;

      const setMarkerColor = () => {
        if (colorMode === "anomaly_score") {
          if (selected) {
            return mapMarkerSelected;
          } else {
            if (anomalyScore !== null && anomalyScore !== undefined) {
              if (anomalyScore < 500) return anomalyScoreOK;
              else if (anomalyScore < 1000) return anomalyScoreWatch;
              else if (anomalyScore < 1500) return anomalyScoreAnomalous;
              else return anomalyScoreDisregard;
            } else {
              return mapMarker;
            }
          }
        } else if (colorMode === "none") {
          return selected ? mapMarkerSelected : mapMarker;
        } else {
          return mapMarker;
        }
      };
      return (
        <Marker
          key={assetId}
          description={`Well ${assetId}`}
          filled={fillMode === "show_producing" ? running : true}
          color={forceMarkerColorAs ? forceMarkerColorAs : setMarkerColor()}
          lat={lat}
          lng={lng}
          onClick={() => {
            if (onMarkerClick) onMarkerClick(asset);
          }}
        />
      );
    };

    return (
      <div className={classes.mapContainer}>
        <GoogleMap
          bootstrapURLKeys={{
            key: "AIzaSyCAYgFyBUdiEBi_js_9wGlsKY-HCrkBLq0",
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={8}
          options={createMapOptions}
          center={{
            lat: startLocation.lat,
            lng: startLocation.lng,
          }}
        >
          {Object.keys(assets).map((assetId) => generateMapMarker(assetId))}
        </GoogleMap>
      </div>
    );
  }
};

export default AssetMap;
