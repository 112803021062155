import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  Typography,
  Theme,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";

import {
  LoginView,
  DefaultLoginForm,
  LoginFormData,
  ToumetisIcon,
} from "@toumetis/cascadence-react-ui";

import { RootState } from "../../app/rootReducer";
import { fetchAuthToken } from "./authSlice";

const useStyles = makeStyles((theme: Theme) => ({
  login: {
    width: "100%",
    height: "100vh",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  errorMessage: {
    backgroundColor: theme.palette.categoryColors.bad[0], // Red
  },
}));

const Login = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, isLoggedIn } = useSelector((state: RootState) => state.auth);
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onLoginSubmit = (details: LoginFormData) => {
    dispatch(fetchAuthToken(details));
  };

  useEffect(() => {
    // error is only not null if login fails, show dialog if so
    setSnackbarOpen(error !== null);
  }, [error]);

  useEffect(() => {
    // Redirect user to home screen if login successful
    if (isLoggedIn) history.push("/");
  }, [history, isLoggedIn]);

  return (
    <>
      <LoginView>
        <DefaultLoginForm
          title={
            <div className={classes.title}>
              <ToumetisIcon className={classes.icon} size={50} />
              <Typography variant={"h3"}>Login to Cascadence</Typography>
            </div>
          }
          submissionCallback={onLoginSubmit}
        />
      </LoginView>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          message={"Authorisation failed"}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          className={classes.errorMessage}
        />
      </Snackbar>
    </>
  );
};

export default Login;
