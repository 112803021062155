import * as React from "react";
import { withStyles } from "@material-ui/core";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { styles } from "./SortTable.styles";
/** Map function returning a cell */
var makeTableCell = function (row, col, onRowClick, cellClass) {
    var key = row.id + "_" + col.id;
    var onClick = function (e) {
        e.stopPropagation(); // prevent the outer handler from being invoked.
        onRowClick(row, col);
    };
    return (React.createElement(TableCell, { className: cellClass, key: key, onClick: onClick }, col.format ? col.format(row.values[col.id]) : row.values[col.id]));
};
/** Map function return a single row */
var makeTableRow = function (props, row) {
    var classes = props.classes, columns = props.columns, onRowClick = props.onRowClick;
    var rowClick = function () { return onRowClick(row, null); };
    return (React.createElement(TableRow, { className: classes.tableRow, key: row.id, hover: true, onClick: rowClick }, columns.map(function (col) {
        return makeTableCell(row, col, onRowClick, classes.tableCell);
    })));
};
var MetricTableBody = function (props) { return (React.createElement(TableBody, null, props.rows.map(function (row) { return makeTableRow(props, row); }))); };
export default withStyles(styles)(MetricTableBody);
