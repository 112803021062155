import * as React from "react";
import classNames from "classnames";
import { createStyles, withStyles } from "@material-ui/core";
var transition = "0.1s ease-in-out";
var styles = function (theme) {
    return createStyles({
        "@keyframes tmtsSpinnerRotateRight": {
            "0%": { transform: "rotate(0deg)" },
            "25%": { transform: "rotate(90deg)" },
            "50%": { transform: "rotate(180deg)" },
            "75%": { transform: "rotate(270deg)" },
            "100%": { transform: "rotate(360deg)" },
        },
        "@keyframes tmtsSpinnerRotateLeft": {
            "0%": { transform: "rotate(0deg)" },
            "25%": { transform: "rotate(-90deg)" },
            "50%": { transform: "rotate(-180deg)" },
            "75%": { transform: "rotate(-270deg)" },
            "100%": { transform: "rotate(-360deg)" },
        },
        outerContainer: {
            width: "100%",
            height: "100%",
            position: "relative",
        },
        outerContainerButton: {
            transition: transition,
            "&:hover": {
                transform: "scale(1.2)",
            },
            "&:active": {
                transform: "scale(1.3)",
            },
            "&:hover $squareContainer": {
                padding: "3.6%",
            },
        },
        outerPointer: {
            opacity: 0.85,
            display: "inline-block",
            textAlign: "center",
        },
        animate: {},
        container: {
            width: "100%",
            height: "100%",
            position: "relative",
            "$animate &": {
                animation: "$tmtsSpinnerRotateRight 4s infinite",
            },
        },
        squareContainer: {
            position: "absolute",
            width: "25%",
            height: "25%",
            boxSizing: "border-box",
        },
        square: {
            width: "100%",
            height: "100%",
            position: "relative",
            "$animate &": {
                animation: "$tmtsSpinnerRotateLeft 4s infinite",
            },
        },
        dk: { backgroundColor: theme.palette.primary.dark },
        md: { backgroundColor: theme.palette.primary.light },
        lt: { backgroundColor: theme.palette.common.white },
        ol: {
            borderColor: theme.palette.primary.light,
            borderWidth: "1px",
            borderStyle: "solid",
        },
        r0: { top: "0%" },
        r1: { top: "25%" },
        r2: { top: "50%" },
        r3: { top: "75%" },
        c0: { left: "0%" },
        c1: { left: "25%" },
        c2: { left: "50%" },
        c3: { left: "75%" },
    });
};
var ToumetisIconBase = function (props) {
    var _a;
    var classes = props.classes, spin = props.spin, pointer = props.pointer, size = props.size, button = props.button, onClick = props.onClick, className = props.className;
    var backClasses = [classes.dk, classes.md, classes.lt];
    var dk = 0, md = 1, lt = 2;
    var rowClasses = [classes.r0, classes.r1, classes.r2, classes.r3];
    var colClasses = [classes.c0, classes.c1, classes.c2, classes.c3];
    var outlineClasses = ["", classes.ol];
    var squares = [
        // inner medium squares
        [md, 1, 1, 0],
        [md, 1, 2, 0],
        [md, 2, 2, 0],
        // outer dark squares
        [dk, 0, 0, 0],
        [dk, 0, 1, 0],
        [dk, 0, 2, 0],
        [dk, 0, 3, 0],
        [dk, 1, 0, 0],
        [dk, 1, 3, 0],
        [dk, 2, 3, 0],
        [dk, 3, 2, 0],
        [dk, 3, 3, 0],
        // outer medium squares
        [md, 2, 0, 0],
        [md, 3, 1, 0],
        // inner light square
        [lt, 2, 1, 0],
        // outer light square
        [lt, 3, 0, pointer ? 1 : 0],
    ];
    var boxClasses = function (box) {
        return classNames(classes.squareContainer, rowClasses[box[1]], colClasses[box[2]], outlineClasses[box[3]]);
    };
    var outerClass = classNames((_a = {},
        _a[classes.outerContainer] = true,
        _a[classes.outerContainerButton] = !!button,
        _a[classes.animate] = spin,
        _a[classes.outerPointer] = pointer,
        _a));
    var style = size ? { width: size + "px", height: size + "px" } : {};
    return (React.createElement("div", { className: classNames(outerClass, className), style: style, onClick: function (ev) { return onClick && onClick(); } },
        React.createElement("div", { className: classes.container }, squares.map(function (square, index) { return (React.createElement("div", { key: index, className: boxClasses(square) },
            React.createElement("div", { className: classNames(classes.square, backClasses[square[0]]) }))); }))));
};
export var ToumetisIcon = withStyles(styles)(ToumetisIconBase);
