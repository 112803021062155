var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import FullScreen from "react-full-screen";
import { createStyles, withStyles } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { TimeseriesAnalytics } from "./TimeseriesAnalytics";
import { Toolbar, ColumnMenu, XAxisToggle, YAxisToggle, FullScreenToggle, SingleChartToggle, } from "./../../Toolbar";
var styles = function (theme) {
    return createStyles({
        root: {
            position: "relative",
            padding: theme.spacing(1),
        },
        emptyIcon: {
            color: "" + theme.palette.grey[300],
            paddingTop: "3px",
        },
    });
};
var TimeseriesAnalyticsWithToolbarUI = /** @class */ (function (_super) {
    __extends(TimeseriesAnalyticsWithToolbarUI, _super);
    function TimeseriesAnalyticsWithToolbarUI() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showXAxes: _this.props.showXAxes ? _this.props.showXAxes : true,
            showYAxes: _this.props.showYAxes ? _this.props.showYAxes : true,
            columns: 1,
            singleChart: false,
            fullScreen: false,
        };
        _this.toggleXAxis = function () {
            _this.setState(__assign(__assign({}, _this.state), { showXAxes: !_this.state.showXAxes }));
        };
        _this.toggleYAxis = function () {
            _this.setState(__assign(__assign({}, _this.state), { showYAxes: !_this.state.showYAxes }));
        };
        _this.toggleSingleChart = function () {
            _this.setState(__assign(__assign({}, _this.state), { singleChart: !_this.state.singleChart }));
        };
        _this.onChooseColumns = function (index, val) {
            _this.setState(__assign(__assign({}, _this.state), { columns: val }));
        };
        _this.toggleFullScreen = function () {
            _this.setState(__assign(__assign({}, _this.state), { fullScreen: !_this.state.fullScreen }));
        };
        return _this;
    }
    TimeseriesAnalyticsWithToolbarUI.prototype.render = function () {
        var _a = this.props, showIndicatorIfEmpty = _a.showIndicatorIfEmpty, classes = _a.classes, timeseriesList = _a.timeseriesList, onRelayout = _a.onRelayout;
        var _b = this.state, showXAxes = _b.showXAxes, showYAxes = _b.showYAxes, columns = _b.columns, singleChart = _b.singleChart, fullScreen = _b.fullScreen;
        return (React.createElement(FullScreen, { enabled: fullScreen },
            React.createElement(TimeseriesAnalytics, __assign({}, this.props, { timeseriesList: timeseriesList, showXAxes: showXAxes, showYAxes: showYAxes, onRelayout: onRelayout, columns: columns, singleChart: singleChart, showLegend: singleChart })),
            this.props.showToolbar && (React.createElement(Toolbar, null,
                timeseriesList.length > 1 && (React.createElement(XAxisToggle, { onClick: this.toggleXAxis })),
                timeseriesList.length > 1 && (React.createElement(YAxisToggle, { onClick: this.toggleYAxis })),
                timeseriesList.length > 0 && (React.createElement(FullScreenToggle, { onClick: this.toggleFullScreen, selected: fullScreen })),
                timeseriesList.length > 0 && (React.createElement(ColumnMenu, { onItemChosen: this.onChooseColumns, maxColumns: 3 })),
                timeseriesList.length > 1 && (React.createElement(SingleChartToggle, { onClick: this.toggleSingleChart, selected: singleChart })))),
            showIndicatorIfEmpty && timeseriesList.length === 0 && (React.createElement(AddCircleOutline, { className: classes.emptyIcon }))));
    };
    return TimeseriesAnalyticsWithToolbarUI;
}(React.Component));
export default withStyles(styles)(TimeseriesAnalyticsWithToolbarUI);
