var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import map from "lodash.map";
import sortby from "lodash.sortby";
import { Grid, Typography, makeStyles } from "@material-ui/core";
var useStyles = makeStyles(function (theme) {
    return {
        metadataRow: {
            marginTop: theme.spacing(1),
        },
        metadataColumn: {
            fontSize: "0.8rem",
        },
        metadataKey: {
            flexBasis: "60%",
            textAlign: "left",
            lineHeight: 1.2,
        },
        metadataValue: {
            flexBasis: "40%",
            textAlign: "right",
            fontWeight: "bold",
            lineHeight: 1.2,
        },
    };
});
/**
 * Sorts a list of metadata in ascending order.
 * Case insensitive.
 * @param {Metadata[]} metadata The {key, value} list to be sorted
 */
export var sortMetadata = function (metadata) {
    return sortby(metadata, function (data) { return data.key.toLowerCase(); });
};
/**
 * Formats the data to replace dashes, underscores with spaces, and capitalises the first
 * letter of each word.
 * @param key The string to be formatted
 */
export var defaultFormatting = function (key) {
    key = key.toLowerCase();
    key = key.replace(/[^a-zA-Z0-9]/g, " ");
    key = key.replace(/\w\S*/g, function (textToFormat) {
        return textToFormat.charAt(0).toUpperCase() +
            textToFormat.substr(1).toLowerCase();
    });
    return key;
};
/**
 * Formats a metadata list for rendering. You should sort the metadata before passing it to
 * this function.
 * @param metadata Metadata[] of {key, value} list
 * @param customTextFormat (key: string) => string
 */
export var formatForRendering = function (metadata, customTextFormat) {
    return map(metadata, function (data) {
        var key = data.key;
        if (customTextFormat !== undefined) {
            key = customTextFormat(key);
        }
        else {
            key = defaultFormatting(key);
        }
        return __assign(__assign({}, data), { key: key });
    });
};
/**
 * This component accepts a list of key value pairs and displays them in sorted ascending order
 * by key.
 *
 * Because it's not possible to predict ahead of time what a metadata structure may look like, any
 * metadata must be formatted to Metadata[] before being passed into this component
 *
 * Accepts props of type MetadataTableProps
 *
 * @param props MetadataTableProps
 */
var MetadataTable = function (props) {
    var metadata = props.metadata;
    var shouldFormatText = props.shouldFormatText, customTextFormat = props.customTextFormat, excludeMissingData = props.excludeMissingData;
    var classes = useStyles();
    metadata = sortMetadata(metadata);
    if (shouldFormatText === true || shouldFormatText === undefined) {
        metadata = formatForRendering(metadata, customTextFormat);
    }
    return (React.createElement(React.Fragment, null, metadata.map(function (data, index) {
        if (excludeMissingData) {
            if (!data.key || !data.value) {
                return;
            }
        }
        return (React.createElement(Grid, { container: true, direction: "row", justify: "space-between", alignItems: "flex-start", className: classes.metadataRow, key: index },
            React.createElement(Typography, { className: classes.metadataColumn + " " + classes.metadataKey }, data.key ? (data.key) : (React.createElement("span", { style: { color: "red", fontWeight: 300 } }, "#!MISSING"))),
            React.createElement(Typography, { className: classes.metadataColumn + " " + classes.metadataValue }, data.value ? (data.value) : (React.createElement("span", { style: { color: "red", fontWeight: 300 } }, "#!MISSING")))));
    })));
};
export default MetadataTable;
