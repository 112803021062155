var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import * as BABYLON from "babylonjs";
import { SceneModelController, } from "./BablyonSceneModel";
var BabylonSceneController = /** @class */ (function (_super) {
    __extends(BabylonSceneController, _super);
    function BabylonSceneController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentHover = -1;
        _this.state = {
            meshVisibility: [],
            exploded: [],
            highlightId: -1,
            transparentHighlight: false,
            explodeFactor: 0,
            zoomFactor: 1.4,
        };
        _this.onMeshClick = function (e) {
            var onMeshClick = _this.props.onElementClick;
            if (_this.controller && onMeshClick) {
                var id = _this.controller.findElementIdFromMesh(e.meshUnderPointer);
                if (id >= 0) {
                    onMeshClick(id, _this.makeAPI(), e);
                }
            }
        };
        _this.onMeshHover = function (e) {
            var onMeshHover = _this.props.onElementHover;
            if (_this.controller && onMeshHover) {
                var id = _this.controller.findElementIdFromMesh(e.meshUnderPointer);
                if (id >= 0 && id !== _this.currentHover) {
                    _this.currentHover = id;
                    onMeshHover(id, _this.makeAPI(), e);
                }
            }
        };
        _this.onMeshUnhover = function (e) {
            var onMeshHover = _this.props.onElementHover;
            if (_this.controller && onMeshHover) {
                var id = _this.controller.findElementIdFromMesh(e.meshUnderPointer);
                if (id >= 0 && id === _this.currentHover) {
                    _this.currentHover = -1;
                    onMeshHover(-1, _this.makeAPI(), e);
                }
            }
        };
        _this.onCanvasMounted = function (canvas) {
            var _a = _this.props, engineOptions = _a.engineOptions, adaptToDeviceRatio = _a.adaptToDeviceRatio, onSceneMount = _a.onSceneMount;
            var engine = new BABYLON.Engine(canvas, true, engineOptions, adaptToDeviceRatio);
            var scene = new BABYLON.Scene(engine);
            _this.controller = new SceneModelController(canvas, engine, scene);
            if (onSceneMount) {
                onSceneMount({ engine: engine, scene: scene, canvas: canvas, controller: _this.makeAPI() });
            }
        };
        _this.setExplodeFactor = function (newFactor) {
            _this.setState(__assign(__assign({}, _this.state), { explodeFactor: newFactor }));
        };
        _this.setZoom = function (zoom) {
            _this.setState(__assign(__assign({}, _this.state), { zoomFactor: zoom }));
        };
        _this.setElementVisibility = function (id, vis) {
            if (id in _this.state.meshVisibility) {
                var newVis = _this.state.meshVisibility.slice();
                newVis[id] = vis;
                _this.setState(__assign(__assign({}, _this.state), { meshVisibility: newVis }));
            }
        };
        _this.cycleElementVisibility = function (id, down) {
            var newVis = (_this.state.meshVisibility[id] + (down ? 2 : 1)) % 3;
            _this.setElementVisibility(id, newVis);
        };
        _this.highlightElement = function (id, restTransparent) {
            if (id in _this.state.meshVisibility) {
                _this.setState(__assign(__assign({}, _this.state), { highlightId: id, transparentHighlight: !!restTransparent }));
            }
            else {
                _this.setState(__assign(__assign({}, _this.state), { highlightId: -1, transparentHighlight: false }));
            }
        };
        _this.explode = function (id, exploded, allChildren) {
            if (!_this.controller) {
                return;
            }
            var expanded = _this.state.exploded.slice();
            var newState = exploded;
            var expandAll = function (index) {
                expanded[index] = newState;
                var item = _this.controller.modelLookup[index];
                if (!item.isLeaf) {
                    item.items.forEach(function (i) { return expandAll(i.id); });
                }
            };
            if (allChildren) {
                expandAll(id);
            }
            else {
                expanded[id] = newState;
            }
            _this.setState(__assign(__assign({}, _this.state), { exploded: expanded }));
        };
        _this.toggleExplode = function (id, allChildren) {
            _this.explode(id, !_this.state.exploded[id], allChildren);
        };
        _this.setupModel = function (meshes, description) {
            if (_this.controller) {
                var meshVisibility = _this.controller.setMeshes(meshes, description);
                var exploded = meshVisibility.map(function (_) { return false; });
                meshes.forEach(function (mesh, index) {
                    mesh.actionManager = new BABYLON.ActionManager(_this.controller.scene);
                    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, _this.onMeshClick));
                    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, _this.onMeshHover));
                    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, _this.onMeshUnhover));
                });
                _this.setState(__assign(__assign({}, _this.state), { meshVisibility: meshVisibility, exploded: exploded }));
            }
        };
        _this.startRendering = function () {
            if (_this.controller) {
                _this.controller.startRendering();
            }
        };
        _this.stopRendering = function () {
            if (_this.controller) {
                _this.controller.stopRendering();
            }
        };
        _this.itemName = function (id) {
            if (_this.controller) {
                return _this.controller.itemName(id);
            }
            return "";
        };
        _this.resize = function () {
            if (_this.controller) {
                _this.controller.resize();
            }
        };
        _this.rootElement = function () { return _this.controller && _this.controller.model; };
        _this.isExploded = function (id) { return _this.state.exploded[id]; };
        _this.explodeFactor = function () { return _this.state.explodeFactor; };
        _this.zoomFactor = function () { return _this.state.zoomFactor; };
        _this.elementVisibility = function (id) { return _this.state.meshVisibility[id]; };
        return _this;
    }
    BabylonSceneController.prototype.componentWillUnmount = function () {
        if (this.controller) {
            this.controller.stopRendering();
        }
    };
    BabylonSceneController.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.controller) {
            var _a = this.state, zoomFactor = _a.zoomFactor, explodeFactor = _a.explodeFactor, meshVisibility = _a.meshVisibility, exploded = _a.exploded, highlightId = _a.highlightId, transparentHighlight = _a.transparentHighlight;
            if (zoomFactor !== prevState.zoomFactor) {
                this.controller.zoom(zoomFactor);
            }
            if (explodeFactor !== prevState.explodeFactor ||
                exploded !== prevState.exploded) {
                this.controller.explode(explodeFactor, exploded);
            }
            if (meshVisibility !== prevState.meshVisibility ||
                highlightId !== prevState.highlightId ||
                transparentHighlight !== prevState.transparentHighlight) {
                this.controller.setVisibility(meshVisibility, highlightId, transparentHighlight);
            }
        }
    };
    BabylonSceneController.prototype.makeAPI = function () {
        var api = {
            rootElement: this.rootElement,
            itemName: this.itemName,
            setZoom: this.setZoom,
            getZoom: this.zoomFactor,
            setExplodeFactor: this.setExplodeFactor,
            getExplodeFactor: this.explodeFactor,
            explodeElement: this.explode,
            toggleExplodeElement: this.toggleExplode,
            isElementExploded: this.isExploded,
            getElementVisibility: this.elementVisibility,
            setElementVisibility: this.setElementVisibility,
            cycleElementVisibility: this.cycleElementVisibility,
            highlightElement: this.highlightElement,
            resize: this.resize,
            onCanvasMounted: this.onCanvasMounted,
            setupModel: this.setupModel,
            startRendering: this.startRendering,
            stopRendering: this.stopRendering,
        };
        return api;
    };
    BabylonSceneController.prototype.render = function () {
        return this.props.children(this.makeAPI());
    };
    return BabylonSceneController;
}(React.Component));
export { BabylonSceneController };
