var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { SizeMe } from "react-sizeme";
import Renderable from "./Renderable";
// Common types
export var Breakpoints;
(function (Breakpoints) {
    Breakpoints[Breakpoints["XS"] = 0] = "XS";
    Breakpoints[Breakpoints["SM"] = 600] = "SM";
    Breakpoints[Breakpoints["MD"] = 960] = "MD";
    Breakpoints[Breakpoints["LG"] = 1280] = "LG";
    Breakpoints[Breakpoints["XL"] = 1920] = "XL";
})(Breakpoints || (Breakpoints = {}));
var BreakpointComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(SizeMe, __assign({}, props.configuration), function (_a) {
            var size = _a.size;
            // type conversion is required thanks to sizeme's union type of number | null & tslint screaming
            var _size = size.width;
            return (React.createElement(React.Fragment, null,
                React.createElement(Renderable, { size: _size, components: __assign({}, props.components) })));
        })));
};
export default BreakpointComponent;
