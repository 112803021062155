import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getIssues, updateIssue } from "../../api/issues";

export interface Issue {
  id: number;
  issueTypeId: number;
  startTime: string;
  endTime: string;
  createdTime: string;
  assetId: string;
  regionId: string;
  reason: string;
  issueStatus: string;
  assignedTo: string;
  anomalyScore: number;
}

export interface IssuesByWell {
  [assetId: string]: { [issueId: string]: Issue };
}

interface IssuesState {
  issues: IssuesByWell;
  isLoading: boolean;
  error: string | null;
}

const initialState: IssuesState = {
  issues: {},
  isLoading: true,
  error: null,
};

const startLoading = (state: IssuesState) => {
  state.isLoading = true;
  state.error = null;
};

const loadingFailed = (state: IssuesState, action: PayloadAction<string>) => {
  state.isLoading = false;
  state.error = action.payload;
};

const getIssuesSuccess = (
  state: IssuesState,
  action: PayloadAction<Issue[]>
) => {
  action.payload.forEach((issue: Issue) => {
    const {
      id,
      issueTypeId,
      startTime,
      endTime,
      createdTime,
      assetId,
      regionId,
      reason,
      issueStatus,
      assignedTo,
      anomalyScore,
    } = issue;

    state.issues[assetId] = state.issues[assetId] || {};
    state.issues[assetId][id] = {
      id,
      issueTypeId,
      startTime,
      endTime,
      createdTime,
      assetId,
      regionId,
      reason,
      issueStatus,
      assignedTo: assignedTo || "", // Needs to be a string for sorting
      anomalyScore,
    };
  });
  state.isLoading = false;
  state.error = null;
};

const issuesSlice = createSlice({
  name: "issues",
  initialState,
  reducers: {
    issueRequestStart: startLoading,
    issueRequestSuccess: getIssuesSuccess,
    issueRequestFail: loadingFailed,
  },
});

export const {
  issueRequestStart,
  issueRequestSuccess,
  issueRequestFail,
} = issuesSlice.actions;

export const fetchIssueData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(issueRequestStart());
    const issueList = await getIssues();
    dispatch(issueRequestSuccess(issueList));
  } catch (err) {
    dispatch(issueRequestFail(err.toString()));
  }
};

export const updateIssueStatus = (
  id: number,
  status: string,
  assignedTo?: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(issueRequestStart());
    await updateIssue(id, status, assignedTo);
    const issueList = await getIssues();
    dispatch(issueRequestSuccess(issueList));
  } catch (err) {
    dispatch(issueRequestFail(err.toString()));
  }
};

export default issuesSlice.reducer;
