import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        root: {
            position: "absolute",
            top: 0,
            left: 0,
        },
    });
};
var Toolbar = function (props) {
    var classes = props.classes;
    return React.createElement("div", { className: classes.root }, props.children);
};
export default withStyles(styles)(Toolbar);
