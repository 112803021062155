import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  createRef,
  RefObject,
} from "react";
import moment from "moment";

// React Router
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { RouteParams } from "../../app/App";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { setNavigation } from "../../features/navigation/navigationSlice";
import { AssetDictionary } from "../../features/assetList/assetListSlice";
import { Region } from "../regions/regionsSlice";

// Material UI
import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  Typography,
  Hidden,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  Error,
} from "@material-ui/icons";

// Local components
import { LoadingCard } from "../../components/LoadingCard";

// Constants
import { dateFormat } from "../../globals";
import {
  selectedListItem,
  inactiveListItem,
  anomalyScoreOK,
  anomalyScoreWatch,
  anomalyScoreAnomalous,
  anomalyScoreDisregard,
  bodyFont,
} from "../../constants/colours";
import {
  checkboxHeight,
  listItemHeight,
  cardActionsHeight,
} from "../../constants/sizes";

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    paddingBottom: 0,
  },
  listActionWrapper: {
    height: cardActionsHeight,
    padding: theme.spacing(2),
  },
  list: {
    height: `calc(100% - ${checkboxHeight}px)`,
    overflowY: "auto",
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(3),
  },
  checkboxLabel: {
    height: checkboxHeight,
    margin: 0,
  },
  listItem: {
    height: listItemHeight,
  },
  listIcon: {
    minWidth: 48,
  },
  stationListItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  assetListItem: {
    paddingLeft: theme.spacing(6) * 1, // Asset is list level 1
    paddingRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  regionListItem: {
    paddingLeft: theme.spacing(6) * 2, // Region is list level 2
  },
  selected: {
    backgroundColor: selectedListItem,
  },
  selectPumpBtn: {
    borderRadius: 0,
  },
}));

const formatPumpDate = (pumpDate: string) => {
  return moment(pumpDate).format(dateFormat);
};

const sortPumpsOldestToNewest = (region1: Region, region2: Region) => {
  if (region1.startDate < region2.startDate) {
    return -1;
  }
  if (region1.startDate > region2.startDate) {
    return 1;
  }
  return 0;
};

/**
 * Returns station ID of provided asset
 * @param {AssetDictionary} assetList
 * @param {string} assetId
 * @returns {string} Station ID
 */
const findStationFromAsset = (assetList: AssetDictionary, assetId: string) => {
  let station;
  const stationIds = Object.keys(assetList);
  for (let i = 0; i < stationIds.length; i++) {
    const stationId = stationIds[i];
    const assetIds = Object.keys(assetList[stationId]);
    if (assetIds.includes(assetId)) {
      station = stationId;
      break;
    }
  }

  return station;
};

interface AssetListProps {
  mobile?: boolean;
  searchValue?: string;
  height?: string;
}

const AssetList = (props: AssetListProps) => {
  const { mobile, searchValue, height } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { assetId: selectedAsset, regionId: selectedRegion } = useParams<
    RouteParams
  >();

  const { assetList, isLoading } = useSelector(
    (state: RootState) => state.assetList
  );
  const { scrollTo, scrolling } = useSelector(
    (state: RootState) => state.navigation
  );

  // Tracks which list items are open
  type OpenListItemsState = {
    [index: string]: boolean;
  };

  const [openListItems, setOpenListItems] = useState<OpenListItemsState>({});
  const [filteredList, setFilteredList] = useState<AssetDictionary>({});
  const [checkBoxValue, setCheckBoxValue] = useState<Boolean>(false);

  // Useful values to memorise
  const filteredStationIds = useMemo(() => Object.keys(filteredList), [
    filteredList,
  ]);

  /**
   * Object consisting of refs for each asset and region in the list
   * This allows us to scroll individual list items into view
   */
  type ListRefs = {
    [index: string]: RefObject<HTMLDivElement>;
  };
  const listRefs = useMemo(() => {
    const refs: ListRefs = {};
    Object.keys(assetList).forEach((stationId) => {
      refs[stationId] = createRef();
      Object.keys(assetList[stationId]).forEach((assetId) => {
        refs[assetId] = createRef();
        assetList[stationId][assetId].regions.forEach((region) => {
          refs[region.regionId] = createRef();
        });
      });
    });
    return refs;
  }, [assetList]);

  /**
   * Listens for changes in the assetList, the search value and the checkbox to update filteredList
   */
  useEffect(() => {
    const newFilteredList: AssetDictionary = {};
    const lcSearchValue = searchValue && searchValue.toLowerCase();

    // Loop through stations
    Object.keys(assetList).forEach((stationId) => {
      let stationMatch = false;
      if (lcSearchValue && stationId.toLowerCase().includes(lcSearchValue)) {
        stationMatch = true;
      }

      // Loop through the assets
      Object.keys(assetList[stationId]).forEach((assetId) => {
        // Allow search value to match station or asset ID
        if (
          !lcSearchValue ||
          assetId.toLowerCase().includes(lcSearchValue) ||
          stationMatch
        ) {
          // For every matched asset get the regions
          const { regions } = assetList[stationId][assetId];

          // Filter the assets based on the checkbox value - if true then only show wells with issues
          let validAsset = false;
          if (checkBoxValue) {
            // We need to check whether the active pump (no end date) has issues
            for (let i = 0; i < regions.length; i++) {
              const region = regions[i];
              if (!region.endDate && region.hasIssues) {
                validAsset = true;
                continue;
              }
            }
          } else {
            validAsset = true;
          }

          if (validAsset) {
            newFilteredList[stationId] = newFilteredList[stationId] || {};
            newFilteredList[stationId][assetId] = {
              assetId,
              regions,
            };
          }
        }
      });
    });

    setFilteredList(newFilteredList); // Set the filteredList to be the filtered results
  }, [assetList, searchValue, checkBoxValue]);

  /**
   * Method for handling any change on the checkbox. Used for hiding and unhiding any wells with issues when toggled.
   * @param event
   */
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checkValue = event.target.checked;
    setCheckBoxValue(checkValue);
  };

  /**
   * Toggles open state of the provided asset or station
   * @param {string} id - Either station ID or asset ID
   * @param {boolean} [forceOpen] - If provided, always set to collapsed
   */
  const handleListItemClick = useCallback((id: string, forceOpen?: boolean) => {
    setOpenListItems((prevState: OpenListItemsState) => ({
      ...prevState,
      [id]: forceOpen ? true : !Boolean(prevState[id]),
    }));
  }, []);

  /**
   * Provides the ability to scroll to a particular asset or region in the list
   * @param {string} assetId - the id of the asset to scroll to
   * @param {string} regionId (optional) - the id of the region to scroll to
   */
  const scrollToListItem = useCallback(
    (assetId: string | null, regionId?: string) => {
      const doTheScroll = () => {
        let currentRef = null;

        const scrollToItem = regionId || assetId || filteredStationIds[0];
        if (listRefs[scrollToItem]) {
          currentRef = listRefs[scrollToItem].current;
        }

        if (currentRef) {
          currentRef.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }

        // since there's no callback that fires when the scrollIntoView function is complete
        // we use a timeOut to add a delay to the scrollbar being ready again, so the user can't
        // overwhelm the application with new clicks.
        setTimeout(
          () => dispatch(setNavigation({ scrollTo, scrolling: "ready" })),
          1000
        );
      };

      // If we want to scroll to a region and the asset/station isn't opened, open it first and then scroll
      // Needs a timeout otherwise the Ref may not exist yet
      if (assetId) {
        const station = findStationFromAsset(assetList, assetId);

        // Only need to delay scroll if station or asset not open
        if ((station && !openListItems[station]) || !openListItems[assetId]) {
          !openListItems[assetId] && handleListItemClick(assetId, true);
          station &&
            !openListItems[station] &&
            handleListItemClick(station, true);
          setTimeout(doTheScroll, 500);
        } else {
          doTheScroll();
        }
      } else {
        doTheScroll();
      }
    },
    [
      dispatch,
      scrollTo,
      assetList,
      listRefs,
      handleListItemClick,
      openListItems,
      filteredStationIds,
    ]
  );

  /**
   * Handles breadcrumb clicks
   */
  useEffect(() => {
    // scrolling will be true if breadcrumb clicked
    // Set back to null after scrolling done
    if (scrolling === "scrolling") {
      if (scrollTo) {
        scrollToListItem(scrollTo);
      } else {
        scrollToListItem(null); // For when "Wells" is clicked, scroll to top of list
      }
    }
  }, [dispatch, scrollTo, scrolling, scrollToListItem]);

  /**
   * Make sure the selected asset and corresponding station is open on load
   */
  useEffect(() => {
    if (!selectedAsset) {
      // Ensure all list items closed if user navigates here from menu
      setOpenListItems({});
    } else {
      const station = findStationFromAsset(assetList, selectedAsset);
      station && handleListItemClick(station, true);
      handleListItemClick(selectedAsset, true);
    }

    // TODO: Scroll to region on load
  }, [assetList, selectedAsset, handleListItemClick]);

  const renderAsset = (index: number, stationId: string, assetId: string) => {
    const station = filteredList[stationId];
    const { regions } = station[assetId];
    const open = Boolean(openListItems[assetId]);

    // Check if active pump has issue and its anomaly score
    let assetHasIssue = false;
    let assetAnomalyScore;

    regions.forEach((region) => {
      const { endDate, hasIssues, anomalyScore } = region;

      // Should only be one active pump which won't have an end date
      if (!endDate) {
        if (hasIssues) assetHasIssue = true;
        if (anomalyScore) assetAnomalyScore = anomalyScore;
      }
    });

    // Colour code based on active pump's anomaly score
    let anomalyCategoryColour = "";
    if (assetAnomalyScore) {
      if (assetAnomalyScore < 500) anomalyCategoryColour = anomalyScoreOK;
      else if (assetAnomalyScore < 1000)
        anomalyCategoryColour = anomalyScoreWatch;
      else if (assetAnomalyScore < 1500)
        anomalyCategoryColour = anomalyScoreAnomalous;
      else anomalyCategoryColour = anomalyScoreDisregard;
    }

    return (
      <li key={assetId}>
        <ListItem
          button
          className={`${classes.listItem} ${classes.assetListItem}`}
          onClick={() => handleListItemClick(assetId)}
          disableRipple
          disableGutters
          ref={listRefs[assetId]}
        >
          <ListItemIcon className={classes.listIcon}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </ListItemIcon>
          <ListItemText
            primary={assetId}
            style={{ color: anomalyCategoryColour || bodyFont }}
          />
          {assetHasIssue && <Error style={{ color: anomalyScoreAnomalous }} />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {regions
              .slice()
              .sort(sortPumpsOldestToNewest)
              .map((region: Region) => {
                const { regionId, startDate, endDate } = region;

                return (
                  <ListItem
                    key={regionId}
                    button
                    className={`${classes.listItem} ${classes.regionListItem} ${
                      selectedRegion === regionId.toString()
                        ? classes.selected
                        : ""
                    }`}
                    onClick={() => {
                      history.push(`/wells/${assetId}/pumps/${regionId}`);
                    }}
                    disableGutters
                    ref={listRefs[regionId]}
                  >
                    <ListItemText
                      primary={formatPumpDate(startDate)}
                      style={{ color: endDate ? inactiveListItem : bodyFont }}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Collapse>
        {index !== Object.keys(station).length - 1 && <Divider />}
      </li>
    );
  };

  // Used in renderStation and conditional rendering
  const filteredStationIdsLength = filteredStationIds.length;

  const renderStation = (index: number) => {
    const stationId = filteredStationIds[index];
    const open = Boolean(openListItems[stationId]);

    return (
      <li key={stationId}>
        <ListItem
          button
          className={`${classes.listItem} ${classes.stationListItem}`}
          onClick={() => handleListItemClick(stationId)}
          disableRipple
          disableGutters
          ref={listRefs[stationId]}
        >
          <ListItemIcon className={classes.listIcon}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </ListItemIcon>
          <ListItemText primary={stationId} />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {Object.keys(filteredList[stationId]).map((assetId, index) =>
              renderAsset(index, stationId, assetId)
            )}
          </List>
        </Collapse>
        {index !== filteredStationIdsLength - 1 && <Divider />}
      </li>
    );
  };

  return (
    <Card style={{ height: height || "100%" }}>
      {isLoading ? (
        <LoadingCard key={"asset-list-loading"} />
      ) : (
        <>
          <CardContent
            className={classes.listWrapper}
            style={{
              height: mobile ? "100%" : `calc(100% - ${cardActionsHeight}px)`,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  classes={{ root: classes.checkbox }}
                />
              }
              label="Only show wells with issues"
              className={classes.checkboxLabel}
            />
            {filteredStationIdsLength === 0 ? (
              <Typography variant="body1" align="center">
                No wells found
              </Typography>
            ) : (
              <List disablePadding className={classes.list}>
                {filteredStationIds.map((val, index) => renderStation(index))}
              </List>
            )}
          </CardContent>

          <Hidden smDown>
            <CardActions className={classes.listActionWrapper}>
              <Button
                onClick={() => {
                  scrollToListItem(selectedAsset, selectedRegion);
                }}
                fullWidth
                size="large"
                variant="outlined"
                className={classes.selectPumpBtn}
                disabled={!selectedRegion}
              >
                Go to selected pump
              </Button>
            </CardActions>
          </Hidden>
        </>
      )}
    </Card>
  );
};

export default AssetList;
