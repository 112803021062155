var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { withSize, defaultSizeMeOptions, } from "../Plotting/DefaultPlot";
var BabylonCanvasBase = /** @class */ (function (_super) {
    __extends(BabylonCanvasBase, _super);
    function BabylonCanvasBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.canvas = {};
        _this.onResizeWindow = function () {
            _this.props.resize();
        };
        _this.onCanvasLoaded = function (canvas) {
            if (canvas !== null) {
                _this.canvas = canvas;
            }
        };
        return _this;
    }
    BabylonCanvasBase.prototype.componentDidMount = function () {
        this.props.onCanvasMounted(this.canvas);
        // Resize the babylon engine when the window is resized
        window.addEventListener("resize", this.onResizeWindow);
    };
    BabylonCanvasBase.prototype.componentWillUnmount = function () {
        window.removeEventListener("resize", this.onResizeWindow);
    };
    BabylonCanvasBase.prototype.render = function () {
        // 'rest' can contain additional properties that you can flow through to canvas:
        // (id, className, etc.)
        var _a = this.props, width = _a.width, height = _a.height;
        var opts = {};
        if (width !== undefined && height !== undefined) {
            opts.width = width;
            opts.height = height;
        }
        return React.createElement("canvas", __assign({}, opts, { ref: this.onCanvasLoaded }));
    };
    return BabylonCanvasBase;
}(React.Component));
export { BabylonCanvasBase };
var BabylonCanvasSizedBase = function (props) {
    var size = props.size, minWidth = props.minWidth, aspect = props.aspect, baseProps = __rest(props, ["size", "minWidth", "aspect"]);
    var width = Math.max(size.width || minWidth || 0, minWidth || 0, 100) - 10;
    var height = Math.max(size.height || 0, 100) - 10; // (aspect || 0.75) * width;
    return (React.createElement("div", { style: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            overflow: "hidden",
            paddingLeft: 5,
            paddingTop: 5,
        } },
        React.createElement(BabylonCanvasBase, __assign({}, baseProps, { width: width, height: height }))));
};
var MONITOR_SIZE_OPTIONS = __assign(__assign({}, defaultSizeMeOptions), { monitorHeight: true, monitorWidth: true });
export var BabylonCanvas = withSize(MONITOR_SIZE_OPTIONS)(BabylonCanvasSizedBase);
