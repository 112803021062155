import React from "react";
import { Card, Typography, Grid, Divider, makeStyles } from "@material-ui/core";
import { LegendColorIcon, MarkerPin } from "@toumetis/cascadence-react-ui";
import {
  anomalyScoreOK,
  anomalyScoreWatch,
  anomalyScoreAnomalous,
  anomalyScoreDisregard,
} from "./../../constants/colours";

const useStyles = makeStyles((theme) => ({
  legend: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  legendEntry: {
    fontSize: "0.75rem",
    paddingLeft: theme.spacing(1),
  },
  legendDivider: {
    margin: theme.spacing(1, 0),
  },
}));

export const AssetMapLegend = () => {
  const classes = useStyles();
  return (
    <Card className={classes.legend}>
      <Grid alignItems="center" direction="row" container>
        <LegendColorIcon fillColor={anomalyScoreDisregard} />
        <Typography className={classes.legendEntry}>Snoozed</Typography>
      </Grid>
      <Grid alignItems="center" direction="row" container>
        <LegendColorIcon fillColor={anomalyScoreOK} />
        <Typography className={classes.legendEntry}>OK</Typography>
      </Grid>
      <Grid alignItems="center" direction="row" container>
        <LegendColorIcon fillColor={anomalyScoreWatch} />
        <Typography className={classes.legendEntry}>Watch</Typography>
      </Grid>
      <Grid alignItems="center" direction="row" container>
        <LegendColorIcon fillColor={anomalyScoreAnomalous} />
        <Typography className={classes.legendEntry}>Anomalous</Typography>
      </Grid>
      <Divider className={classes.legendDivider} variant="middle"></Divider>
      <Grid alignItems="center" direction="row" container>
        <MarkerPin height={19} filled={true} color={"#333"} />
        <Typography className={classes.legendEntry}>Producing</Typography>
      </Grid>
      <Grid alignItems="center" direction="row" container>
        <MarkerPin height={19} filled={false} color={"#333"} />
        <Typography className={classes.legendEntry}>Shut in</Typography>
      </Grid>
    </Card>
  );
};
