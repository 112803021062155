import React from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Typography,
  Divider,
  Button,
  makeStyles,
} from "@material-ui/core";
import { doLogout } from "./authSlice";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "fixed",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  continueButton: {
    marginRight: theme.spacing(2),
  },
  logoutOptionButtons: {
    marginTop: theme.spacing(1),
  },
  padded: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(1, 0)}`,
  },
}));

export interface LogoutDialogProps {
  open: boolean;
  closeHandler: () => void;
}
export const LogoutDialog = (props: LogoutDialogProps) => {
  const { open, closeHandler } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => closeHandler()}>
      <IconButton
        onClick={() => closeHandler()}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Grid container direction="column" className={classes.padded}>
        <Grid item>
          <Typography align="left" variant="h5">
            Log out of Cascadence?
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Are you sure you want to log out of this session?
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-end"
          className={classes.logoutOptionButtons}
        >
          <Button
            className={classes.continueButton}
            variant="contained"
            color="primary"
            onClick={() => dispatch(doLogout())}
          >
            Continue
          </Button>
          <Button variant="contained" onClick={() => closeHandler()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
