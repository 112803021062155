import * as React from "react";
import { Tooltip, IconButton, Typography, createStyles, withStyles, } from "@material-ui/core";
import ToolbarMenu from "./ToolbarMenu";
import { ToumetisIcon } from "../ToumetisIcon/ToumetisIcon";
import range from "lodash.range";
import { Delete as DeleteIcon, LineStyle as LineStyleIcon, InsertChart as InsertChartIcon, ShowChart as SinglelineChart, MultilineChart, BorderBottom as XAxisIcon, BorderLeft as YAxisIcon, ViewColumnOutlined as ColumnIcon, Fullscreen, FullscreenExit, } from "@material-ui/icons";
var Y_AXIS_MENU = ["Arbitrary", "Normalised", "Multiple Axes"];
var RESAMPLE_MENU = ["Time Average", "Largest Triangle"];
var MAX_COLUMNS = 8;
var makeColumnMenu = function (columns) {
    return range(1, Math.min(columns, MAX_COLUMNS) + 1).map(function (n) { return ({
        text: "" + n,
        value: n,
    }); });
};
export var DeleteAll = function (props) {
    return (React.createElement(Tooltip, { title: "Remove all tags" },
        React.createElement(IconButton, { onClick: props.onClick },
            React.createElement(DeleteIcon, null))));
};
export var XAxisToggle = function (props) {
    return (React.createElement(Tooltip, { title: props.selected ? "Hide X axes" : "Show X axes" },
        React.createElement(IconButton, { onClick: props.onClick },
            React.createElement(XAxisIcon, null))));
};
export var YAxisToggle = function (props) {
    return (React.createElement(Tooltip, { title: props.selected ? "Hide Y axes" : "Show Y axes" },
        React.createElement(IconButton, { onClick: props.onClick },
            React.createElement(YAxisIcon, null))));
};
export var FullScreenToggle = function (props) {
    return (React.createElement(Tooltip, { title: props.selected ? "Exit fullscreen view" : "Fullscreen view" },
        React.createElement(IconButton, { onClick: props.onClick }, props.selected ? React.createElement(FullscreenExit, null) : React.createElement(Fullscreen, null))));
};
export var SingleChartToggle = function (props) {
    return (React.createElement(Tooltip, { title: props.selected ? "Show on multiple plots" : "Show on one plot" },
        React.createElement(IconButton, { onClick: props.onClick }, props.selected ? React.createElement(SinglelineChart, null) : React.createElement(MultilineChart, null))));
};
var loadingItemStyles = function (theme) {
    return createStyles({
        container: {
            display: "inline",
            padding: theme.spacing(2),
        },
        spinner: {
            top: "3px",
            marginRight: theme.spacing(1),
        },
    });
};
var LoadingItemUnstyled = function (props) {
    var classes = props.classes, text = props.text;
    return (React.createElement("div", { className: classes.container },
        React.createElement(ToumetisIcon, { spin: true, pointer: true, size: 16, className: classes.spinner }),
        React.createElement(Typography, { display: "inline", variant: "body2" }, text)));
};
export var LoadingItem = withStyles(loadingItemStyles)(LoadingItemUnstyled);
export var YAxisStyle = function (props) {
    return (React.createElement(ToolbarMenu, { toolTip: "Y-axis Style", items: Y_AXIS_MENU, onItemChosen: props.onItemChosen },
        React.createElement(InsertChartIcon, null)));
};
export var ResampleMethod = function (props) {
    return (React.createElement(ToolbarMenu, { toolTip: "Resample strategy", items: RESAMPLE_MENU, onItemChosen: props.onItemChosen },
        React.createElement(LineStyleIcon, null)));
};
export var ColumnMenu = function (props) {
    var onItemChosen = props.onItemChosen, maxColumns = props.maxColumns;
    if (maxColumns < 1) {
        return null;
    }
    return (React.createElement(ToolbarMenu, { toolTip: "Columns to display", items: makeColumnMenu(maxColumns), onItemChosen: onItemChosen },
        React.createElement(ColumnIcon, null)));
};
