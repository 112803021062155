import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getModelData } from "../../api/model";

export interface ModelData {
  id: string;
  times: string[]; // Timestamp
  values: number[];
}

interface ModelDataState {
  modelData: { [assetId: string]: { [regionId: string]: ModelData } };
  isLoading: boolean;
  error: string | null;
}

interface ModelDataSuccessPayload {
  assetId: string;
  regionId: string;
  modelData: ModelData;
}

const initialState: ModelDataState = {
  modelData: {},
  isLoading: true,
  error: null,
};

function startLoading(state: ModelDataState) {
  state.isLoading = true;
}

function loadingFailed(state: ModelDataState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const modelDataSlice = createSlice({
  name: "modelData",
  initialState,
  reducers: {
    getModelDataStart: startLoading,
    getModelDataSuccess(state, action: PayloadAction<ModelDataSuccessPayload>) {
      const { assetId, regionId, modelData } = action.payload;

      // Don't want to overwrite modelData for assetId, only assetId/regionId combo
      state.modelData[assetId] = state.modelData[assetId] || {};
      state.modelData[assetId][regionId] = modelData;
      state.isLoading = false;
      state.error = null;
    },
    getModelDataFailure: loadingFailed,
  },
});

export const {
  getModelDataStart,
  getModelDataSuccess,
  getModelDataFailure,
} = modelDataSlice.actions;

export const fetchModelData = (
  assetId: string,
  regionId: string,
  startTime?: string,
  endTime?: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(getModelDataStart());
    const modelData = await getModelData(assetId, regionId, startTime, endTime);

    const payload: ModelDataSuccessPayload = {
      assetId,
      regionId,
      modelData,
    };
    dispatch(getModelDataSuccess(payload));
  } catch (err) {
    dispatch(getModelDataFailure(err.toString()));
  }
};

export default modelDataSlice.reducer;
