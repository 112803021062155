import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginFormData } from "@toumetis/cascadence-react-ui";
import { AppThunk } from "../../app/store";
import { getToken } from "../../api/auth";
import axios from "axios";
import { encryptWithAES } from "../../utils";

interface AuthState {
  isLoading: boolean;
  error: string | null;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  error: null,
  isLoggedIn: false,
};

let timeout: number;

function startLogin(state: AuthState) {
  state.isLoading = true;
  state.error = null;
}
function loginFailed(state: AuthState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export interface LoginPayload {
  token: string;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Login
    getLoginStart: startLogin,
    getLoginSuccess(state, action: PayloadAction<LoginPayload>) {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      sessionStorage.setItem("token", action.payload.token);

      // Set default Authorization header so we don't have to provide it for every request
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.token}`;
    },
    getLoginFailure: loginFailed,

    // Logout
    doLogout(state) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("usr");
      sessionStorage.removeItem("pw");
      state.isLoggedIn = false;
      axios.defaults.headers.common["Authorization"] = "";
    },
  },
});

export const {
  getLoginStart,
  getLoginSuccess,
  getLoginFailure,
  doLogout,
} = authSlice.actions;

export const fetchAuthToken = (loginData: LoginFormData): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getLoginStart());
    const token = await getToken(loginData);
    dispatch(getLoginSuccess(token));

    // NOTE: for demo purposes only
    // Save username and password so we can get a new token even after refreshing the browser
    sessionStorage.setItem("usr", loginData.username);
    sessionStorage.setItem("pw", encryptWithAES(loginData.password));

    dispatch(startAuthTimer(loginData));
  } catch (err) {
    dispatch(getLoginFailure(err.toString()));
  }
};

// NOTE: for demo purposes only
export const startAuthTimer = (loginData: LoginFormData): AppThunk => async (
  dispatch
) => {
  if (timeout) window.clearTimeout(timeout);

  // Tokens expire after 30 minutes, so set a timeout for 25 minutes to get a new one before that happens
  timeout = window.setTimeout(() => {
    dispatch(fetchAuthToken(loginData));
  }, 60 * 1000 * 25);
};

export default authSlice.reducer;
