import React, {
  useState,
  useEffect,
  useMemo,
  createRef,
  RefObject,
} from "react";
import moment from "moment";

// React Router
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { RouteParams } from "../../app/App";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { updateIssueStatus, Issue } from "./issuesSlice";

// Material UI
import {
  makeStyles,
  useTheme,
  Card,
  CardHeader,
  Collapse,
  Grid,
  ButtonGroup,
  Button,
  ButtonBase,
  IconButton,
  Divider,
  Typography,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
  Error,
  Mail,
  Visibility,
  Search,
  Delete,
} from "@material-ui/icons";

// Sensorhealth components
import { MetadataTable } from "@toumetis/cascadence-react-ui";

// Constants
import { dateFormat } from "../../globals";
import {
  selectedListItem,
  selectedOpenListItemBorder,
  selectedCollapseListItemBorder,
  muiGrey,
} from "../../constants/colours";

// Utility functions
import { extractIssuesForRegion } from "../../utils";

// Local components
import { LoadingCard } from "../../components/LoadingCard";

const useStyles = makeStyles((theme) => {
  const cardHeaderHeight = 70;
  const tableHeaderHeight = 40;
  const actionButtonHeight = 40;
  const sortIconSize = 16;

  return {
    card: {
      overflow: "hidden",
    },
    header: {
      height: cardHeaderHeight,
    },
    cardHeader: {
      paddingRight: 0,
    },
    filterContainer: {
      padding: theme.spacing(0, 2),
    },
    filter: {
      minWidth: 0, // Override tab width
    },
    cardContent: {
      height: `calc(100% - ${cardHeaderHeight}px)`,
      padding: theme.spacing(0, 2, 2, 2),
    },
    table: {
      maxHeight: "100%",
      overflowY: "auto",
    },
    tableHead: {
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: "#e0e0e0",
      height: tableHeaderHeight,
    },
    tableHeadCell: {
      justifyContent: "flex-start",
      width: "100%",
    },
    sortIcon: {
      width: sortIconSize,
      height: sortIconSize,
      marginLeft: theme.spacing(0.25),
    },
    tableRow: {
      borderBottom: "1px solid #e0e0e0",
    },
    tableRowCollapse: {
      borderBottomStyle: "solid",
    },
    tableRowRef: {
      scrollMarginTop: `${tableHeaderHeight}px`, // For sticky header
    },
    tableCell: {
      padding: theme.spacing(0.75),
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    tableCellAction: {
      width: "20%",
      [theme.breakpoints.up("md")]: {
        width: "10%",
      },
      padding: 0, // Icon has padding
    },
    tableCellAlert: {
      width: "20%",
      [theme.breakpoints.up("md")]: {
        width: "17.5%",
      },
    },
    tableCellFlagged: {
      width: "35%",
      [theme.breakpoints.up("md")]: {
        width: "22.5%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "17.5%",
      },
    },
    tableCellWell: {
      width: "25%",
      [theme.breakpoints.up("md")]: {
        width: "15%",
      },
    },
    tableCellAssigned: {
      width: "35%",
      [theme.breakpoints.up("lg")]: {
        width: "40%",
      },
    },
    alertLevel: {
      verticalAlign: "middle",
    },
    detailsContainer: {
      padding: theme.spacing(2),
    },
    detailsHeading: {
      fontSize: "1.2rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "1.25rem",
      },
    },
    actionButton: {
      justifyContent: "space-between",
      height: actionButtonHeight,
      borderRadius: 0,
      borderColor: "#e0e0e0 !important", // Match Divider/Table
    },
    mobileActionContainer: {
      padding: theme.spacing(1, 2),
    },
    mobileActionButton: {
      backgroundColor: "#e0e0e0",
      color: "#676767",
      "&:hover": {
        backgroundColor: "#e0e0e0",
        opacity: 0.7,
      },
    },
    mobileActionButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.7,
      },
    },
    confirmationRow: {
      padding: theme.spacing(1, 2),
    },
    emailInput: {
      marginBottom: theme.spacing(1.5),
    },
  };
});

interface IssueRowProps {
  issue: Issue;
  open: boolean;
  mobile: boolean;
  onRowClick: Function;
  onChevronClick: Function;
  onActionConfirm: Function;
  selectedIssue: Number;
  tableRowRef: RefObject<HTMLDivElement>;
}

interface CollapseData {
  [key: string]: string;
}

interface NotificationListProps {
  title?: string;
  showFilters?: boolean;
  mobile?: boolean;
  searchValue?: string;
  watchList?: boolean;
  height?: string;
}

interface TableRowRefs {
  [index: string]: RefObject<HTMLDivElement>;
}

type SortColumn = "anomalyScore" | "startTime" | "assetId" | "assignedTo";
type SortDirection = "ascending" | "descending";

interface SortConfig {
  column: SortColumn;
  direction: SortDirection;
}

const confirmationMessage: { [key: string]: string } = {
  Escalated: "Escalate issue?",
  Watching: "Add issue to watch list?",
  Review: "Add to reviewed issues?",
  Dismissed: "Dismiss issue?",
};

const IssueRow = (props: IssueRowProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    issue,
    open,
    mobile,
    onRowClick,
    onChevronClick,
    onActionConfirm,
    selectedIssue,
    tableRowRef,
  } = props;
  const {
    id,
    assetId,
    issueStatus,
    assignedTo,
    anomalyScore,
    regionId,
  } = issue;
  const issueId = id.toString();

  // Keeps track of which action confirmation is showing on mobile
  const [confirmationRow, setConfirmationRow] = useState("");
  const onMobileActionClick = (status: string) => {
    // Close confirmation row if same action pressed
    setConfirmationRow(confirmationRow === status ? "" : status);
  };

  // Determine alert level from anomaly score
  let alertLevel = "orange";
  if (anomalyScore >= 1000) alertLevel = "red";

  const isWatching = issueStatus === "Watching";
  const isReview = issueStatus === "Review";

  // Data to display alongside actions in Collapse component
  // TODO: Decide what other data to display here
  const collapseData: CollapseData = {
    "Issue ID": issueId,
  };

  // Assigned To column not shown on desktop due to space constraints
  if (mobile && assignedTo) collapseData["Assigned To"] = assignedTo;

  const selected = id === selectedIssue;

  return (
    <Grid item xs={12} ref={tableRowRef} className={classes.tableRowRef}>
      <ButtonBase
        component="div"
        onClick={() => onRowClick(assetId, regionId)}
        disableRipple
        style={{ width: "100%" }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          className={classes.tableRow}
          style={{
            borderBottomColor:
              selected && open ? selectedOpenListItemBorder : muiGrey,
            backgroundColor: selected ? selectedListItem : "transparent",
          }}
        >
          <Grid
            item
            className={`${classes.tableCell} ${classes.tableCellAction}`}
          >
            <IconButton
              size="small"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => onChevronClick(issueId, event)}
            >
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Grid>
          <Grid
            item
            className={`${classes.tableCell} ${classes.tableCellAlert}`}
          >
            {alertLevel === "orange" ? (
              <Warning
                style={{ color: theme.palette.categoryColors.warning[0] }}
                className={classes.alertLevel}
              />
            ) : (
              <Error
                style={{ color: theme.palette.categoryColors.bad[0] }}
                className={classes.alertLevel}
              />
            )}
          </Grid>
          <Grid
            item
            className={`${classes.tableCell} ${classes.tableCellFlagged}`}
          >
            {moment(issue.startTime).format(dateFormat)}
          </Grid>
          <Grid
            item
            className={`${classes.tableCell} ${classes.tableCellWell}`}
          >
            {assetId}
          </Grid>
          <Hidden smDown>
            <Grid
              item
              className={`${classes.tableCell} ${classes.tableCellAssigned}`}
            >
              {assignedTo || "-"}
            </Grid>
          </Hidden>
        </Grid>
      </ButtonBase>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.tableRowCollapse}
        style={{
          borderWidth: open && !mobile ? 1 : 0,
          borderColor: selected ? selectedOpenListItemBorder : muiGrey,
          backgroundColor: selected
            ? selectedCollapseListItemBorder
            : "transparent",
        }}
      >
        <Grid container>
          <Grid
            item
            className={classes.detailsContainer}
            style={{ width: mobile ? "100%" : "calc(50% - 0.5px)" }}
          >
            <Typography variant="body1" className={classes.detailsHeading}>
              Status: {issueStatus || "Awaiting Action"}
            </Typography>
            <MetadataTable
              metadata={Object.entries(collapseData).map((entry) => ({
                key: entry[0],
                value: entry[1] ? entry[1].toString() : entry[1],
              }))}
            />
          </Grid>
          <Hidden smDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Grid item style={{ width: mobile ? "100%" : "calc(50% - 0.5px)" }}>
            <Hidden mdUp>
              <>
                <Grid
                  container
                  justify="space-between"
                  className={classes.mobileActionContainer}
                >
                  <IconButton
                    onClick={() => onMobileActionClick("Escalated")}
                    className={
                      confirmationRow === "Escalated"
                        ? classes.mobileActionButtonSelected
                        : classes.mobileActionButton
                    }
                  >
                    <Mail />
                  </IconButton>
                  {!isWatching && (
                    <IconButton
                      onClick={() => onMobileActionClick("Watching")}
                      className={
                        confirmationRow === "Watching"
                          ? classes.mobileActionButtonSelected
                          : classes.mobileActionButton
                      }
                    >
                      <Visibility />
                    </IconButton>
                  )}
                  {!isReview && (
                    <IconButton
                      onClick={() => onMobileActionClick("Review")}
                      className={
                        confirmationRow === "Review"
                          ? classes.mobileActionButtonSelected
                          : classes.mobileActionButton
                      }
                    >
                      <Search />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => onMobileActionClick("Dismissed")}
                    className={
                      confirmationRow === "Dismissed"
                        ? classes.mobileActionButtonSelected
                        : classes.mobileActionButton
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
                <Divider />
                <Collapse in={confirmationRow !== ""}>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.confirmationRow}
                  >
                    <Grid item>{confirmationMessage[confirmationRow]}</Grid>
                    <Grid item>
                      <Button
                        onClick={() => onActionConfirm(id, confirmationRow)}
                        variant="outlined"
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />
                </Collapse>
              </>
            </Hidden>
            <Hidden smDown>
              <ButtonGroup orientation="vertical" fullWidth variant="text">
                <Button
                  onClick={() => onActionConfirm(id, "Escalated")}
                  endIcon={<Mail />}
                  className={classes.actionButton}
                >
                  Escalate
                </Button>
                {!isWatching && (
                  <Button
                    onClick={() => onActionConfirm(id, "Watching")}
                    endIcon={<Visibility />}
                    className={classes.actionButton}
                  >
                    Watch
                  </Button>
                )}
                <Button
                  onClick={() => onActionConfirm(id, "Review")}
                  endIcon={<Search />}
                  className={classes.actionButton}
                >
                  Review
                </Button>
                <Button
                  onClick={() => onActionConfirm(id, "Dismissed")}
                  endIcon={<Delete />}
                  className={classes.actionButton}
                >
                  Dismiss
                </Button>
              </ButtonGroup>
            </Hidden>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

const NotificationList = (props: NotificationListProps) => {
  const { title, showFilters, mobile, searchValue, watchList, height } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { issues, isLoading } = useSelector((state: RootState) => state.issues);
  const { assetId: selectedAssetId, regionId: selectedRegionId } = useParams<
    RouteParams
  >();

  // Filters/Sorting
  const [filterIndex, setFilterIndex] = useState(0);
  const onFilterChange = (event: React.ChangeEvent<{}>, index: number) =>
    setFilterIndex(index);
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    column: "anomalyScore",
    direction: "ascending",
  });
  const onSortChange = (column: SortColumn) => {
    let direction: SortDirection = "ascending";

    // If we're already sorting by this column, switch direction
    if (sortConfig.column === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ column, direction });
  };
  const getSortColumnIcon = (column: string) => {
    let icon = null;
    if (sortConfig.column === column) {
      icon =
        sortConfig.direction === "ascending" ? (
          <KeyboardArrowUp className={classes.sortIcon} />
        ) : (
          <KeyboardArrowDown className={classes.sortIcon} />
        );
    }
    return icon;
  };
  useEffect(() => {
    const newFilteredIssues: Issue[] = [];
    const lcSearchValue = searchValue && searchValue.toLowerCase();

    // Determine filter
    let filter = "";
    switch (filterIndex) {
      case 1:
        filter = "Watching";
        break;
      case 2:
        filter = "Review";
        break;
      default:
        break;
    }

    // Loops through assets with issues and then issues
    Object.keys(issues).forEach((assetId) => {
      Object.keys(issues[assetId]).forEach((issueId) => {
        const issue = issues[assetId][issueId];
        const { issueStatus } = issue;

        // If "All Issues" is selected, don't need to check issueStatus
        if (
          (filter === "" || filter === issueStatus) &&
          (!lcSearchValue || assetId.toLowerCase().includes(lcSearchValue)) &&
          (watchList
            ? ["Watching"].includes(issueStatus)
            : !["Dismissed"].includes(issueStatus))
        ) {
          newFilteredIssues.push(issue);
        }
      });
    });

    // Sorting
    newFilteredIssues.sort((a, b) => {
      const { column, direction } = sortConfig;
      const isAscending = direction === "ascending";

      const aValue = a[column];
      const bValue = b[column];

      // Use moment to sort dates
      if (column === "startTime") {
        if (moment(aValue).isAfter(bValue)) {
          return isAscending ? -1 : 1;
        }

        if (moment(bValue).isAfter(aValue)) {
          return isAscending ? 1 : -1;
        }

        return 0;
      }

      if (aValue < bValue) return isAscending ? 1 : -1;
      if (aValue > bValue) return isAscending ? -1 : 1;
      return 0;
    });

    setFilteredIssues(newFilteredIssues);
  }, [issues, filterIndex, searchValue, watchList, sortConfig]);

  // Keeps track of whether table rows are open
  type OpenTableRowsState = {
    [index: string]: boolean;
  };
  const [openTableRows, setOpenTableRows] = useState<OpenTableRowsState>({});
  const handleTableRowClick = (assetId: string, regionId: string) => {
    history.push(`/wells/${assetId}/pumps/${regionId}`);
  };
  const handleChevronClick = (
    issueId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenTableRows((prevState: OpenTableRowsState) => ({
      ...prevState,
      [issueId]: !Boolean(prevState[issueId]),
    }));
  };

  // Table row references for scrolling to issue on load
  const tableRowRefs = useMemo(() => {
    const refs: TableRowRefs = {};
    Object.keys(issues).forEach((assetId) => {
      Object.keys(issues[assetId]).forEach((issueId) => {
        refs[issueId] = createRef();
      });
    });

    return refs;
  }, [issues]);

  // Set selected issue and ensure actions are available
  const [selectedIssue, setSelectedIssue] = useState(0);
  useEffect(() => {
    if (selectedAssetId && selectedRegionId) {
      const regionIssues = extractIssuesForRegion(
        selectedAssetId,
        selectedRegionId,
        issues
      );

      if (regionIssues && regionIssues[0]) {
        const { id } = regionIssues[0];
        setOpenTableRows({ [id.toString()]: true });
        setSelectedIssue(id);
      }
    } else {
      setOpenTableRows({});
      setSelectedIssue(0);
    }
  }, [selectedAssetId, selectedRegionId, issues]);

  // Scrolls to selected issue
  useEffect(() => {
    if (selectedIssue !== 0) {
      const currentRef =
        tableRowRefs[selectedIssue] && tableRowRefs[selectedIssue].current;
      if (currentRef) {
        // Delaying scroll to wait for table row to open
        setTimeout(() => {
          currentRef.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 1000);
      }
    }
  }, [selectedIssue, tableRowRefs]);

  const onActionConfirm = (id: number, status: string) => {
    if (status === "Escalated") {
      setDialogIssue(id);
      return;
    }

    dispatch(updateIssueStatus(id, status));
  };

  // Escalation dialog
  const [dialogIssue, setDialogIssue] = useState(0);
  const [escalationEmail, setEscalationEmail] = useState("");
  const [escalationReason, setEscalationReason] = useState("");
  const [emailError, showEmailError] = useState(false);
  const handleDialogClose = () => setDialogIssue(0);
  const onEscalationEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEscalationEmail(event.target.value);
  };
  const onEscalationEmailBlur = () => {
    showEmailError(
      escalationEmail.length !== 0 && !escalationEmail.match(/^\S+@\S+\.\S+$/)
    );
  };
  const onEscalationReasonChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEscalationReason(event.target.value);
  };
  const onEscalateFormSubmit = () => {
    // Don't submit invalid email
    if (emailError) return;

    // Opens email client with provided email and prepopulated subject
    const subject = encodeURIComponent(
      `Rumaila Oilfield - Escalation of Issue ${dialogIssue}`
    );
    const body =
      escalationReason.length !== 0
        ? encodeURIComponent(`Reason: ${escalationReason}`)
        : "";
    window.location.href = `mailto:${escalationEmail}?subject=${subject}&body=${body}`;

    dispatch(updateIssueStatus(dialogIssue, "Escalated", escalationEmail));
    setDialogIssue(0);
    setEscalationEmail("");
    setEscalationReason("");
    showEmailError(false);
  };

  return (
    <Card
      className={classes.card}
      style={{
        height: height || "100%",
      }}
    >
      {isLoading ? (
        <LoadingCard key="notification-list-loading" />
      ) : (
        <>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.header}
          >
            {title && (
              <Grid item>
                <CardHeader title={title} className={classes.cardHeader} />
              </Grid>
            )}
            {showFilters && (
              <Grid
                item
                className={classes.filterContainer}
                style={{ width: mobile ? "100%" : "auto" }}
              >
                <Tabs
                  value={filterIndex}
                  onChange={onFilterChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={Boolean(mobile) ? "fullWidth" : "standard"}
                >
                  <Tab label="All Issues" className={classes.filter} />
                  <Tab label="Watching" className={classes.filter} />
                  <Tab label="Review" className={classes.filter} />
                </Tabs>
              </Grid>
            )}
          </Grid>
          <div className={classes.cardContent}>
            {filteredIssues.length === 0 ? (
              <Typography variant="body1" align="center">
                {watchList ? "No issues being watched" : "No issues found"}
              </Typography>
            ) : (
              <Grid container item xs={12} className={classes.table}>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  className={classes.tableHead}
                >
                  <Grid
                    item
                    className={`${classes.tableCell} ${classes.tableCellAction}`}
                  />
                  <Grid
                    item
                    className={`${classes.tableCell} ${classes.tableCellAlert}`}
                  >
                    <ButtonBase
                      onClick={() => onSortChange("anomalyScore")}
                      component="span"
                      disableRipple
                      className={classes.tableHeadCell}
                    >
                      Alert
                      {getSortColumnIcon("anomalyScore")}
                    </ButtonBase>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.tableCell} ${classes.tableCellFlagged}`}
                  >
                    <ButtonBase
                      onClick={() => onSortChange("startTime")}
                      component="span"
                      disableRipple
                      className={classes.tableHeadCell}
                    >
                      Flagged
                      {getSortColumnIcon("startTime")}
                    </ButtonBase>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.tableCell} ${classes.tableCellWell}`}
                  >
                    <ButtonBase
                      onClick={() => onSortChange("assetId")}
                      component="span"
                      disableRipple
                      className={classes.tableHeadCell}
                    >
                      Well
                      {getSortColumnIcon("assetId")}
                    </ButtonBase>
                  </Grid>
                  <Hidden smDown>
                    <Grid
                      item
                      className={`${classes.tableCell} ${classes.tableCellAssigned}`}
                    >
                      <ButtonBase
                        onClick={() => onSortChange("assignedTo")}
                        component="span"
                        disableRipple
                        className={classes.tableHeadCell}
                      >
                        Assigned To
                        {getSortColumnIcon("assignedTo")}
                      </ButtonBase>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid container item xs={12} justify="center">
                  {filteredIssues.map((issue) => {
                    const issueId = issue.id.toString();
                    const open = Boolean(openTableRows[issueId]);
                    return (
                      <IssueRow
                        key={issueId}
                        issue={issue}
                        open={open}
                        mobile={Boolean(mobile)}
                        onRowClick={handleTableRowClick}
                        onChevronClick={handleChevronClick}
                        onActionConfirm={onActionConfirm}
                        selectedIssue={selectedIssue}
                        tableRowRef={tableRowRefs[issueId]}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </div>
          <Dialog open={dialogIssue !== 0} onClose={handleDialogClose}>
            <DialogTitle>Escalate Issue</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To escalate this issue, enter an email address and a reason
                (optional) and click Escalate.
              </DialogContentText>
              <TextField
                autoFocus
                label="Email Address"
                type="email"
                fullWidth
                value={escalationEmail}
                error={emailError}
                onChange={onEscalationEmailChange}
                onBlur={onEscalationEmailBlur}
                helperText={
                  emailError ? "Please enter a valid email address" : ""
                }
                className={classes.emailInput}
                required
              />
              <TextField
                label="Reason"
                type="textarea"
                fullWidth
                value={escalationReason}
                onChange={onEscalationReasonChange}
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={onEscalateFormSubmit}
                variant="outlined"
                disabled={escalationEmail.length === 0 || emailError}
              >
                Escalate
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Card>
  );
};

export default NotificationList;
