import { IssuesByWell } from "features/issues/issuesSlice";

const CryptoJS = require("crypto-js");
const passphrase = "RBrTiTxnSUuL13MvSG9M";

/**
 * Encrypt some text using AES
 * @param text - the text to encrypt
 * @returns {string}
 */
export const encryptWithAES = (text: string) => {
  if (!text) return "";
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

/**
 * Decrypt some ciphertext using AES
 * @param ciphertext - the text to decrypt
 * @returns {string}
 */
export const decryptWithAES = (ciphertext: string) => {
  if (!ciphertext) return "";
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const text = bytes.toString(CryptoJS.enc.Utf8);
  return text;
};

/**
 * Gets the issues associated with a particular region (pump)
 * @param {string} assetId - the id of the asset (well) that the region (pump) belongs to
 * @param {string} regionId - the id of the region (pump)
 * @param {IssuesByWell} issuesByWell - the issues data from Redux
 * @returns {array} - array of the issues associated with the provided region (pump)
 */
export const extractIssuesForRegion = (
  assetId: string,
  regionId: string,
  issuesByWell: IssuesByWell
) => {
  const issuesForWell = issuesByWell[assetId];
  if (!issuesForWell) return [];

  const issuesForWellValues = Object.values(issuesForWell);

  // TODO: correct regionId throughout the app to be the correct type, so we can use === here
  const filteredIssues = issuesForWellValues.filter(
    (issue) => issue.regionId == regionId
  );

  return filteredIssues || [];
};

/**
 * Round a group of percentages (that add up to 100) down, but still ensure they add up to 100 after the rounding
 * @param percentagesArray - array of objects, consisting of a name and value for each percentage
 *                         - the name is needed to relate a particular percentage with what it means
 */
export const roundPercentages = (
  percentagesArray: { name: string; value: number }[]
) => {
  // Get the total of the values
  const originalTotal = percentagesArray.reduce(
    (total, percentage) => total + percentage.value,
    0
  );

  // Protect against bad values being provided, e.g. when data hasn't been loaded yet
  if (originalTotal === 0) {
    return percentagesArray.reduce(
      (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
      {}
    );
  }

  // Sort by descending decimal value
  percentagesArray.sort((a, b) => {
    return (b.value % 1) - (a.value % 1);
  });

  // Get the total of the values when rounded down
  const roundedTotal = percentagesArray.reduce(
    (total, percentage) => total + Math.floor(percentage.value),
    0
  );

  // Get the difference from 100% from the total of the rounded values
  const differenceFrom100 = 100 - roundedTotal;

  // Share the differenceFrom100 between the highest decimal values
  percentagesArray.forEach((item, index) => {
    item.value =
      index < differenceFrom100
        ? Math.floor(item.value) + 1
        : Math.floor(item.value);
  });

  return percentagesArray.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
    {}
  );
};
