// See the following doc for the current tag ids and their corresponding names.
// docs.google.com/spreadsheets/d/10PQksYbzY6kQQTO7VcehPgFKBVOcLcZA29va3mstbDU/edit#gid=0

export const mapTagIdToName = (id: string) =>
  tagIDNameMappings[id.replace(/^([^\_\s]+)_/, "")];

export const tagIDNameMappings: Record<string, string> = {
  "AMBTEMP.CV": "Ambient Temperature",
  "DC_BUS.CV": "DC Bus",
  "DISPRES.CV": "Pump Discharge Pressure",
  "DISPRESS_HH.CV": "Pump Discharge Pressure HH",
  "ILEAK.CV": "Leakage Current",
  "I_VSD.CV": "Pwr-Output Amps",
  "I_VSD_A.CV": "Pwr-Output Amps Ph A",
  "I_VSD_B.CV": "Pwr-Output Amps Ph B",
  "I_VSD_C.CV": "Pwr-Output Amps Ph C",
  "INTPRES.CV": "Pump Intake Pressure",
  "INTPRESS_LL.CV": "Pump Intake Pressure LL",
  "INTTEMP.CV": "Pump Intake Temperature",
  "MOTTEMP.CV": "Motor Temperature",
  "MOTTEMP.HH.CV": "Motor Temperature HH",
  "PRESS_DS.CV": "Choke Downstream Pressure",
  "PRESS_DS_BATTV.CV": "Choke Downstream Pressure Batt Voltage",
  "PRESS_DS_TAMPER.CV": "PRESS_DS Tamper Alarm",
  "PRESS_US.CV": "Choke Upstream Pressure",
  "PRESS_US_BATTV.CV": "Choke Upstream Pressure Batt Voltage",
  "PRESS_US_TAMPER.CV": "PRESS_US Tamper Alarm",
  "TEMP_ANN.CV": "Annulus Temperature",
  "TEMP_ANN_BATTV.CV": "Annulus Temperature Batt Voltage",
  "TEMP_FL.CV": "Flowline Temperature",
  "TEMP_FL_BATTV.CV": "Flowline Temperature Batt Voltage",
  "TEMP_TUB.CV": "Tube Temperature",
  "TEMP_TUB_BATTV.CV": "Tube Temperature Batt Voltage",
  "TEMP_US.CV": "Upstream Temperature",
  "TEMP_US_BATTV.CV": "Upstream Temperature Batt Voltage",
  "VAB.CV": "VSD Voltage",
  "VIBX.CV": "Vibration Pump X Axis",
  "VIBY.CV": "Vibration Pump Y Axis",
  "VIBZ.CV": "Vibration Pump Z Axis",
  "FREQ.CV": "Motor Frequency",
  well: "Well Name",
  "Start date": "Start Date",
  "failure date": "Failure Date",
  "POOH date": "POOH Date",
  Timestamp: "Timestamp",
};
