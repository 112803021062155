var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Breakpoints, } from "./BreakpointComponent";
import ismatch from "lodash.ismatch";
var Renderable = /** @class */ (function (_super) {
    __extends(Renderable, _super);
    function Renderable(props) {
        var _this = _super.call(this, props) || this;
        _this.getCurrentBreakpoint = function (_size) {
            if (_size > Breakpoints.XL) {
                return "xl";
            }
            if (_size > Breakpoints.LG) {
                return "lg";
            }
            if (_size > Breakpoints.MD) {
                return "md";
            }
            if (_size > Breakpoints.SM) {
                return "sm";
            }
            return "xs";
        };
        var _a = _this.props, components = _a.components, size = _a.size;
        _this.state = {
            size: size,
            jsx: React.createElement(React.Fragment, null),
            cache: {
                xs: components.xs,
                sm: components.sm,
                md: components.md,
                lg: components.lg,
                xl: components.xl,
            },
        };
        return _this;
    }
    Renderable.prototype.componentDidMount = function () {
        var breakpoint = this.getCurrentBreakpoint(this.props.size);
        this.setCurrentJSX(breakpoint);
    };
    Renderable.prototype.componentDidUpdate = function (previousProps) {
        var _this = this;
        var props = this.props;
        // Size check
        if (previousProps.size !== props.size) {
            var breakpoint = this.getCurrentBreakpoint(props.size);
            this.setCurrentJSX(breakpoint);
        }
        // Has JSX changed from outside?
        if (!ismatch(previousProps.components, props.components)) {
            this.setState({ cache: __assign({}, props.components) }, function () {
                _this.setCurrentJSX(_this.getCurrentBreakpoint(props.size));
            });
        }
    };
    Renderable.prototype.setCurrentJSX = function (breakpoint) {
        var cache = this.state.cache;
        if (cache[breakpoint] === undefined) {
            breakpoint = this.getClosestDefinedBreakpoint(breakpoint);
        }
        if (cache[breakpoint] instanceof Function) {
            var callback = cache[breakpoint];
            cache[breakpoint] = callback();
            this.setState({ jsx: cache[breakpoint] });
        }
        else {
            this.setState({ jsx: cache[breakpoint] });
        }
    };
    Renderable.prototype.getClosestDefinedBreakpoint = function (currentBreakpoint) {
        var _this = this;
        var breakpoints = ["xl", "lg", "md", "sm", "xs"];
        var availableBreakpoints = breakpoints.splice(breakpoints.indexOf(currentBreakpoint));
        var closest = availableBreakpoints.find(function (breakpoint) { return _this.state.cache[breakpoint] !== undefined; });
        return closest || "xs";
    };
    Renderable.prototype.render = function () {
        return React.createElement("div", null, this.state.jsx);
    };
    return Renderable;
}(React.Component));
export default Renderable;
