var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { List, Link as LinkText, ListItem, Collapse, withStyles, IconButton, createStyles, } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
export var nodeStyles = function (theme) {
    return createStyles({
        nodeStyle: {
            paddingLeft: theme.spacing(4),
        },
        listItem: {
            justifyContent: "space-between",
            color: theme.palette.text.primary,
            padding: 0,
            paddingLeft: theme.spacing(2),
        },
        nodeContainer: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            height: 44,
        },
        expandContainer: {
            height: 44,
        },
        link: {
            cursor: "pointer",
            textTransform: "capitalize",
        },
        icon: {
            color: theme.palette.text.secondary,
        },
    });
};
// Component
var TreeNodeBase = /** @class */ (function (_super) {
    __extends(TreeNodeBase, _super);
    function TreeNodeBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { open: false };
        _this.clickHandler = function () {
            _this.setState({ open: !_this.state.open });
        };
        return _this;
    }
    TreeNodeBase.prototype.render = function () {
        var _this = this;
        var _a = this.props, dataSource = _a.dataSource, classes = _a.classes;
        var node = dataSource.node, children = dataSource.children;
        var open = this.state.open;
        // very gross, but this library won't be used soon, so I don't care.
        var handleRouting = function () {
            if (_this.props.routeHandlerCallback) {
                _this.props.routeHandlerCallback(node);
            }
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(ListItem, { button: true, disableRipple: true, className: classes.listItem },
                React.createElement("div", { className: classes.nodeContainer, onClick: handleRouting },
                    React.createElement(LinkText, { className: classes.link, variant: "body2", color: "inherit", onClick: handleRouting }, node.name)),
                React.createElement("div", { className: classes.expandContainer }, children &&
                    children.length > 0 &&
                    (open ? (React.createElement(IconButton, { onClick: this.clickHandler },
                        React.createElement(ExpandLess, { className: classes.icon, fontSize: "small" }))) : (React.createElement(IconButton, { onClick: this.clickHandler },
                        React.createElement(ExpandMore, { className: classes.icon, fontSize: "small" })))))),
            children && children.length > 0 && (React.createElement(Collapse, { in: open, timeout: "auto", unmountOnExit: true },
                React.createElement(List, { className: classes.nodeStyle, disablePadding: true }, children.map(function (childNode) {
                    console.log("childnode: ", childNode);
                    return (
                    // tslint:disable-next-line:no-use-before-declare
                    React.createElement(TreeNode, __assign({}, _this.props, { dataSource: childNode, key: childNode.node.id })));
                }))))));
    };
    return TreeNodeBase;
}(React.Component));
// Exports
export var TreeNode = withStyles(nodeStyles)(TreeNodeBase);
