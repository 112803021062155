import React from "react";
import moment from "moment";
import DateTimePicker from "react-date-picker";
import isEmpty from "lodash.isempty";
import { dateFormatUnicode, dateFormatPlotly } from "../../globals";
import {
  CardHeader,
  CardContent,
  makeStyles,
  InputLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import {
  TimeseriesAnalytics,
  HighlightRange,
} from "@toumetis/cascadence-react-ui";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { ModelData } from "./modelDataSlice";

import { LoadingCard } from "../../components/LoadingCard";

import "./AnomalyScore.css";

interface AnomalyScoreProps {
  assetId: string;
  regionId: string;
  onRelayout: (event: Readonly<Plotly.PlotRelayoutEvent>) => void;
  highlightsArray?: Array<HighlightRange>;
  onDateChange: Function;
  startDate: Date | null;
  endDate: Date | null;
}

const useStyles = makeStyles((theme) => {
  return {
    cardHeader: {
      paddingBottom: 0,
    },
    datePickerContainer: {
      width: "auto",
      padding: theme.spacing(2, 2, 0, 2),
    },
    datePickerLabel: {
      marginBottom: theme.spacing(1),
    },
    startDate: {
      marginRight: theme.spacing(2),
    },
  };
});

const AnomalyScore = (props: AnomalyScoreProps) => {
  const {
    assetId,
    regionId,
    onRelayout,
    onDateChange,
    startDate,
    endDate,
  } = props;
  const classes = useStyles();
  const { modelData, isLoading } = useSelector(
    (state: RootState) => state.modelData
  );

  const onStartDateChange = (date: Date | Date[]) => {
    onDateChange(true, date);
  };

  const onEndDateChange = (date: Date | Date[]) => {
    onDateChange(false, date);
  };

  const regionModelData: ModelData =
    modelData && modelData[assetId] && modelData[assetId][regionId];

  return (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <CardHeader title="Anomaly Score" className={classes.cardHeader} />
        </Grid>
        <Grid container item className={classes.datePickerContainer}>
          <Grid item className={classes.startDate}>
            <InputLabel shrink={false} className={classes.datePickerLabel}>
              Start date
            </InputLabel>
            <DateTimePicker
              onChange={onStartDateChange}
              value={startDate}
              calendarIcon={<CalendarToday />}
              format={dateFormatUnicode}
            />
          </Grid>
          <Grid item>
            <InputLabel shrink={false} className={classes.datePickerLabel}>
              End date
            </InputLabel>
            <DateTimePicker
              onChange={onEndDateChange}
              value={endDate}
              calendarIcon={<CalendarToday />}
              format={dateFormatUnicode}
            />
          </Grid>
        </Grid>
      </Grid>
      <CardContent>
        {isLoading ? (
          <LoadingCard />
        ) : (
          <>
            {isEmpty(regionModelData) ? (
              <Typography variant="body1" align="center">
                No model data available
              </Typography>
            ) : (
              <TimeseriesAnalytics
                timeseriesList={[
                  {
                    name: regionModelData.id,
                    index: regionModelData.times.map((timestamp: string) =>
                      moment(timestamp).valueOf()
                    ),
                    data: regionModelData.values,
                  },
                ]}
                showXAxes={true}
                showYAxes={false}
                height={130}
                highlights={props.highlightsArray || []}
                onRelayout={onRelayout}
                tickFormat={dateFormatPlotly}
              />
            )}
          </>
        )}
      </CardContent>
    </>
  );
};

export default AnomalyScore;
