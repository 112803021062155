/**
 *
 * =============================================
 * Exports a plotly <Plot> component, wrapped in default settings and theme information.
 * Use this by default.
 * =============================================
 *
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { withSize } from "react-sizeme";
import { createStyles, withStyles } from "@material-ui/core";
import Plot from "react-plotly.js";
import merge from "lodash.merge";
// Export withSize & associtated types / defaults
export { withSize } from "react-sizeme";
export var defaultSizeMeOptions = {
    refreshMode: "debounce",
    refreshRate: 40,
};
var styles = function (theme) {
    return createStyles({
        plot: {
            height: "100%",
            width: "100%",
        },
    });
};
export var DefaultAxisValues = function (theme) {
    var axis = {
        showgrid: true,
        showline: true,
        gridcolor: theme.palette.grey[500],
        linecolor: theme.palette.grey[200],
    };
    return axis;
};
// --------------------- //
// -- Helper Function -- //
// --------------------- //
var defaultLayout = function (theme) {
    return {
        autosize: true,
        height: 450,
        font: {
            family: theme.typography.fontFamily,
        },
        hoverlabel: {
            bgcolor: theme.palette.primary.main,
            bordercolor: theme.palette.text.primary,
            font: {
                color: theme.palette.text.primary,
            },
        },
        plot_bgcolor: theme.palette.background.paper,
        paper_bgcolor: theme.palette.background.paper,
        geo: {
            showocean: true,
            oceancolor: "#F1FCFF",
            landcolor: theme.palette.primary.light,
            countrycolor: theme.palette.primary.main,
        },
    };
};
var mergeDefaultLayout = function (theme, layout) {
    var themeLayout = defaultLayout(theme);
    return merge(themeLayout, layout);
};
var mergeDefaultData = function (theme, data) {
    var traces = data.map(function (trace, index) {
        return {
            marker: {
                color: theme.palette.plot[index % theme.palette.plot.length],
            },
            line: {
                width: 1,
                color: theme.palette.plot[index % theme.palette.plot.length],
            },
        };
    });
    return merge(traces, data);
};
var mergeDefaultConfig = function (config) {
    var defaultConfig = {
        responsive: true,
        displaylogo: false,
    };
    return merge(defaultConfig, config);
};
// --------------- //
// -- Component -- //
// --------------- //
// This component is a wrapper for the Plotly <Plot> component. It provides basic layout parameters and theming/colors,
// so that our other plot components only have to worry about providing data, axes, title, etc.
//
// The default formatting is achieved by using base versions of the layout, data and config objects expected by <Plot>.
// These are merged with the versions provided in props, such that the props versions override any duplicate keys.
// This allows any parent component to override specific styles if needed.
var DefaultPlotBase = /** @class */ (function (_super) {
    __extends(DefaultPlotBase, _super);
    function DefaultPlotBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DefaultPlotBase.prototype.render = function () {
        var _a = this.props, data = _a.data, layout = _a.layout, config = _a.config, theme = _a.theme, classes = _a.classes;
        var plotProps = __assign(__assign({}, this.props), { data: mergeDefaultData(theme, data), layout: mergeDefaultLayout(theme, layout), config: mergeDefaultConfig(config) });
        return React.createElement(Plot, __assign({}, plotProps, { useResizeHandler: true, className: classes.plot }));
    };
    return DefaultPlotBase;
}(React.Component));
export var withSizeHoc = withSize(defaultSizeMeOptions);
var withStylesHoc = withStyles(styles, { withTheme: true });
export var DefaultPlot = withStylesHoc(DefaultPlotBase);
