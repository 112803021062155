import cc from "color-convert";
var ColorMapper = /** @class */ (function () {
    function ColorMapper(colors) {
        this.colors = colors;
        this.index = 0;
    }
    /** Return the next color in the array. */
    ColorMapper.prototype.getColor = function () {
        return this.colors[this.index++ % this.colors.length];
    };
    return ColorMapper;
}());
/**
 * Class to generate sequences of colors for traffic light statuses.
 */
var TrafficLightColorMap = /** @class */ (function () {
    function TrafficLightColorMap(theme) {
        this.theme = theme;
        var categoryColors = theme.palette.categoryColors;
        this.mappers = [
            new ColorMapper(categoryColors.unknown),
            new ColorMapper(categoryColors.good),
            new ColorMapper(categoryColors.warning),
            new ColorMapper(categoryColors.bad),
        ];
    }
    /**
     * Get the next color in the list for the appropriate type
     * @param colorType health type (Unknown, Good, Warning or Bad)
     */
    TrafficLightColorMap.prototype.getColor = function (colorType) {
        return this.mappers[colorType].getColor();
    };
    return TrafficLightColorMap;
}());
export { TrafficLightColorMap };
/**
 * Convert a normal color to a de-emphasised version
 * by reducing the saturation (greyer) and increasing
 * luminance (lighter shade)
 *
 * NB: Does not handle alpha channel or opacity.
 */
export function greyColor(col) {
    // Convert from rgb or css color to rgb
    var hsl = col.charAt(0) === "#"
        ? cc.hex.hsl(col.substr(1))
        : cc.keyword.hsl(col);
    // Drop the saturation by 50%
    hsl[1] /= 2;
    // Increase the luminance to half-way between the initial value and 100%
    hsl[2] = 50 + hsl[2] / 2;
    // Convert back to rgb & return
    return "#" + cc.hsl.hex(hsl);
}
