import React from "react";
import { withStyles, createStyles } from "@material-ui/core";
var styles = function (theme) {
    return createStyles({
        tooltipContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            display: "inline-block",
            width: "16px",
            height: "16px",
            "&:hover span": {
                visibility: "visible",
            },
        },
        tooltip: {
            visibility: "hidden",
            transform: "translate(-50%, -50%)",
            bottom: "5px",
            width: "auto",
            padding: "5px 15px",
            backgroundColor: "#000a",
            color: "#fff",
            textAlign: "center",
            borderRadius: "6px",
            position: "absolute",
            zIndex: 1,
        },
    });
};
export var MarkerTooltipBase = function (props) {
    return (React.createElement("span", { className: props.classes.tooltipContainer },
        React.createElement("span", { className: props.classes.tooltip }, props.description)));
};
export var MarkerTooltip = withStyles(styles, { withTheme: true })(MarkerTooltipBase);
