var axisSuffix = function (index) {
    return index ? "" + (index + 1) : "";
};
var xAxisName = function (index) {
    return ("x" + axisSuffix(index));
};
var yAxisName = function (index) {
    return ("y" + axisSuffix(index));
};
var Y_AXIS_WIDTH = 70;
var X_AXIS_HEIGHT = 20;
var MIN_GRAPH_WIDTH = 180;
var TimeseriesAnalyticsLayoutManager = /** @class */ (function () {
    function TimeseriesAnalyticsLayoutManager() {
        this.data = [];
        this.layout = {};
        this.xAxes = [];
        this.yAxes = [];
        this.plotGroups = [];
        this.tickFormat = "%Y-%m-%d";
        this.hoverFormat = "";
    }
    /** Helper function: Create a simple PlotDescription from a timeseries using
     * a separate axis for each timeseries & the timeseries name as the label
     */
    TimeseriesAnalyticsLayoutManager.PlotDescriptionFromTs = function (series, singlePlot) {
        if (singlePlot) {
            return {
                series: series,
                yAxisMap: series.map(function (_, index) { return index; }),
                yAxisLabels: series.map(function (s) { return s.name; }),
            };
        }
        return series.map(function (s) { return ({
            series: [s],
            yAxisMap: [0],
            yAxisLabels: [s.name],
        }); });
    };
    /**
     * Adds a plot group
     * @param series list of series or arrays of series to add
     * @param cols number of subplots across the page
     * @param plotHeight height of an individual plot
     * @param showAllX if true all x axis tick labels are shown. If false,
     *                 only those on the bottom of each column are visible
     */
    TimeseriesAnalyticsLayoutManager.prototype.addPlotGroup = function (series, cols, plotHeight, showAllX, showAllY, gap) {
        var _this = this;
        var plots = [];
        if (!Array.isArray(series)) {
            series = [series];
        }
        series.forEach(function (desc) { return plots.push(_this.addPlot(desc)); });
        this.plotGroups.push({
            subplots: plots,
            cols: cols,
            plotHeight: plotHeight,
            showAllX: showAllX,
            showAllY: showAllY,
            gap: gap,
        });
    };
    /**
     * Add one or more traces to a single subplot.
     * @param seriesList array of traces to add
     * @returns SubPlotInfo describing the plot
     */
    TimeseriesAnalyticsLayoutManager.prototype.addPlot = function (desc) {
        var _this = this;
        var firstYIndex = this.yAxes.length;
        var xAxisIndex = this.addXAxis(firstYIndex); // anchored on the first y
        // Show on one plot functionality
        // Add the y axes for the plot, splitting evenly between left and right sides
        var midIndex = desc.yAxisLabels.length / 2;
        if (desc.yAxisLabels.length > 0) {
            desc.yAxisLabels.forEach(function (label, index) {
                // Each yAxis Label maps to an equivalent series UoM. So we use the current index
                // to get the correct UoM for the timeseries.
                var unitOfMeasure = desc.series[index].unitOfMeasure
                    ? desc.series[index].unitOfMeasure
                    : "";
                _this.addYAxis(label, xAxisIndex, index, index < midIndex, firstYIndex, unitOfMeasure); // anchored on the first y
            });
        }
        else {
            this.addYAxis("", xAxisIndex, 0, true, firstYIndex, desc.series[0].unitOfMeasure);
        }
        // Add the plot series using the y axis map to determine which y axis to use
        desc.series.forEach(function (series, index) {
            var yAxisIndex = firstYIndex + desc.yAxisMap[index];
            _this.data.push({
                name: series.name,
                type: "scatter",
                x: series.index,
                y: series.data,
                xaxis: xAxisName(xAxisIndex),
                yaxis: yAxisName(yAxisIndex),
            });
        });
        return {
            xAxis: xAxisIndex,
            yAxis: firstYIndex,
            yAxisCount: desc.yAxisLabels.length,
            traceCount: desc.series.length,
            title: desc.title,
        };
    };
    /**
     * Add an x axis to the list returning the index of the new axis
     * @param yAnchor index of the corresponding y anchor
     */
    TimeseriesAnalyticsLayoutManager.prototype.addXAxis = function (yAnchor) {
        var xaxis = {
            tickformat: this.tickFormat,
            hoverformat: this.hoverFormat || this.tickFormat,
            showline: true,
            type: "date",
            anchor: yAxisName(yAnchor),
        };
        // Link all x axis together
        if (this.xAxes.length) {
            xaxis.matches = "x";
        }
        this.xAxes.push(xaxis);
        return this.xAxes.length - 1;
    };
    /**
     * Add an x axis to the list returning the index of the new axis
     * @param title title to display on the subplot
     * @param xAnchor index of corresponding x axis for the subplot
     * @param index index of y axis within this subplot
     * @param firstYIndex index of the first y axis in the subplot
     */
    TimeseriesAnalyticsLayoutManager.prototype.addYAxis = function (title, xAnchor, index, left, firstYIndex, unitOfMeasure) {
        var yaxis = {
            title: title,
            titlefont: { size: 12 },
            showline: true,
            anchor: xAxisName(xAnchor),
            ticksuffix: unitOfMeasure,
            showticksuffix: "all",
        };
        if (index > 0) {
            yaxis.overlaying = yAxisName(firstYIndex);
        }
        this.yAxes.push(yaxis);
        return this.yAxes.length - 1;
    };
    /**
     * Layout the plots, calculating the final height of the plot.
     *
     * Currently the algorithm does not re-align the x axis if there
     * are plots with multiple y axes. The algorithm for this would be:
     * Treat all groups with the same number of columns as a single group.
     * For each column in the supergroup, find the plot with the most y axes
     * and resize the rest of the column to match that plot (optionally,
     * resize all columns to match the narrowest so that the x scale is the
     * same across all columns).
     */
    TimeseriesAnalyticsLayoutManager.prototype.createLayout = function (width, options, defaultHighlightColor) {
        var _this = this;
        if (width < 1) {
            width = 1000;
        }
        var layout = {
            title: options.title,
            hovermode: options.hoverMode ? "x" : false,
            showlegend: options.showLegend,
            margin: { t: 40, b: 20, l: Y_AXIS_WIDTH, r: 0 },
            height: 0,
            legend: {
                orientation: options.verticalLegend ? "v" : "h",
            },
            shapes: [],
            annotations: [],
        };
        if (options.fixedRange) {
            this.xAxes.forEach(function (axis) {
                axis.fixedrange = true;
            });
            this.yAxes.forEach(function (axis) {
                axis.fixedrange = true;
            });
        }
        var currentTop = 0;
        var plotWidth = width - layout.margin.l - layout.margin.r;
        this.plotGroups.forEach(function (group) {
            currentTop = _this.layoutPlotGroup(group, plotWidth, currentTop);
        });
        this.setupAxes(layout, plotWidth, currentTop);
        this.setRange(layout, options.range);
        if (options.highlights && defaultHighlightColor) {
            this.addHighlights(layout, options.highlights, defaultHighlightColor);
        }
        layout.height = currentTop + X_AXIS_HEIGHT;
        this.plotGroups.forEach(function (group) {
            group.subplots.forEach(function (subplot) {
                if (subplot.title) {
                    var xAxis = _this.xAxes[subplot.xAxis];
                    var yAxis = _this.yAxes[subplot.yAxis];
                    layout.annotations.push({
                        text: subplot.title,
                        x: xAxis.domain[0],
                        y: yAxis.domain[1],
                        xref: "paper",
                        yref: "paper",
                        xanchor: "left",
                        yanchor: "bottom",
                        showarrow: false,
                        xshift: 4,
                        yshift: -2,
                        font: {
                            size: 14,
                            color: "black",
                        },
                    });
                }
            });
        });
        this.layout = layout;
        return layout;
    };
    TimeseriesAnalyticsLayoutManager.prototype.layoutPlotGroup = function (group, plotWidth, currentTop) {
        var _this = this;
        var cols = group.cols, showAllX = group.showAllX, showAllY = group.showAllY, plotHeight = group.plotHeight, gap = group.gap, subplots = group.subplots;
        var rows = Math.floor((subplots.length + cols - 1) / cols);
        var xGutter = Y_AXIS_WIDTH + 20;
        var yGutter = X_AXIS_HEIGHT * 2; // (showAllX ? 2 : 1);
        var domain = function (offset, index, size, gutter) {
            var start = offset + index * (size + gutter);
            return [start, start + size];
        };
        var subplotWidth = (plotWidth - (cols - 1) * xGutter) / cols;
        // most number of traces on a single plot
        // const maxTraces = Math.max(...subplots.map(x => x.yAxisCount));
        currentTop += gap;
        subplots.forEach(function (plot, index) {
            var xAxis = plot.xAxis, yAxis = plot.yAxis, yAxisCount = plot.yAxisCount;
            var xAxisLayout = _this.xAxes[xAxis];
            var yAxisLayout = _this.yAxes[yAxis];
            var col = index % cols;
            var row = (index - col) / cols;
            xAxisLayout.domain = domain(0, col, subplotWidth, xGutter);
            yAxisLayout.domain = domain(currentTop, row, plotHeight, yGutter);
            if (index < subplots.length - cols && !showAllX) {
                xAxisLayout.ticks = "";
                xAxisLayout.showticklabels = false;
            }
            if (!showAllY) {
                yAxisLayout.ticks = "";
                yAxisLayout.showticklabels = false;
            }
            // Handle multiple y axes by shrinking the plot area & manually
            // positioning the second and subsequent y axes
            if (yAxisCount > 0) {
                var numOnLeft = Math.ceil(yAxisCount / 2);
                var axisSize = _this.additionalYAxisSize(xAxisLayout.domain, yAxisCount - 1);
                xAxisLayout.domain[0] += (numOnLeft - 1) * axisSize; // first y is already accounted for
                xAxisLayout.domain[1] -= (yAxisCount - numOnLeft) * axisSize;
                for (var yCtr = 1; yCtr < yAxisCount; ++yCtr) {
                    var freeYAxis = _this.yAxes[yAxis + yCtr];
                    freeYAxis.anchor = "free";
                    if (yCtr < numOnLeft) {
                        freeYAxis.side = "left";
                        freeYAxis.position = xAxisLayout.domain[0] - yCtr * axisSize;
                    }
                    else {
                        freeYAxis.side = "right";
                        freeYAxis.position =
                            xAxisLayout.domain[1] + (yCtr - numOnLeft) * axisSize;
                    }
                }
            }
        });
        return currentTop + (plotHeight + yGutter) * rows - yGutter;
    };
    /**
     * Calculates the width of the additional axis, ensuring that some space is
     * left for the actual graph area
     * @param domain x axis domain of the graph
     * @param additionalTraces number of traces over the first.
     */
    TimeseriesAnalyticsLayoutManager.prototype.additionalYAxisSize = function (domain, additionalTraces) {
        var space = domain[1] - domain[0] - MIN_GRAPH_WIDTH; // don't let the graph area go beneath minimum width
        return Math.min(Y_AXIS_WIDTH, Math.max(0, Math.floor(space / additionalTraces)));
    };
    TimeseriesAnalyticsLayoutManager.prototype.addHighlights = function (layout, highlights, defaultHighlightColor) {
        var _this = this;
        this.plotGroups.forEach(function (group) {
            group.subplots.forEach(function (plot, index) {
                highlights.forEach(function (highlight) {
                    var highlightColor = highlight.color
                        ? highlight.color
                        : defaultHighlightColor;
                    _this.addHighlight(layout, highlight, highlightColor, plot.xAxis, plot.yAxis);
                });
            });
        });
    };
    TimeseriesAnalyticsLayoutManager.prototype.addHighlight = function (layout, highlight, highlightColor, xAxisIndex, yAxisIndex) {
        // Because the shapes array is 1 dimensional, we can use this value to say which subplot
        // we want each shape to be bound to. This allows us to have an array of different shapes
        // per plot be passed in, but be flattened into a single array mapped to subplots.
        var yAxis = this.yAxes[yAxisIndex];
        layout.shapes.push({
            fillcolor: highlightColor,
            line: { width: 0 },
            type: "rect",
            x0: highlight.from.getTime(),
            x1: highlight.to.getTime(),
            xref: xAxisName(xAxisIndex),
            y0: yAxis.domain[0],
            y1: yAxis.domain[1],
            yref: "paper",
            opacity: 0.2,
        });
    };
    /**
     * Map axis domain coordinates from top-down in pixels to bottom-up
     * in plot size fractions, then copy the mapped axis into the layout object
     *
     * @param layout Plotly layout object.
     * @param width width of the plotly plot area in pixels
     * @param height height of the plotly plot area in pixels
     */
    TimeseriesAnalyticsLayoutManager.prototype.setupAxes = function (layout, width, height) {
        this.xAxes.forEach(function (x, index) {
            if (x.domain) {
                x.domain = [x.domain[0] / width, x.domain[1] / width];
            }
            layout["xaxis" + axisSuffix(index)] = x;
        });
        this.yAxes.forEach(function (y, index) {
            if (y.domain) {
                // reverse order as we're reversing the coordinate system
                y.domain = [1 - y.domain[1] / height, 1 - y.domain[0] / height];
            }
            if (y.anchor === "free") {
                y.position /= width;
            }
            layout["yaxis" + axisSuffix(index)] = y;
        });
    };
    TimeseriesAnalyticsLayoutManager.prototype.setRange = function (layout, range) {
        if (range) {
            layout.xaxis.autorange = false;
            layout.xaxis.range = [range.from.getTime(), range.to.getTime()];
        }
    };
    TimeseriesAnalyticsLayoutManager.prototype.addDebuggingShapes = function (layout) {
        var _this = this;
        var addRect = function (x, y, color, lineWidth, opacity) {
            if (lineWidth === void 0) { lineWidth = 1; }
            if (opacity === void 0) { opacity = 1; }
            layout.shapes.push({
                line: { width: lineWidth, color: color },
                type: "rect",
                x0: x[0],
                x1: x[1],
                xref: "paper",
                y0: y[0],
                y1: y[1],
                yref: "paper",
                opacity: opacity,
            });
        };
        this.plotGroups.forEach(function (group) {
            group.subplots.forEach(function (plot) {
                addRect(_this.xAxes[plot.xAxis].domain, _this.yAxes[plot.yAxis].domain, "green");
            });
        });
        addRect([0, 1], [0, 1], "red", 2, 0.5);
    };
    return TimeseriesAnalyticsLayoutManager;
}());
export { TimeseriesAnalyticsLayoutManager };
