import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import axios from "axios";

// React Router
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthToken } from "features/auth/authSlice";
import { fetchAssetList } from "../features/assetList/assetListSlice";
import { fetchIssueData } from "../features/issues/issuesSlice";
import { fetchAssetData } from "../features/assetData/assetDataSlice";
import { RootState } from "../app/rootReducer";

// Sensorhealth components
import { LoginFormData } from "@toumetis/cascadence-react-ui";

// Utility functions
import { decryptWithAES } from "../utils";

// Local components
import Login from "../features/auth/Login";
import AnalyticsPage from "../components/AnalyticsPage";
import HomePage from "../components/HomePage";

export interface RouteParams {
  assetId: string;
  regionId: string;
}

// Set default Authorization header for initial load
// (e.g. if user was logged in and refreshed the page)
const token = sessionStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      // Fetch data that we want to use throughout the application
      dispatch(fetchAssetList());
      dispatch(fetchIssueData());
      dispatch(fetchAssetData());
    } else {
      // NOTE: for demo purposes only
      const usr = sessionStorage.getItem("usr") || "";
      const pw = decryptWithAES(sessionStorage.getItem("pw") || "");

      if (usr && pw) {
        const loginData: LoginFormData = { username: usr, password: pw };
        dispatch(fetchAuthToken(loginData)); // Get a fresh token
      }
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Router history={history}>
      <Switch>
        <Route path={"/login"} exact component={Login} />
        <PrivateRoute exact component={HomePage} path="/" />
        <PrivateRoute
          component={AnalyticsPage}
          path="/wells/:assetId/pumps/:regionId"
        />
        <PrivateRoute component={AnalyticsPage} path="/wells" />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default App;
