import { combineReducers } from "@reduxjs/toolkit";
import assetListReducer from "features/assetList/assetListSlice";
import authReducer from "features/auth/authSlice";
import tagDataReducer from "features/tagData/tagDataSlice";
import issueReducer from "features/issues/issuesSlice";
import regionReducer from "features/regions/regionsSlice";
import assetDataReducer from "features/assetData/assetDataSlice";
import modelDataReducer from "features/model/modelDataSlice";
import navigationReducer from "features/navigation/navigationSlice";

const rootReducer = combineReducers({
  assetList: assetListReducer,
  auth: authReducer,
  tagData: tagDataReducer,
  issues: issueReducer,
  regions: regionReducer,
  assetData: assetDataReducer,
  modelData: modelDataReducer,
  navigation: navigationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
