import React from "react";
import { greyColor } from "../../utils";
import MarkerIcon from "./MarkerIcon";
/** Marker icon that colors itself from the health and on properties */
export var MarkerPin = function (props) {
    var filled = props.filled, width = props.width, height = props.height, selected = props.selected;
    var statusColor = props.color;
    if (selected === false) {
        statusColor = greyColor(statusColor);
    }
    var fillColor = filled ? statusColor : "transparent";
    var strokeColor = filled ? "#fff5" : statusColor;
    var circleFill = filled ? "white" : statusColor;
    return (React.createElement(MarkerIcon, { width: width, height: height, strokeWidth: 1, strokeColor: strokeColor, fillColor: fillColor, circleFill: circleFill }));
};
