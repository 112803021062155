var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { withTheme } from "@material-ui/core";
import { DefaultPlot, withSizeHoc } from "../DefaultPlot";
import { TrafficLightColorMap, greyColor, } from "../../../utils/trafficlightcolors";
/**
 * Donut chart showing percentages using traffic light health colors
 */
var StatusPieBase = /** @class */ (function (_super) {
    __extends(StatusPieBase, _super);
    function StatusPieBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StatusPieBase.prototype.render = function () {
        var props = this.props;
        // const {values, valueTypes, labels, selected, onPieClicked, theme, size} = props;
        var values = props.values, valueTypes = props.valueTypes, markerColors = props.markerColors, labels = props.labels, selected = props.selected, onPieClicked = props.onPieClicked, theme = props.theme;
        // const width = size.width || 500;
        var width = 500;
        if (values.reduce(function (prev, curr) { return prev + curr; }, 0) === 0) {
            return null;
        }
        var data = {
            values: values,
            labels: labels,
            type: "pie",
            hole: 0.4,
            textposition: "inside",
            hoverinfo: "label+value+percent",
            textinfo: "value",
        };
        /* Get the list of selected colors.
         * If valueTypes is present, map that list to traffic light colors
         * else if an explicit list is present (markerColors) use that,
         * otherwise just use the default color pattern
         */
        var colorList;
        if (valueTypes) {
            var mapper_1 = new TrafficLightColorMap(theme);
            colorList = valueTypes.map(function (valueType) { return mapper_1.getColor(valueType); });
        }
        else if (markerColors) {
            colorList = markerColors;
        }
        else {
            colorList = values.map(function (_, index) { return theme.palette.plot[index % theme.palette.plot.length]; });
        }
        /* If we're handling selections, gray out colors for unselected items */
        if (selected) {
            colorList = colorList.map(function (col, index) {
                return selected[index] ? col : greyColor(col);
            });
        }
        data.marker = { colors: colorList };
        var heightTotal = 300;
        var legendHeight = 74; // correct for 3 lines
        var margin = { t: 0, b: 20, l: 25, r: 25 };
        var plotHeight = heightTotal - margin.t - margin.b;
        // Not completely accurate as this depends on what labels
        // are in the margin area. Should be conservative to ensure
        // the legend doesn't migrate off the page
        var plotMinWidth = plotHeight + margin.l + margin.r;
        var spare = width - plotMinWidth;
        var xExtra = spare > 0 && width > 0 ? (spare + 130) / width : 0;
        var layout = {
            showlegend: true,
            height: heightTotal,
            margin: margin,
            font: {
                size: 18,
            },
            legend: {
                orientation: "v",
                xanchor: "right",
                x: 1 + xExtra,
                yanchor: "top",
                y: 1 + (legendHeight + 30) / plotHeight,
                font: {
                    size: 12,
                },
            },
        };
        var config = {
            displayModeBar: false,
        };
        var onclick = function (e) {
            if (onPieClicked && e.points && e.points.length > 0) {
                var index = e.points[0].pointNumber;
                if (index >= 0 && index <= values.length) {
                    onPieClicked(index, false);
                }
            }
        };
        var onLegendClick = function (e /* Readonly<Plotly.LegendClickEvent> */) {
            if (onPieClicked && e && e.label) {
                var index = labels.indexOf(e.label);
                if (index >= 0) {
                    onPieClicked(index, true);
                }
            }
            return false;
        };
        return (React.createElement(DefaultPlot, { onLegendClick: onLegendClick, data: [data], onClick: onclick, layout: layout, config: config }));
    };
    return StatusPieBase;
}(React.Component));
export { StatusPieBase };
export var StatusPie = withSizeHoc(withTheme(StatusPieBase));
