import * as React from "react";
import { withStyles } from "@material-ui/core";
import { TableHead, TableRow, TableCell, TableSortLabel, Tooltip, } from "@material-ui/core";
import { styles } from "./SortTable.styles";
var SortTableHead = function (props) {
    var columns = props.columns, sortColumn = props.sortColumn, sortDirection = props.sortDirection, onSortClick = props.onSortClick, classes = props.classes;
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, { className: classes.tableHeadRow }, columns.map(function (col) { return (React.createElement(TableCell, { key: col.id, sortDirection: sortColumn === col ? sortDirection : false, className: classes.tableHeadCell },
            React.createElement(Tooltip, { title: "Sort", enterDelay: 300 },
                React.createElement(TableSortLabel, { active: sortColumn === col, direction: sortDirection, onClick: function () { return onSortClick(col); } },
                    col.name,
                    "--")))); }))));
};
export default withStyles(styles)(SortTableHead);
