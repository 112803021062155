import React from "react";
import { createStyles, withStyles, } from "@material-ui/core";
import { MarkerPin } from "./MarkerPin";
import { MarkerTooltip } from "./MarkerTooltip";
var styles = function () {
    return createStyles({
        marker: {
            position: "absolute",
            fontSize: "16px",
            userSelect: "none",
            transform: "translate(-50%, -90%)",
            cursor: "pointer",
            "&:hover": {
                zIndex: 1,
            },
        },
    });
};
export var MarkerBase = function (props) {
    var classes = props.classes, description = props.description, color = props.color, filled = props.filled, onClick = props.onClick, opacity = props.opacity;
    return (React.createElement("span", { className: classes.marker, onClick: function (e) { return onClick && onClick(); }, style: { opacity: opacity && !isNaN(opacity) ? opacity : 1 } },
        description && React.createElement(MarkerTooltip, { description: description }),
        React.createElement(MarkerPin, { color: color, filled: filled })));
};
export var Marker = withStyles(styles, { withTheme: true })(MarkerBase);
