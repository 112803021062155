export const tagNames = [
  "I_VSD_A.CV",
  "VAB.CV",
  "DISPRES.CV",
  "INTPRES.CV",
  "INTTEMP.CV",
  "MOTTEMP.CV",
  "TEMP_FL.CV",
  "PRESS_DS.CV",
  "FREQ.CV",
  "VIBX.CV",
  "PRESS_US.CV",
  "TEMP_TUB.CV",
];
