import React from "react";

import { makeStyles } from "@material-ui/core";

import { ToumetisIcon } from "@toumetis/cascadence-react-ui";

const useStyles = makeStyles((theme) => ({
  empty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

export const LoadingCard = () => {
  const classes = useStyles();
  return (
    <div className={classes.empty}>
      <ToumetisIcon spin={true} size={40} />
    </div>
  );
};
