import React from "react";
import moment from "moment";

// React Router
import { useParams } from "react-router";
import { RouteParams } from "../../app/App";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { setNavigation } from "./navigationSlice";

// Material UI
import { makeStyles, Breadcrumbs, Button } from "@material-ui/core";

// Constants
import { dateFormat } from "../../globals";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    color: "#fff",
  },
  breadcrumb: {
    fontSize: "1.2rem",
    textTransform: "none",
  },
  breadcrumbLink: {
    borderBottom: "1px solid #fff",
    padding: "2px 0 2px 0",
    minWidth: 0,
    lineHeight: 1,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface BreadcrumbProps {
  noLink?: boolean;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { noLink } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { assetId, regionId } = useParams<RouteParams>();
  const { regions } = useSelector((state: RootState) => state.regions);
  const region = regions[regionId];
  const { scrolling } = useSelector((state: RootState) => state.navigation);

  /**
   * Updates navigation state with asset to scroll to
   * Clicking on asset in breadcrumb will scroll to that point in asset list
   * Clicking on Wells will scroll to top of asset list
   * @param {string | null} assetId
   */
  const onBreadcrumbClick = (assetId: string | null) => {
    if (scrolling === "ready")
      dispatch(setNavigation({ scrollTo: assetId, scrolling: "scrolling" }));
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator=">"
      className={classes.breadcrumbContainer}
    >
      {noLink ? (
        <span className={classes.breadcrumb}>Wells</span>
      ) : (
        <Button
          color="inherit"
          className={`${classes.breadcrumb} ${classes.breadcrumbLink}`}
          disableRipple
          onClick={() => onBreadcrumbClick(null)}
        >
          Wells
        </Button>
      )}

      {assetId &&
        (noLink ? (
          <span key={assetId} className={classes.breadcrumb}>
            {assetId}
          </span>
        ) : (
          <Button
            key={assetId}
            color="inherit"
            className={`${classes.breadcrumb} ${classes.breadcrumbLink}`}
            disableRipple
            onClick={() => onBreadcrumbClick(assetId)}
          >
            {assetId}
          </Button>
        ))}

      {regionId && region && (
        <span key={region.regionId} className={classes.breadcrumb}>
          {moment(region.startDate).format(dateFormat)}
        </span>
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
