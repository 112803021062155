import React from "react";
import {
  makeStyles,
  AppBar,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";

import { ToumetisIcon } from "@toumetis/cascadence-react-ui";

import Breadcrumb from "./Breadcrumb";
import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
  logoTitleContainer: {
    width: "auto",
  },
  titleContainer: {
    width: 180,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  appTitle: {
    fontWeight: 900,
    fontSize: "1.6rem",
  },
  appSubTitle: {
    fontSize: "0.75rem",
    fontFamily: "Helvetica",
    letterSpacing: 0.05,
  },
  appBar: {
    padding: theme.spacing(1, 2, 1, 2),
    height: 70,
    justifyContent: "center",
  },
}));

interface HeaderProps {
  showBreadcrumb?: boolean;
  showNoBreadcrumbLink?: boolean;
}

const Header = (props: HeaderProps) => {
  const { showBreadcrumb, showNoBreadcrumbLink } = props;
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0} className={classes.appBar}>
      <Grid container wrap="nowrap" alignItems="center">
        <Grid
          container
          item
          wrap="nowrap"
          className={classes.logoTitleContainer}
        >
          <Grid item>
            <ToumetisIcon className={classes.icon} size={50} />
          </Grid>
          <Hidden smDown>
            <Grid
              container
              item
              direction="column"
              wrap="nowrap"
              justify="space-around"
              className={classes.titleContainer}
            >
              <Grid item>
                <Typography variant="h1" className={classes.appTitle}>
                  Cascadence
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1" className={classes.appSubTitle}>
                  Rumaila Oilfield
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            {showBreadcrumb && <Breadcrumb noLink={showNoBreadcrumbLink} />}
          </Grid>
          <Grid item>
            <Menu />
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
