import axios from "axios";
import { Issue } from "../features/issues/issuesSlice";

interface IssuesResponse {
  id: number;
  issue_type_id: number;
  start_time: string;
  end_time: string;
  created_time: string;
  asset_id: string;
  region_id: number;
  reason: string;
  issue_status: string;
  assigned_to: string;
  anomaly_score: number;
}

export async function getIssues(): Promise<Issue[]> {
  const url = `${process.env.REACT_APP_API_ROOT}/issue/`;

  try {
    const response = await axios.get<IssuesResponse[]>(url, {
      params: {},
      timeout: 5000,
    });

    const issues: Issue[] = [];
    response.data.forEach((item) => {
      issues.push({
        id: item.id,
        issueTypeId: item.issue_type_id,
        startTime: item.start_time,
        endTime: item.end_time,
        createdTime: item.created_time,
        assetId: item.asset_id,
        regionId: item.region_id,
        reason: item.reason,
        issueStatus: item.issue_status,
        assignedTo: item.assigned_to,
        anomalyScore: item.anomaly_score,
      });
    });
    return issues;
  } catch (err) {
    throw err;
  }
}

export async function updateIssue(
  id: number,
  status: string,
  assignedTo: string | undefined
): Promise<void> {
  let url = `${process.env.REACT_APP_API_ROOT}/issue/update_status?issue_id=${id}&issue_status=${status}`;
  if (assignedTo) url += `&issue_assigned_to=${assignedTo}`;

  try {
    await axios.post(url, {
      timeout: 5000,
    });
  } catch (err) {
    throw err;
  }
}
