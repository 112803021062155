var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { withStyles } from "@material-ui/core";
import { Table, TablePagination } from "@material-ui/core";
import SortTableHead from "./SortTableHead";
import SortTableBody from "./SortTableBody";
import { styles } from "./SortTable.styles";
// --------------------------- //
// -- Props and State Setup -- //
// --------------------------- //
var DefaultCompareFunc = function (a, b) {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
};
// --------------- //
// -- Component -- //
// --------------- //
var SortTableUnstyled = /** @class */ (function (_super) {
    __extends(SortTableUnstyled, _super);
    function SortTableUnstyled(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChangePage = function (event, nextPage) {
            var maxPages = Math.ceil(_this.props.rows.length / _this.state.rowsPerPage) - 1;
            var page = Math.max(0, Math.min(nextPage, maxPages));
            _this.setState({ page: page });
        };
        _this.handleChangeRowsPerPage = function (event) {
            _this.setState({ rowsPerPage: event.target.value });
        };
        /**
         * Handles a click on a column. If the column passed is null, clear the sort order (
         * show in the order of the Rows[] property)
         */
        _this.handleSortClick = function (column) {
            var prevDirection = column === _this.state.sortColumn ? _this.state.sortDirection : "asc";
            var toggleDirection = function (dir) {
                return dir === "asc" ? "desc" : "asc";
            };
            _this.setState({
                sortColumn: column,
                sortDirection: toggleDirection(prevDirection),
            });
        };
        _this.handleRowClick = function (row, col) {
            return _this.props.onRowClick ? _this.props.onRowClick(row, col) : null;
        };
        _this.getRowSort = function () {
            // Create a sort function specific to the data in the sortId column
            var rowSort = function (a, b, sortColumn, sortDirection) {
                if (!sortColumn) {
                    return 0;
                }
                var sortId = sortColumn.id;
                var compareFunc = sortColumn.compare || DefaultCompareFunc;
                var sign = sortDirection === "desc" ? -1 : 1;
                var comp = compareFunc(a.values[sortId], b.values[sortId], a.values, b.values, sortId);
                return sign * comp;
            };
            return function (a, b) {
                return rowSort(a, b, _this.state.sortColumn, _this.state.sortDirection);
            };
        };
        _this.getColumns = function () {
            var _a = _this.props, columns = _a.columns, columnIds = _a.columnIds;
            return columns.filter(function (col) {
                return columnIds && columnIds.length ? columnIds.includes(col.id) : true;
            });
        };
        _this.getRows = function () {
            var rows = _this.props.rows;
            var _a = _this.state, page = _a.page, rowsPerPage = _a.rowsPerPage;
            return rows
                .sort(_this.getRowSort())
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        };
        var options = props.rowsPerPageOptions;
        var rowsPerPageOptions = Array.isArray(options) && options.length ? options : [10, 25, 50, 100];
        var sortColumn = props.columns.find(function (x) { return x.id === props.sortId; });
        var sortDirection = props.sortDirection || "asc";
        _this.state = {
            page: 0,
            rowsPerPage: rowsPerPageOptions[0],
            rowsPerPageOptions: rowsPerPageOptions,
            sortColumn: sortColumn || null,
            sortDirection: sortDirection,
        };
        return _this;
    }
    SortTableUnstyled.prototype.render = function () {
        var _a = this.state, page = _a.page, rowsPerPage = _a.rowsPerPage, rowsPerPageOptions = _a.rowsPerPageOptions, sortColumn = _a.sortColumn, sortDirection = _a.sortDirection;
        var columns = this.getColumns();
        var rows = this.getRows();
        return (React.createElement(React.Fragment, null,
            React.createElement(Table, { size: "small" },
                React.createElement(SortTableHead, { columns: columns, sortColumn: sortColumn, sortDirection: sortDirection, onSortClick: this.handleSortClick }),
                React.createElement(SortTableBody, { columns: columns, rows: rows, onRowClick: this.handleRowClick })),
            React.createElement(TablePagination, { component: "div", rowsPerPageOptions: rowsPerPageOptions, count: this.props.rows.length, rowsPerPage: rowsPerPage, page: page, onChangePage: this.handleChangePage, onChangeRowsPerPage: this.handleChangeRowsPerPage })));
    };
    return SortTableUnstyled;
}(React.Component));
export var SortTable = withStyles(styles)(SortTableUnstyled);
